import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Loader2 } from 'lucide-react';

const IsAdmin = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const checkAdminStatus = async () => {
      try {
        const adminToken = localStorage.getItem('adminToken');
        
        // No token present
        if (!adminToken) {
          setIsLoading(false);
          setIsAdmin(false);
          return;
        }

        // Verify admin status with backend
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/verify`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${adminToken}`,
            'Content-Type': 'application/json'
          }
        });

        if (response.ok) {
          const data = await response.json();
          setIsAdmin(data.isAdmin === true);
        } else {
          // Token is invalid or expired
          localStorage.removeItem('adminToken');
          setIsAdmin(false);
        }
      } catch (error) {
        console.error('Admin verification error:', error);
        localStorage.removeItem('adminToken');
        setIsAdmin(false);
      } finally {
        setIsLoading(false);
      }
    };

    checkAdminStatus();
  }, []);

  // Loading state
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader2 className="w-8 h-8 text-purple-600 animate-spin" />
      </div>
    );
  }

  // No token and not on login page - redirect to login
  if (!isAdmin && location.pathname !== '/admin-espor.ai/login') {
    return <Navigate to="/admin-espor.ai/login" replace />;
  }

  // Has token and on login page - redirect to dashboard
  if (isAdmin && location.pathname === '/admin-espor.ai/login') {
    return <Navigate to="/admin-espor.ai" replace />;
  }

  // Continue with the current route
  return <Outlet />;
};

export default IsAdmin;