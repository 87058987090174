import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useOutletContext, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../components/context/AuthContext';
import {
  Link as LinkIcon,
  Check,
  AlertCircle,
  Settings,
  RefreshCw,
  XCircle,
  CheckCircle,
  Info,
  ChevronDown,
  ChevronUp,
} from 'lucide-react';
import { FaGoogle, FaFacebook, FaWhatsapp } from 'react-icons/fa';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

// Estilos globales para optimización móvil
const MobileOptimizations = () => (
  <style jsx global>{`
    @media (max-width: 640px) {
      .mobile-card {
        border-radius: 0.75rem;
        margin-bottom: 1rem;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
      }
      
      /* Para botones en móvil */
      .mobile-button {
        min-height: 44px;
        font-size: 0.875rem;
      }
      
      /* Para tamaños de texto en móvil */
      .mobile-text-xs {
        font-size: 0.75rem;
      }
      
      .mobile-text-sm {
        font-size: 0.875rem;
      }
      
      /* Para espaciados en móvil */
      .mobile-p-3 {
        padding: 0.75rem;
      }
      
      .mobile-p-4 {
        padding: 1rem;
      }
      
      /* Para padding inferior extra en cuerpo principal */
      .mobile-main {
        padding-bottom: 5rem;
      }
    }
  `}</style>
);

// Utility Components
const LoadingSpinner = ({ size = "small", color = "blue" }) => {
  const sizeClasses = {
    small: "h-4 w-4",
    medium: "h-6 w-6",
    large: "h-8 w-8"
  };

  const colorClasses = {
    blue: "text-blue-600",
    red: "text-red-600",
    white: "text-white",
    purple: "text-[#2745F8]"
  };

  return (
    <RefreshCw 
      className={`${sizeClasses[size]} ${colorClasses[color]} animate-spin`} 
    />
  );
};

const StatusBadge = ({ status, type = "default" }) => {
  const getStatusColors = () => {
    const statusMap = {
      ACTIVE: 'bg-green-100 text-green-600',
      ENABLED: 'bg-green-100 text-green-600',
      PAUSED: 'bg-yellow-100 text-yellow-600',
      DISABLED: 'bg-red-100 text-red-600',
      default: 'bg-gray-100 text-gray-600'
    };

    if (type === 'meta') {
      return status === 'ACTIVE' ? statusMap.ACTIVE : statusMap.PAUSED;
    }

    if (type === 'google') {
      return status === 'ENABLED' ? statusMap.ENABLED : statusMap.DISABLED;
    }

    return statusMap[status] || statusMap.default;
  };

  return (
    <span className={`inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium ${getStatusColors()}`}>
      {status}
    </span>
  );
};

// Accordion component for mobile view
const MobileAccordion = ({ title, children, icon, isConnected, defaultOpen = false }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  
  return (
    <div className="border border-gray-200 rounded-lg bg-white overflow-hidden mb-4">
      <div 
        className="flex items-center justify-between p-4 cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex items-center">
          {icon && (
            <div className="p-2.5 rounded-full bg-[#2745F8]/10 mr-3">
              {icon}
            </div>
          )}
          <div>
            <h2 className="text-base font-medium text-gray-800">{title}</h2>
            {isConnected && (
              <div className="flex items-center text-green-600 text-xs">
                <Check className="h-3.5 w-3.5 mr-1" />
                <span>Connected</span>
              </div>
            )}
          </div>
        </div>
        {isOpen ? (
          <ChevronUp className="h-5 w-5 text-gray-500" />
        ) : (
          <ChevronDown className="h-5 w-5 text-gray-500" />
        )}
      </div>
      
      {isOpen && (
        <div className="p-4 border-t border-gray-200">
          {children}
        </div>
      )}
    </div>
  );
};

// Google Accounts Section optimized for mobile
const GoogleAccountSelector = ({ accounts, selectedAccount, onSelectAccount, activeAccountId }) => {
  const [isConnecting, setIsConnecting] = useState(false);
  const [processingAccountId, setProcessingAccountId] = useState(null);
  const { auth, updateUser } = useContext(AuthContext);

  const handleAccountConnection = async (account) => {
    try {
      setIsConnecting(true);
      setProcessingAccountId(account.customer_id);
      
      const response = await axios.post(
        `${API_URL}/user/set-active-account`,
        {
          google_customer_id: account.customer_id
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` }
        }
      );

      if (response.status === 200) {
        onSelectAccount(account);
        
        updateUser({
          ...auth.user,
          google_customer_id: account.customer_id,
          google_connected: true
        });
      }

    } catch (error) {
      console.error('Error connecting account:', error);
    } finally {
      setIsConnecting(false);
      setProcessingAccountId(null);
    }
  };

  return (
    <div className="mt-3 border-t border-gray-200 pt-3">
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between mb-3">
        <h3 className="text-sm font-medium text-gray-700 mb-1 sm:mb-0">Seleccionar cuenta para operar</h3>
        {activeAccountId && (
          <div className="flex items-center text-xs text-green-600">
            <Check className="h-3.5 w-3.5 mr-1" />
            Cuenta activa
          </div>
        )}
      </div>

      <div className="space-y-2">
        {accounts.filter(account => !account.is_manager).map((account) => {
          const isActive = activeAccountId === account.customer_id;
          const isProcessing = processingAccountId === account.customer_id;
          
          return (
            <div
              key={account.customer_id}
              className={`p-3 sm:p-4 rounded-lg border transition-colors ${
                isActive
                  ? 'border-[#2745F8] bg-[#2745F8]/10'
                  : 'border-gray-200 hover:border-[#2745F8]/50 hover:bg-[#2745F8]/5'
              }`}
            >
              <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                <div className="flex-1 mb-2 sm:mb-0">
                  <div className="font-medium text-gray-800 text-sm sm:text-base">
                    {account.descriptive_name || `Account ${account.customer_id}`}
                  </div>
                  <div className="text-xs text-gray-600">
                    ID: {account.customer_id}
                    <span className="mx-2">•</span>
                    {account.currency_code}
                  </div>
                </div>

                <div className="flex items-center gap-2">
                  {isActive ? (
                    <div className="flex items-center text-green-600 bg-green-50 px-3 py-1.5 rounded-lg text-xs sm:text-sm">
                      <Check className="h-3.5 w-3.5 mr-1" />
                      <span>Cuenta activa</span>
                    </div>
                  ) : (
                    <button
                      onClick={() => handleAccountConnection(account)}
                      disabled={isConnecting}
                      className="px-3 py-1.5 bg-[#2745F8] text-white rounded-lg text-xs sm:text-sm hover:bg-[#2745F8]/90 
                        transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2 w-full sm:w-auto justify-center sm:justify-start mobile-button"
                    >
                      {isProcessing ? (
                        <>
                          <LoadingSpinner color="white" />
                          <span>Conectando...</span>
                        </>
                      ) : (
                        <>
                          <Settings className="h-3.5 w-3.5" />
                          <span>Usar cuenta</span>
                        </>
                      )}
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
const GoogleSection = () => {
  const location = useLocation();
  const { auth, updateUser } = useContext(AuthContext);
  const [isConnectingGoogle, setIsConnectingGoogle] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [selectedGoogleAccount, setSelectedGoogleAccount] = useState(null);
  const [activeGoogleAccount, setActiveGoogleAccount] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  // Get Google accounts from context
  const outletContext = useOutletContext();
  const { 
    googleAccounts = [],
    loadingGoogleAccounts = false,
    fetchGoogleAccounts = () => console.log('fetchGoogleAccounts not available')
  } = outletContext || {};
 
  useEffect(() => {
    if (auth.user?.google_customer_id && googleAccounts.length > 0) {
      const active = googleAccounts.find(
        account => account.customer_id === auth.user.google_customer_id
      );
      setActiveGoogleAccount(active || null);
      if (active) {
        setSelectedGoogleAccount(active);
      }
    }
  }, [auth.user?.google_customer_id, googleAccounts]);

  useEffect(() => {
    if ((auth.user?.google_connected || location.state?.googleConnected) && 
        typeof fetchGoogleAccounts === 'function') {
      fetchGoogleAccounts();
    }
  }, [auth.user?.google_connected, location.state?.googleConnected, fetchGoogleAccounts]);

  const handleGoogleLogin = () => {
    if (auth.user?.meta_connected) {
      localStorage.setItem('preserveMetaConnection', 'true');
    }
    const redirectUri = `${window.location.origin}/google-callback`;
    const scope = encodeURIComponent('openid email profile https://www.googleapis.com/auth/adwords');
    window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=${redirectUri}&scope=${scope}&response_type=code&access_type=offline&prompt=consent`;
  };

  const connectGoogleAds = async () => {
    if (!selectedGoogleAccount) {
      setError('Por favor selecciona una cuenta primero');
      return;
    }

    try {
      setIsConnectingGoogle(true);
      setError('');
      
      const response = await axios.post(
        `${API_URL}/connect_google_ads`,
        {
          customer_id: selectedGoogleAccount.customer_id
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` }
        }
      );
  
      const userData = {
        google_connected: true,
        google_customer_id: selectedGoogleAccount.customer_id
      };
  
      const updateResponse = await axios.put(
        `${API_URL}/user`,
        userData,
        {
          headers: { Authorization: `Bearer ${auth.token}` }
        }
      );
  
      updateUser(updateResponse.data);
      setActiveGoogleAccount(selectedGoogleAccount);
      setSuccess('Conectado exitosamente a Google Ads');
      
    } catch (error) {
      console.error('Error connecting to Google Ads:', error);
      setError(error.response?.data?.error || 'Error connecting to Google Ads');
    } finally {
      setIsConnectingGoogle(false);
    }
  };

  const disconnectGoogleAds = async () => {
    try {
      await axios.post(
        `${API_URL}/api/google_ads/disconnect`,
        {},
        {
          headers: { Authorization: `Bearer ${auth.token}` }
        }
      );
      updateUser({
        ...auth.user,
        google_connected: false,
        google_customer_id: null
      });
      setSelectedGoogleAccount(null);
      setActiveGoogleAccount(null);
      setSuccess('Google Ads account disconnected');
    } catch (error) {
      setError(error.response?.data?.error || 'Error disconnecting Google Ads');
    }
  };

  const renderGoogleConnectSection = () => {
    const isConnected = auth.user?.google_connected;
    
    if (isConnected) {
      return (
        <div className="space-y-3">
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-2">
            <div className="flex items-center space-x-3">
              <span className="flex items-center text-green-600">
                <Check className="h-4 w-4 mr-1" />
                <span className="text-sm font-medium">Connected</span>
              </span>
              <button
                onClick={disconnectGoogleAds}
                className="p-1.5 rounded-full text-gray-400 hover:text-red-600 hover:bg-red-50"
                title="Disconnect account"
              >
                <XCircle className="h-4 w-4" />
              </button>
            </div>
            {activeGoogleAccount && (
              <div className="text-xs sm:text-sm text-gray-700 bg-gray-50 px-3 py-1.5 rounded-lg">
                Active: {activeGoogleAccount.descriptive_name || activeGoogleAccount.customer_id}
              </div>
            )}
          </div>

          <GoogleAccountSelector
            accounts={googleAccounts}
            selectedAccount={selectedGoogleAccount}
            onSelectAccount={setSelectedGoogleAccount}
            activeAccountId={auth.user?.google_customer_id}
          />
        </div>
      );
    }

    return (
      <div className="space-y-3 w-full">
        {!googleAccounts.length && (
          <button
            onClick={handleGoogleLogin}
            className="w-full px-4 py-2 bg-[#2745F8] hover:bg-[#2745F8]/90 text-white rounded-lg flex items-center justify-center gap-2 mobile-button"
          >
            <FaGoogle className="h-4 w-4" />
            <span>Conectar con Google Ads</span>
          </button>
        )}

        {googleAccounts.length > 0 && (
          <>
            <GoogleAccountSelector
              accounts={googleAccounts}
              selectedAccount={selectedGoogleAccount}
              onSelectAccount={setSelectedGoogleAccount}
              activeAccountId={auth.user?.google_customer_id}
            />

            <button
              onClick={connectGoogleAds}
              disabled={isConnectingGoogle || !selectedGoogleAccount}
              className={`w-full px-4 py-2 rounded-lg flex items-center justify-center gap-2 transition-all mobile-button
                ${selectedGoogleAccount 
                  ? 'bg-[#2745F8] hover:bg-[#2745F8]/90 text-white' 
                  : 'bg-gray-100 text-gray-400 cursor-not-allowed'}`}
            >
              {isConnectingGoogle ? (
                <>
                  <LoadingSpinner color="white" />
                  <span>Conectando...</span>
                </>
              ) : (
                <>
                  <LinkIcon className="h-4 w-4" />
                  <span>Conectar cuenta seleccionada</span>
                </>
              )}
            </button>

            {!selectedGoogleAccount && (
              <p className="text-xs sm:text-sm text-red-600 text-center">
                Selecciona una cuenta para continuar
              </p>
            )}
          </>
        )}
      </div>
    );
  };

  // Modified mobile view - No longer using the collapsible MobileAccordion
  const renderMobileView = () => (
    <div className="border border-gray-200 rounded-lg bg-white overflow-hidden mb-4">
      {/* Header section - always visible */}
      <div className="flex items-center p-4 border-b border-gray-200">
        <div className="p-2.5 rounded-full bg-[#2745F8]/10 mr-3">
          <FaGoogle className="h-5 w-5 text-[#2745F8]" />
        </div>
        <div>
          <h2 className="text-base font-medium text-gray-800">Google Ads</h2>
          {auth.user?.google_connected && (
            <div className="flex items-center text-green-600 text-xs">
              <Check className="h-3.5 w-3.5 mr-1" />
              <span>Connected</span>
            </div>
          )}
        </div>
      </div>
      
      {/* Content section - always visible */}
      <div className="p-4">
        {error && (
          <div className="flex items-center p-3 mb-3 bg-red-50 border border-red-200 rounded-lg text-red-600 text-xs">
            <AlertCircle className="h-4 w-4 mr-2 flex-shrink-0" />
            <p>{error}</p>
          </div>
        )}
        
        {success && (
          <div className="flex items-center p-3 mb-3 bg-green-50 border border-green-200 rounded-lg text-green-600 text-xs">
            <CheckCircle className="h-4 w-4 mr-2 flex-shrink-0" />
            <p>{success}</p>
          </div>
        )}
        
        {renderGoogleConnectSection()}
      </div>
    </div>
  );

  // Desktop view
  const renderDesktopView = () => (
    <div className="bg-white rounded-lg shadow-sm border border-gray-200">
      <div className="p-4">
        <div className="flex flex-col space-y-4">
          <div className="flex items-center space-x-4">
            <div className="p-3 rounded-full bg-[#2745F8]/10">
              <FaGoogle className="h-6 w-6 text-[#2745F8]" />
            </div>
            <div>
              <h2 className="text-lg font-semibold text-gray-800">Google Ads</h2>
              <p className="text-sm text-gray-600">
                {auth.user?.google_connected 
                  ? 'Account connected'
                  : googleAccounts.length 
                    ? 'Selecciona tu cuenta'
                    : 'Conecta tu cuenta de Google Ads'}
              </p>
            </div>
          </div>
          
          {error && (
            <div className="flex items-center p-4 bg-red-50 border border-red-200 rounded-lg text-red-600">
              <AlertCircle className="h-5 w-5 mr-2" />
              <p>{error}</p>
            </div>
          )}
          
          {success && (
            <div className="flex items-center p-4 bg-green-50 border border-green-200 rounded-lg text-green-600">
              <CheckCircle className="h-5 w-5 mr-2" />
              <p>{success}</p>
            </div>
          )}
          
          {renderGoogleConnectSection()}
        </div>
      </div>
    </div>
  );

  // Return responsive view
  return (
    <>
      <div className="hidden sm:block">
        {renderDesktopView()}
      </div>
      <div className="sm:hidden">
        {renderMobileView()}
      </div>
    </>
  );
};
// Meta Account Selector optimized for mobile
const MetaAccountSelector = ({ accounts, selectedAccount, onSelectAccount, activeAccountId }) => {
  const [isConnecting, setIsConnecting] = useState(false);
  const [processingAccountId, setProcessingAccountId] = useState(null);
  const { auth, updateUser } = useContext(AuthContext);

  const handleAccountSelection = async (account) => {
    try {
      setIsConnecting(true);
      setProcessingAccountId(account.account_id);
      
      const response = await axios.post(
        `${API_URL}/api/meta/select-account`,
        {
          account_id: account.account_id
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` }
        }
      );

      if (response.status === 200) {
        onSelectAccount(account);
        
        updateUser({
          ...auth.user,
          meta_ad_account_id: account.account_id,
          meta_connected: true
        });
      }
    } catch (error) {
      console.error('Error setting active Meta account:', error);
    } finally {
      setIsConnecting(false);
      setProcessingAccountId(null);
    }
  };

  return (
    <div className="mt-3 border-t border-gray-200 pt-3">
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between mb-3">
        <h3 className="text-sm font-medium text-gray-700 mb-1 sm:mb-0">Seleccionar cuenta para operar</h3>
        {activeAccountId && (
          <div className="flex items-center text-xs text-green-600">
            <Check className="h-3.5 w-3.5 mr-1" />
            Cuenta activa
          </div>
        )}
      </div>

      <div className="space-y-2">
        {accounts.map((account) => {
          const isActive = activeAccountId === account.account_id;
          const isProcessing = processingAccountId === account.account_id;
          
          return (
            <div
              key={account.account_id}
              className={`p-3 sm:p-4 rounded-lg border transition-colors ${
                isActive
                  ? 'border-[#2745F8] bg-[#2745F8]/10'
                  : 'border-gray-200 hover:border-[#2745F8]/50 hover:bg-[#2745F8]/5'
              }`}
            >
              <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                <div className="flex-1 mb-2 sm:mb-0">
                  <div className="font-medium text-gray-800 text-sm sm:text-base">
                    {account.name || `Account ${account.account_id}`}
                  </div>
                  <div className="text-xs text-gray-600">
                    ID: {account.account_id}
                    {account.currency && (
                      <>
                        <span className="mx-2">•</span>
                        {account.currency}
                      </>
                    )}
                  </div>
                </div>

                <div className="flex items-center justify-between sm:justify-end gap-2">
                  {account.status && (
                    <span className={`inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium ${
                      account.status === 'ACTIVE' 
                        ? 'bg-green-100 text-green-600' 
                        : account.status === 'UNSETTLED'
                          ? 'bg-yellow-100 text-yellow-600'
                          : 'bg-gray-100 text-gray-600'
                    }`}>
                      {account.status}
                    </span>
                  )}
                  
                  {isActive ? (
                    <div className="flex items-center text-green-600 bg-green-50 px-3 py-1.5 rounded-lg text-xs sm:text-sm">
                      <Check className="h-3.5 w-3.5 mr-1" />
                      <span>Cuenta activa</span>
                    </div>
                  ) : (
                    <button
                      onClick={() => handleAccountSelection(account)}
                      disabled={isConnecting || account.status !== 'ACTIVE'}
                      className={`px-3 py-1.5 rounded-lg text-xs sm:text-sm
                        transition-colors flex items-center gap-2 w-full sm:w-auto justify-center sm:justify-start mobile-button
                        ${account.status === 'ACTIVE'
                          ? 'bg-[#2745F8] text-white hover:bg-[#2745F8]/90'
                          : 'bg-gray-100 text-gray-400 cursor-not-allowed'
                        }
                        disabled:opacity-50 disabled:cursor-not-allowed`}
                    >
                      {isProcessing ? (
                        <>
                          <LoadingSpinner color="white" />
                          <span>Conectando...</span>
                        </>
                      ) : (
                        <>
                          <Settings className="h-3.5 w-3.5" />
                          <span>Usar cuenta</span>
                        </>
                      )}
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

// Main Meta Connection Component
const MetaConnection = () => {
  const { auth, updateUser } = useContext(AuthContext);
  const [businessId, setBusinessId] = useState('');
  const [isConnecting, setIsConnecting] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [metaAccounts, setMetaAccounts] = useState([]);
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);
  const [selectedMetaAccount, setSelectedMetaAccount] = useState(null);
  const [activeMetaAccount, setActiveMetaAccount] = useState(null);



  useEffect(() => {
    const loadMetaData = async () => {
      if (auth.user?.meta_connected) {
        console.log('MetaConnection: Force loading accounts data on mount');
        await fetchMetaAccounts();
      }
    };
    
    loadMetaData();
    
    // Intentar cargar estados guardados localmente como fallback
    const savedMetaAccounts = localStorage.getItem('meta_accounts');
    if (savedMetaAccounts && !metaAccounts.length) {
      try {
        const parsedAccounts = JSON.parse(savedMetaAccounts);
        setMetaAccounts(parsedAccounts);
        
        // También cargar cuenta activa si existe
        const savedActiveAccount = localStorage.getItem('meta_active_account');
        if (savedActiveAccount) {
          const parsedActive = JSON.parse(savedActiveAccount);
          setActiveMetaAccount(parsedActive);
          setSelectedMetaAccount(parsedActive);
        }
      } catch (e) {
        console.error('Error parsing saved accounts:', e);
      }
    }
  }, []);
  
  // Guardar datos en localStorage cuando cambien
  useEffect(() => {
    if (metaAccounts.length > 0) {
      localStorage.setItem('meta_accounts', JSON.stringify(metaAccounts));
    }
    if (activeMetaAccount) {
      localStorage.setItem('meta_active_account', JSON.stringify(activeMetaAccount));
    }
  }, [metaAccounts, activeMetaAccount]);
  useEffect(() => {
    // Initialize business ID from user data if available
    if (auth.user?.meta_ad_account_id) {
      setBusinessId(auth.user.meta_ad_account_id);
    }

    // Fetch Meta accounts if user is connected
    if (auth.user?.meta_connected) {
      fetchMetaAccounts();
    }
  }, [auth.user?.meta_connected, auth.user?.meta_ad_account_id]);

  useEffect(() => {
    // Set active account when accounts are loaded
    if (auth.user?.meta_ad_account_id && metaAccounts.length > 0) {
      const active = metaAccounts.find(
        account => account.account_id === auth.user.meta_ad_account_id || account.is_selected
      );
      setActiveMetaAccount(active || null);
      if (active) {
        setSelectedMetaAccount(active);
      }
    }
  }, [auth.user?.meta_ad_account_id, metaAccounts]);
  const fetchMetaAccounts = async () => {
    console.log('Fetching Meta accounts with token:', auth.token?.substring(0, 15) + '...');
    
    try {
      setIsLoadingAccounts(true);
      setError('');
      
      const response = await axios.get(
        `${API_URL}/api/meta/accounts`,
        {
          headers: { 
            Authorization: `Bearer ${auth.token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      
      console.log('Meta accounts response:', response.data);
      
      if (Array.isArray(response.data)) {
        setMetaAccounts(response.data);
        
        // Si hay un ID de cuenta activa, buscar la cuenta
        if (auth.user?.meta_ad_account_id) {
          const active = response.data.find(
            account => account.account_id === auth.user.meta_ad_account_id
          );
          
          if (active) {
            console.log('Found active Meta account:', active);
            setActiveMetaAccount(active);
            setSelectedMetaAccount(active);
          }
        }
      } else {
        console.warn('Meta accounts response is not an array:', response.data);
      }
    } catch (error) {
      console.error('Error fetching Meta accounts:', error);
      setError(error.response?.data?.error || 'Error getting Meta accounts');
    } finally {
      setIsLoadingAccounts(false);
    }
  };

  useEffect(() => {
    // Fetch Meta accounts if user is connected AND has a token
    if (auth.user?.meta_connected && auth.user?.meta_access_token) {
      fetchMetaAccounts();
    }
  }, [auth.user?.meta_connected, auth.user?.meta_ad_account_id]);
  const handleMetaLogin = () => {
    setIsConnecting(true);
    setError('');
    setSuccess('');
    
    // Guardar el token actual en localStorage
    if (auth.token) {
      localStorage.setItem('current_user_token', auth.token);
    }
    
    const appId = process.env.REACT_APP_FACEBOOK_APP_ID;
    // Usar una URL simple sin el parámetro token
    const redirectUri = encodeURIComponent(`${window.location.origin}/facebook-callback`);
    
    const scopes = [
      'email',
      'public_profile',
      'ads_read',
      'ads_management',
      'catalog_management',
      'business_management',
      'pages_show_list',
      'pages_read_engagement',
      'instagram_basic',
      'instagram_content_publish',
      'instagram_manage_insights'
    ].join(',');
    
    // Generar un estado para seguridad CSRF
    const state = Math.random().toString(36).substring(2, 15);
    localStorage.setItem('facebook_auth_state', state);
    
    window.location.href = `https://www.facebook.com/v18.0/dialog/oauth?client_id=${appId}&redirect_uri=${redirectUri}&scope=${scopes}&response_type=code&state=${state}`;
  };

  const handleMetaDisconnect = async () => {
    try {
      setIsConnecting(true);
      setError('');
      setSuccess('');
      
      const response = await axios.post(
        `${API_URL}/api/meta/disconnect`,
        {},
        {
          headers: { Authorization: `Bearer ${auth.token}` }
        }
      );
      
      if (response.status === 200) {
        // Update local state first, before updating the context
        setBusinessId('');
        setMetaAccounts([]);
        setSelectedMetaAccount(null);
        setActiveMetaAccount(null);
        
        // Update the authentication context
        updateUser({
          ...auth.user,
          meta_connected: false,
          meta_access_token: null,  // Make sure this field is removed
          meta_ad_account_id: null
        });
        
        // Show success message
        setSuccess('Conexión con Meta desactivada exitosamente');
        
        // Avoid any automatic requests
        return;
      }
    } catch (error) {
      console.error('Error disconnecting Meta:', error);
      setError(error.response?.data?.error || 'Error disconnecting Meta');
    } finally {
      setIsConnecting(false);
    }
  };

  const renderMetaConnectSection = () => {
    const isConnected = auth.user?.meta_connected;

    if (isConnected) {
      return (
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-3">
              <span className="flex items-center text-green-600">
                <Check className="h-5 w-5 mr-1" />
                <span className="text-sm font-medium">Connected</span>
              </span>
              <button
                onClick={handleMetaDisconnect}
                className="p-1.5 rounded-full text-gray-400 hover:text-red-600 hover:bg-red-50"
                title="Disconnect account"
                disabled={isConnecting}
              >
                <XCircle className="h-5 w-5" />
              </button>
            </div>
            {activeMetaAccount && (
              <div className="text-sm text-gray-700">
                Active account: {activeMetaAccount.name || activeMetaAccount.account_id}
              </div>
            )}
          </div>

          {/* Display connected Meta accounts if available */}
          {metaAccounts.length > 0 ? (
            <MetaAccountSelector
              accounts={metaAccounts}
              selectedAccount={selectedMetaAccount}
              onSelectAccount={setSelectedMetaAccount}
              activeAccountId={auth.user?.meta_ad_account_id}
            />
          ) : isLoadingAccounts ? (
            <div className="flex justify-center items-center py-4">
              <LoadingSpinner color="purple" />
              <span className="ml-2 text-sm text-gray-600">Loading accounts...</span>
            </div>
          ) : (
            <div className="text-center py-4 text-gray-600">
              No available accounts found
            </div>
          )}
        </div>
      );
    }

    // If not connected, show button to connect
    return (
      <div className="space-y-4 w-full">
        <button
          onClick={handleMetaLogin}
          disabled={isConnecting}
          className="w-full px-4 py-2 bg-[#2745F8] hover:bg-[#2745F8]/90 text-white rounded-lg flex items-center justify-center gap-2"
        >
          {isConnecting ? (
            <>
              <LoadingSpinner color="white" />
              <span>Conectando...</span>
            </>
          ) : (
            <>
              <FaFacebook className="h-4 w-4" />
              <span>Conectar con Meta Ads</span>
            </>
          )}
        </button>
      </div>
    );
  };

  return (
    <div className="bg-white rounded-lg shadow-sm border border-gray-200">
      <div className="p-4">
        <div className="flex flex-col space-y-4">
          <div className="flex items-center space-x-4">
            <div className="p-3 rounded-full bg-[#2745F8]/10">
              <FaFacebook className="h-6 w-6 text-[#2745F8]" />
            </div>
            <div>
              <h2 className="text-lg font-semibold text-gray-800">Meta Ads</h2>
              <p className="text-sm text-gray-600">
                {auth.user?.meta_connected
                  ? 'Cuenta conectada'
                  : 'Conecta tu cuenta de Meta Ads'}
              </p>
            </div>
          </div>

          {error && (
            <div className="flex items-center p-4 bg-red-50 border border-red-200 rounded-lg text-red-600">
              <AlertCircle className="h-5 w-5 mr-2" />
              <p>{error}</p>
            </div>
          )}
          
          {success && (
            <div className="flex items-center p-4 bg-green-50 border border-green-200 rounded-lg text-green-600">
              <Check className="h-5 w-5 mr-2" />
              <p>{success}</p>
            </div>
          )}

          {renderMetaConnectSection()}
        </div>
      </div>
    </div>
  );
};
const AccountsConnection = () => {
  const { auth, fetchMetaData } = useContext(AuthContext);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  
  // NUEVO: Efecto para verificar conexiones al cargar la página
  useEffect(() => {
    // Este efecto se ejecuta una vez al montar el componente
    console.log('AccountsConnection mounted, checking connections status');
    
    // Forzar una recarga de datos después de que el componente se monte
    if (auth.user) {
      console.log('User auth state:', auth.user);
      
      // Pequeño retraso para asegurar que la UI se ha renderizado
      const timer = setTimeout(() => {
        // Disparar una acción que fuerce la actualización del estado
        if (auth.user?.meta_connected) {
          console.log('Refreshing Meta connection data');
          // Si fetchMetaData está disponible en el contexto, úsalo
          if (typeof fetchMetaData === 'function') {
            fetchMetaData().catch(err => 
              console.error('Error refreshing Meta data:', err)
            );
          }
        }
      }, 300);
      
      return () => clearTimeout(timer);
    }
  }, [auth.user, fetchMetaData]);

  // Clear success/error messages after 5 seconds
  useEffect(() => {
    if (success || error) {
      const timer = setTimeout(() => {
        setSuccess('');
        setError('');
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [success, error]);

  return (
    <div className="bg-gray-50 h-full">
      {/* Header */}
      <div className="flex items-center mb-6 bg-white p-4 border-b">
        <div className="flex items-center space-x-4">
          <div className="p-3 rounded-full bg-[#2745F8]/10">
            <Settings className="w-6 h-6 text-[#2745F8]" />
          </div>
          <div>
            <h1 className="text-2xl font-bold text-gray-800">Conexiones de Cuenta</h1>
            <p className="text-sm text-gray-600">Administra tus conexiones con Google Ads y Meta</p>
          </div>
        </div>
      </div>

      {/* Alerts */}
      {error && (
        <div className="flex items-center p-4 mx-4 mb-4 bg-red-50 border border-red-200 rounded-lg text-red-600">
          <AlertCircle className="h-5 w-5 mr-2" />
          <p>{error}</p>
        </div>
      )}
      {success && (
        <div className="flex items-center p-4 mx-4 mb-4 bg-green-50 border border-green-200 rounded-lg text-green-600">
          <CheckCircle className="h-5 w-5 mr-2" />
          <p>{success}</p>
        </div>
      )}

      {/* Account sections */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 p-4">
        <GoogleSection />
        <MetaConnection />
      </div>

      {/* Floating WhatsApp Button */}
      {/* Caja de contacto en lugar del botón de WhatsApp */}
      <div className="mx-4 my-6">
        <div className="bg-white rounded-lg border border-gray-200 shadow-sm p-6">
          <div className="flex flex-col md:flex-row items-start md:items-center gap-4">
            <div className="bg-[#2745F8]/10 p-4 rounded-full">
              <FaWhatsapp className="h-8 w-8 text-[#2745F8]" />
            </div>
            
            <div className="flex-1 space-y-2">
              <h3 className="text-xl font-semibold text-gray-900">¿Te falta algo para comenzar a pautar con éxito?</h3>
              <ul className="list-disc pl-5 text-gray-600 space-y-1">
                <li>Cuentas publicitarias</li>
                <li>Página web</li>
                <li>Métodos de pago configurados</li>
                <li>Conversiones en tu sitio web</li>
              </ul>
              <p className="text-gray-600">
                Si aún no tienes todo listo, contáctanos y te ayudaremos a configurarlo paso a paso.
              </p>
            </div>
            
            <button
              onClick={() => window.open('https://wa.me/59899860399', '_blank')}
              className="px-6 py-3 bg-[#2745F8] hover:bg-[#2745F8]/90 text-white rounded-lg transition-all flex items-center gap-2 font-medium whitespace-nowrap shadow-sm"
            >
              <span>Contáctanos</span>
              <FaWhatsapp className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountsConnection;