import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate, Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from '../components/context/AuthContext';
import { useAuth } from '../components/context/AuthContext';
import Sidebar from '../components/sidebar';
import axios from 'axios';
import _ from 'lodash';

const API_URL = process.env.REACT_APP_API_URL;

const ProtectedLayout = () => {
  const { auth, logout } = useAuth();
  const { updateUser } = useContext(AuthContext);
  const navigate = useNavigate();
  
  // Base states
  const [isVerifying, setIsVerifying] = useState(true);
  const [sidebarExpanded, setSidebarExpanded] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [requestCount, setRequestCount] = useState(0);
  
  // Detectar tamaño de pantalla
  const [screenSize, setScreenSize] = useState({
    isMobile: false,
    isTablet: false,
    isDesktop: true
  });

  // Campaign states
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [isNewCampaignModalOpen, setIsNewCampaignModalOpen] = useState(false);

  // Google Ads states
  const [googleAccounts, setGoogleAccounts] = useState([]);
  const [googleCampaigns, setGoogleCampaigns] = useState({});
  const [loadingGoogleAccounts, setLoadingGoogleAccounts] = useState(false);
  const [loadingGoogleCampaigns, setLoadingGoogleCampaigns] = useState({});

  // Catalog states
  const [catalogs, setCatalogs] = useState({
    items: [],
    pages: [],
    loading: true,
    error: null,
    lastUpdated: null
  });

  // Metrics states
  const [lastMetricsSync, setLastMetricsSync] = useState(null);
  const [metricsSyncStatus, setMetricsSyncStatus] = useState({
    google: false,
    meta: false,
    inProgress: false,
    lastError: null
  });

  // Detectar tamaño de pantalla
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      const newScreenSize = {
        isMobile: width < 640,
        isTablet: width >= 640 && width < 1024,
        isDesktop: width >= 1024
      };
      
      setScreenSize(newScreenSize);
      
      // Si cambia a desktop, expandir sidebar
      if (newScreenSize.isDesktop && !screenSize.isDesktop) {
        setSidebarExpanded(true);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [screenSize.isDesktop]);

  // Utility function for retrying failed requests
  const retryRequest = async (requestFn, maxRetries = 3, currentRetry = 0) => {
    try {
      return await requestFn();
    } catch (error) {
      console.log(`Intento ${currentRetry + 1} fallido:`, error.message);
      
      if (error.response?.status === 500 && currentRetry < maxRetries) {
        const delay = Math.pow(2, currentRetry) * 1000;
        console.log(`Reintentando en ${delay}ms...`);
        
        await new Promise(resolve => setTimeout(resolve, delay));
        return retryRequest(requestFn, maxRetries, currentRetry + 1);
      }
      
      throw error;
    }
  };

  // Throttled API calls
  const throttledValidateToken = useCallback(
    _.throttle(async (token) => {
      if (requestCount >= 3) return;
      
      try {
        setRequestCount(prev => prev + 1);
        const response = await axios.get(`${API_URL}/api/validate-token`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        
        if (!response.data.valid) {
          throw new Error('Token invalid');
        }
      } catch (error) {
        console.error('Token validation failed:', error);
        logout();
        navigate('/login', { 
          state: { error: 'Tu sesión ha expirado. Por favor, inicia sesión nuevamente.' }
        });
      } finally {
        setRequestCount(prev => prev - 1);
        setIsVerifying(false);
      }
    }, 5000),
    [logout, navigate]
  );

  const throttledSyncMetrics = useCallback(
    _.throttle(async () => {
      if (requestCount >= 3 || metricsSyncStatus.inProgress) return;
      
      setMetricsSyncStatus(prev => ({ ...prev, inProgress: true, lastError: null }));
      
      try {
        setRequestCount(prev => prev + 1);
        if (auth.user?.google_customer_id) {
          const googleResponse = await retryRequest(() => 
            axios.get(
              `${API_URL}/api/metrics/sync/google/${auth.user.google_customer_id}`,
              {
                headers: { Authorization: `Bearer ${auth.token}` }
              }
            )
          );
          
          setMetricsSyncStatus(prev => ({
            ...prev,
            google: googleResponse.data.success
          }));
        }

        if (auth.user?.meta_ad_account_id) {
          const metaResponse = await retryRequest(() =>
            axios.get(
              `${API_URL}/api/metrics/sync/meta/${auth.user.meta_ad_account_id}`,
              {
                headers: { Authorization: `Bearer ${auth.token}` }
              }
            )
          );
          
          setMetricsSyncStatus(prev => ({
            ...prev,
            meta: metaResponse.data.success
          }));
        }

        setLastMetricsSync(new Date());
      } catch (error) {
        console.error('Error synchronizing metrics:', error);
        setMetricsSyncStatus(prev => ({
          ...prev,
          lastError: 'Error al sincronizar métricas'
        }));
      } finally {
        setRequestCount(prev => prev - 1);
        setMetricsSyncStatus(prev => ({ ...prev, inProgress: false }));
      }
    }, 10000),
    [auth.token, auth.user]
  );

  const throttledFetchCampaigns = useCallback(
    _.throttle(async () => {
      if (requestCount >= 3) return;
      
      setLoading(true);
      try {
        setRequestCount(prev => prev + 1);
        const [googleResponse, metaResponse] = await Promise.all([
          auth.user?.google_customer_id ? 
            retryRequest(() => 
              axios.get(`${API_URL}/google_ads/campaigns/${auth.user.google_customer_id}`, {
                headers: { Authorization: `Bearer ${auth.token}` }
              })
            ) : Promise.resolve({ data: [] }),
          
          auth.user?.meta_ad_account_id ?
            axios.get(`${API_URL}/api/meta/campaigns/${auth.user.meta_ad_account_id}`, {
              headers: { Authorization: `Bearer ${auth.token}` }
            }) : Promise.resolve({ data: [] })
        ]);

        const allCampaigns = [
          ...(googleResponse.data || []).map(campaign => ({
            ...campaign,
            platform: 'google_ads'
          })),
          ...(metaResponse.data || []).map(campaign => ({
            ...campaign,
            platform: 'meta_ads'
          }))
        ];

        setCampaigns(allCampaigns);
      } catch (error) {
        console.error('Error fetching campaigns:', error);
        if (error.response?.status === 401) {
          updateUser({
            ...auth.user,
            google_connected: false,
            google_customer_id: null
          });
        }
        setError('Error al cargar las campañas');
      } finally {
        setRequestCount(prev => prev - 1);
        setLoading(false);
      }
    }, 5000),
    [auth.token, auth.user, updateUser]
  );

  const throttledFetchGoogleAccounts = useCallback(
    _.throttle(async () => {
      if (requestCount >= 3) return;
      
      setLoadingGoogleAccounts(true);
      try {
        setRequestCount(prev => prev + 1);
        
        const makeRequest = () => 
          axios.get(
            `${API_URL}/google_ads/accounts`,
            { headers: { Authorization: `Bearer ${auth.token}` } }
          );
        
        const response = await retryRequest(makeRequest);
        
        const formattedAccounts = response.data.map(account => ({
          ...account,
          isManager: account.is_manager || false,
          status: account.status || 'ENABLED',
          descriptive_name: account.descriptive_name || `Cuenta ${account.customer_id}`,
          currency_code: account.currency_code || 'USD',
          time_zone: account.time_zone || 'GMT'
        }));
  
        setGoogleAccounts(formattedAccounts);
  
      } catch (error) {
        console.error('Error después de todos los reintentos:', error);
        if (error.response?.status === 401) {
          updateUser({
            ...auth.user,
            google_connected: false,
            google_customer_id: null
          });
        }
      } finally {
        setRequestCount(prev => prev - 1);
        setLoadingGoogleAccounts(false);
      }
    }, 5000),
    [auth.token, auth.user, updateUser]
  );

  const throttledFetchGoogleCampaigns = useCallback(
    _.throttle(async (customerId) => {
      if (requestCount >= 3 || loadingGoogleCampaigns[customerId]) return;
      
      setLoadingGoogleCampaigns(prev => ({ ...prev, [customerId]: true }));
      try {
        setRequestCount(prev => prev + 1);
        
        const response = await retryRequest(() =>
          axios.get(
            `${API_URL}/google_ads/campaigns/${customerId}`,
            {
              headers: { Authorization: `Bearer ${auth.token}` }
            }
          )
        );

        const formattedCampaigns = (response.data || []).map(campaign => ({
          campaign_id: campaign.campaign_id,
          name: campaign.name,
          status: campaign.status,
          advertising_channel_type: campaign.advertising_channel_type,
          budget_amount_micros: campaign.budget_amount_micros,
          metrics: campaign.metrics || {
            impressions: 0,
            clicks: 0,
            cost: 0,
            conversions: 0,
            conversion_value: 0
          },
          start_date: campaign.start_date,
          end_date: campaign.end_date
        }));

        setGoogleCampaigns(prev => ({
          ...prev,
          [customerId]: formattedCampaigns
        }));
      } catch (error) {
        console.error('Error fetching Google campaigns:', error);
        if (error.response?.status === 401) {
          updateUser({
            ...auth.user,
            google_connected: false,
            google_customer_id: null
          });
        }
      } finally {
        setRequestCount(prev => prev - 1);
        setLoadingGoogleCampaigns(prev => ({ ...prev, [customerId]: false }));
      }
    }, 5000),
    [auth.token, auth.user, updateUser]
  );

  const throttledFetchCatalogs = useCallback(
    _.throttle(async () => {
      if (requestCount >= 3) return;
      
      setCatalogs(prev => ({ ...prev, loading: true, error: null }));
      try {
        setRequestCount(prev => prev + 1);
        
        if (!auth.user?.meta_ad_account_id) {
          setCatalogs(prev => ({
            ...prev,
            items: [],
            pages: [],
            loading: false,
            lastUpdated: new Date()
          }));
          return;
        }

        const response = await axios.get(
          `${API_URL}/api/meta/catalogs`,
          { headers: { Authorization: `Bearer ${auth.token}` } }
        );

        setCatalogs({
          items: response.data.catalogs || [],
          pages: response.data.pages || [],
          loading: false,
          error: null,
          lastUpdated: new Date()
        });

      } catch (error) {
        console.error('Error fetching catalogs:', error);
        setCatalogs(prev => ({
          ...prev,
          loading: false,
          error: 'Error al cargar los catálogos'
        }));
      } finally {
        setRequestCount(prev => prev - 1);
      }
    }, 5000),
    [auth.token, auth.user?.meta_ad_account_id]
  );

  // Token validation and initial user data refresh on mount
  useEffect(() => {
    if (!auth.token) {
      setIsVerifying(false);
      navigate('/login');
      return;
    }
    
    const verifyAndLoad = async () => {
      try {
        // Validar token
        const response = await axios.get(`${API_URL}/api/validate-token`, {
          headers: { Authorization: `Bearer ${auth.token}` }
        });
        
        if (!response.data.valid) {
          throw new Error('Token invalid');
        }
        
        // Si el token es válido, obtener datos actualizados del usuario
        try {
          const userResponse = await axios.get(`${API_URL}/api/users/active-session`, {
            headers: { Authorization: `Bearer ${auth.token}` }
          });
          
          if (userResponse.data) {
            console.log('User session refreshed:', userResponse.data);
            // Actualizar el usuario con datos frescos del servidor
            updateUser(userResponse.data);
          }
        } catch (userError) {
          console.error('Error getting active session:', userError);
          // No hacemos logout aquí, solo loggeamos el error
        }
        
        setIsVerifying(false);
        
        // Cargar datos inmediatamente después de verificar
        setTimeout(() => {
          if (auth.user?.meta_connected) {
            console.log('User has Meta connection, loading data...');
            // Cargar datos de Meta
            try {
              axios.get(`${API_URL}/api/meta/accounts`, {
                headers: { Authorization: `Bearer ${auth.token}` }
              }).then(response => {
                console.log('Meta accounts loaded on init:', response.data);
              }).catch(err => {
                console.error('Error loading Meta accounts on init:', err);
              });
            } catch (metaError) {
              console.error('Error loading Meta accounts:', metaError);
            }
          }
          
          if (auth.user?.google_connected) {
            console.log('User has Google connection, loading data...');
            throttledFetchGoogleAccounts();
          }
          
          throttledFetchCampaigns();
          throttledFetchCatalogs();
        }, 300);
        
      } catch (error) {
        console.error('Token validation failed:', error);
        logout();
        navigate('/login', { 
          state: { error: 'Tu sesión ha expirado. Por favor, inicia sesión nuevamente.' }
        });
      }
    };
    
    verifyAndLoad();
  }, [auth.token, navigate, logout, updateUser, throttledFetchCampaigns, throttledFetchCatalogs, throttledFetchGoogleAccounts, auth.user]);

  // Initial data load
  useEffect(() => {
    if (!isVerifying && auth.token) {
      console.log('Initial data load started');
      throttledFetchCampaigns();
      throttledFetchCatalogs();
      if (auth.user?.google_connected) {
        throttledFetchGoogleAccounts();
      }
    }
  }, [isVerifying, throttledFetchCampaigns, throttledFetchCatalogs, throttledFetchGoogleAccounts, auth.user?.google_connected, auth.token]);

  // Periodic metrics sync
  useEffect(() => {
    const syncInterval = 24 * 60 * 60 * 1000; // 24 hours
    
    if (!lastMetricsSync || (new Date() - new Date(lastMetricsSync)) >= syncInterval) {
      throttledSyncMetrics();
    }

    const intervalId = setInterval(throttledSyncMetrics, syncInterval);
    return () => clearInterval(intervalId);
  }, [lastMetricsSync, throttledSyncMetrics]);

  // Función para navegar programáticamente
  const handleNavigate = useCallback((path) => {
    navigate(path);
  }, [navigate]);

  if (isVerifying) {
    return (
      <div className="flex h-screen bg-white items-center justify-center">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto mb-4"></div>
          <p className="text-gray-700">Verificando autenticación...</p>
        </div>
      </div>
    );
  }
  if (!auth.token) {
    return <Navigate to="/login" replace />;
  }

  return (
    <div className="flex h-screen bg-white overflow-hidden">
      {/* Sidebar */}
      <Sidebar 
        campaigns={campaigns}
        selectedCampaign={selectedCampaign}
        setSelectedCampaign={setSelectedCampaign}
        setIsNewCampaignModalOpen={setIsNewCampaignModalOpen}
        onExpandedChange={setSidebarExpanded}
        onNavigate={handleNavigate}
      />
      
      {/* Contenido principal que se ajusta automáticamente según el estado del sidebar */}
      <main 
        className={`flex-1 overflow-x-hidden overflow-y-auto transition-all duration-300 ease-in-out
          ${screenSize.isDesktop 
            ? sidebarExpanded ? 'ml-64' : 'ml-16' 
            : 'ml-0'}`}
      >
        <div className="p-4">
          <Outlet context={{ 
            campaigns,
            setCampaigns,
            catalogs,
            fetchCampaigns: throttledFetchCampaigns,
            fetchCatalogs: throttledFetchCatalogs,
            googleAccounts,
            googleCampaigns,
            loadingGoogleAccounts,
            loadingGoogleCampaigns,
            fetchGoogleAccounts: throttledFetchGoogleAccounts,
            fetchGoogleCampaigns: throttledFetchGoogleCampaigns,
            isNewCampaignModalOpen,
            setIsNewCampaignModalOpen,
            lastMetricsSync,
            syncMetrics: throttledSyncMetrics,
            metricsSyncStatus,
            loading,
            error
          }}/>
        </div>
      </main>
    </div>
  );
};

export default ProtectedLayout;