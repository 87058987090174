import React, { useState, useEffect, useCallback } from "react";
import {
  User,
  CreditCard,
  Save,
  Bell,
  Check,
  Zap,
  Award,
  Shield,
  X,
  Receipt,
  History,
  Download,
  AlertTriangle,
  Clock,
  Tag,
  Loader,
  Sparkles,
  Camera,
} from "lucide-react";
import { useAuth } from "../../components/context/AuthContext";
import axios from "axios";

const SUBSCRIPTION_PLANS = {
  free_trial: {
    name: "SofIA",
    subtitle: "espor.ai",
    price: 0,
    description: "Tu asistente inicial de marketing digital",
    features: [
      { enabled: true, text: "Genero previsualizaciones de tus anuncios" },
      {
        enabled: true,
        text: "Preguntame lo que quieras de Marketing Digital o ecommerce",
      },
      {
        enabled: true,
        text: "Conectá tus cuentas y te respondo lo que quieras de tus campañas",
      },
      { enabled: false, text: "No creo las campañas por vos." },
      {
        enabled: false,
        text: "No hago experimentos para buscar nuevas oportunidades.",
      },
      {
        enabled: false,
        text: "No me meto en estrategia personalizada, reuniones o análisis profundos, pero te doy una base sólida.",
      },
    ],
  },
  performance: {
    name: "LucIA",
    subtitle: "espor.ai",
    price: 9.99,
    regularPrice: 250, // Precio regular sin descuento
    discountPercentage: 96, // Porcentaje de descuento del primer pago
    description: "Tu experta en performance marketing",
    features: [
      {
        enabled: true,
        text: "Creo, optimizo y hago ajustes constantes en tus campañas de Google y Meta Ads.",
      },
      {
        enabled: true,
        text: "Te doy soporte por email para responder tus dudas.",
      },
      {
        enabled: true,
        text: "Cada semana te paso un informe con números claros para que sepas cómo van las cosas.",
      },
      {
        enabled: true,
        text: "Optimizo campañas todo el tiempo, no solo cuando lo pedís.",
      },
      {
        enabled: true,
        text: "Hago experimentos para encontrar nuevas oportunidades de venta.",
      },
      {
        enabled: true,
        text: "Te armo una estrategia personalizada, pensada para vos y tu negocio.",
      },
      {
        enabled: false,
        text: "No manejo tu P&L para que veas si estás ganando plata o no.",
      },
      { enabled: false, text: "No hago auditoría de e-commerce." },
      {
        enabled: false,
        text: "No me meto en análisis de UX, pero sí en la parte de marketing digital.",
      },
    ],
  },
  premium: {
    name: "LeticIA",
    subtitle: "espor.ai",
    price: "Contactar",
    description: "Tu directora de marketing digital",
    features: [
      {
        enabled: true,
        text: "Creo, optimizo y ajusto en tiempo real tus campañas en Google y Meta Ads.",
      },
      {
        enabled: true,
        text: "Soporte por email sin vueltas. Siempre tenés línea directa conmigo.",
      },
      {
        enabled: true,
        text: "Cada semana te entrego un reporte completo con insights clave.",
      },
      {
        enabled: true,
        text: "Hago optimización continua para que cada dólar invertido rinda al máximo.",
      },
      {
        enabled: true,
        text: "Experimento y pruebo nuevas estrategias para encontrar oportunidades únicas.",
      },
      {
        enabled: true,
        text: "Estrategia personalizada, adaptada a tu negocio y a tu público.",
      },
      {
        enabled: true,
        text: "Reuniones semanales para que siempre estemos alineados.",
      },
      {
        enabled: true,
        text: "Planificación estratégica, para que cada paso tenga un propósito.",
      },
      {
        enabled: true,
        text: "Manejo tu P&L, así sabemos exactamente cuánto ganás y dónde ajustar.",
      },
      {
        enabled: true,
        text: "Auditoría de tu e-commerce, para asegurarnos de que todo esté funcionando bien.",
      },
      {
        enabled: true,
        text: "Análisis de UX + sugerencias para mejorar los KPIs, porque cada detalle cuenta.",
      },
    ],
  },
};

const PlanBadge = (props) => {
  const { children, color } = props;
  return (
    <div
      className={`absolute -top-2 right-4 ${color} text-white px-2 py-0.5 rounded-full text-xs font-medium md:-top-3 md:right-6 md:px-3 md:py-1 md:text-sm`}
    >
      {children}
    </div>
  );
};

const CouponInput = ({ planId, onCouponApplied, initialCouponCode }) => {
  const [couponCode, setCouponCode] = useState(initialCouponCode || "");
  const [isValidating, setIsValidating] = useState(false);
  const [couponStatus, setCouponStatus] = useState(null); // null, 'valid', 'invalid'
  const [appliedCoupon, setAppliedCoupon] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isFirstPaymentDiscount, setIsFirstPaymentDiscount] = useState(
    planId === "performance"
  );

  // Si es el plan Performance, aplicar automáticamente el descuento del primer pago
  useEffect(() => {
    if (planId === "performance" && !appliedCoupon) {
      setIsFirstPaymentDiscount(true);
      if (!initialCouponCode) {
        handleAutoApplyCoupon();
      }
    } else {
      setIsFirstPaymentDiscount(false);
    }
  }, [planId, initialCouponCode, appliedCoupon]);

  // Aplicar automáticamente el cupón FIRST96 para el plan Performance
  const handleAutoApplyCoupon = async () => {
    try {
      setIsValidating(true);
      const response = await axios.post("/api/coupons/validate", {
        code: "FIRST96",
        plan_id: "performance",
      });

      if (response.data.valid) {
        setCouponStatus("valid");
        setAppliedCoupon({
          code: "FIRST96",
          discountType: response.data.discount_type,
          discountValue: response.data.discount_value,
          originalPrice: response.data.original_price,
          finalPrice: response.data.final_price,
        });
        setCouponCode("FIRST96");

        // Notificar al componente padre
        if (onCouponApplied) {
          onCouponApplied(
            "FIRST96",
            response.data.final_price,
            response.data.original_price
          );
        }
      }
    } catch (error) {
      console.error("Error al aplicar el cupón automáticamente:", error);
      setErrorMessage(
        "Error al aplicar el código de descuento. Intente nuevamente."
      );
    } finally {
      setIsValidating(false);
    }
  };

  const handleCouponValidation = async () => {
    if (!couponCode.trim()) {
      setErrorMessage("Por favor ingrese un código de cupón");
      return;
    }

    try {
      setIsValidating(true);
      setErrorMessage("");

      const response = await axios.post("/api/coupons/validate", {
        code: couponCode,
        plan_id: planId,
      });

      if (response.data.valid) {
        setCouponStatus("valid");
        setAppliedCoupon({
          code: couponCode,
          discountType: response.data.discount_type,
          discountValue: response.data.discount_value,
          originalPrice: response.data.original_price,
          finalPrice: response.data.final_price,
        });

        // Notificar al componente padre
        if (onCouponApplied) {
          onCouponApplied(
            couponCode,
            response.data.final_price,
            response.data.original_price
          );
        }
      } else {
        setCouponStatus("invalid");
        setAppliedCoupon(null);
        setErrorMessage(response.data.message || "Cupón inválido");

        // Notificar al componente padre que no hay cupón
        if (onCouponApplied) {
          onCouponApplied(null, null, null);
        }
      }
    } catch (error) {
      console.error("Error al validar cupón:", error);
      setCouponStatus("invalid");
      setErrorMessage(
        error.response?.data?.error || "Error al validar el cupón"
      );
    } finally {
      setIsValidating(false);
    }
  };

  const handleRemoveCoupon = () => {
    setCouponCode("");
    setCouponStatus(null);
    setAppliedCoupon(null);
    setErrorMessage("");

    // Notificar al componente padre
    if (onCouponApplied) {
      onCouponApplied(null, null, null);
    }

    // Si es el plan Performance, volver a aplicar el cupón de primer pago
    if (planId === "performance") {
      handleAutoApplyCoupon();
    }
  };

  return (
    <div className="mt-3 md:mt-4">
      {/* Banner de descuento del primer pago para el plan Performance */}
      {isFirstPaymentDiscount && (
        <div className="p-2 md:p-3 bg-blue-50 border border-blue-100 rounded-lg mb-3 md:mb-4 text-xs md:text-sm">
          <div className="flex items-start">
            <div className="flex-shrink-0 mt-0.5">
              <Tag className="h-4 w-4 md:h-5 md:w-5 text-blue-500" />
            </div>
            <div className="ml-2 md:ml-3">
              <h3 className="font-medium text-blue-800">
                ¡96% de descuento en tu primer pago!
              </h3>
              <div className="mt-1 md:mt-2 text-blue-700">
                <p>
                  Tu primer pago incluye un descuento exclusivo del 96%. Pagarás
                  sólo $9.99 USD en lugar de $250 USD.
                </p>
                <p className="mt-1 md:mt-2 font-medium">
                  El cupón FIRST96 se ha aplicado automáticamente.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Sección de entrada de cupón */}
      {!appliedCoupon && !isFirstPaymentDiscount && (
        <div>
          <h4 className="text-xs md:text-sm font-medium text-gray-700 mb-1 md:mb-2">
            ¿Tienes un código de descuento?
          </h4>
          <div className="flex space-x-2">
            <input
              type="text"
              className="flex-1 px-2 py-1.5 md:px-3 md:py-2 bg-white text-black border border-gray-200 rounded-lg 
              focus:outline-none focus:border-[#0F31F7] text-xs md:text-sm"
              placeholder="Ingresa tu código"
              value={couponCode}
              onChange={(e) => setCouponCode(e.target.value.toUpperCase())}
              disabled={isValidating}
            />
            <button
              onClick={handleCouponValidation}
              disabled={isValidating || !couponCode.trim()}
              className="px-3 py-1.5 md:px-4 md:py-2 bg-[#0F31F7] text-white rounded-lg hover:bg-blue-700 
              transition-colors duration-150 whitespace-nowrap disabled:opacity-50 disabled:cursor-not-allowed text-xs md:text-sm"
            >
              {isValidating ? (
                <Loader className="w-3 h-3 md:w-4 md:h-4 animate-spin mx-auto" />
              ) : (
                "Aplicar"
              )}
            </button>
          </div>

          {/* Mensaje de error */}
          {couponStatus === "invalid" && (
            <div className="mt-1 md:mt-2 p-1.5 md:p-2 bg-red-50 border border-red-200 rounded-lg text-xs md:text-sm text-red-600 flex items-start gap-1 md:gap-2">
              <AlertTriangle className="w-3 h-3 md:w-4 md:h-4 mt-0.5 flex-shrink-0" />
              <span>{errorMessage || "Cupón inválido"}</span>
            </div>
          )}
        </div>
      )}

      {/* Cupón aplicado */}
      {(appliedCoupon || isFirstPaymentDiscount) && (
        <div className="mt-1 md:mt-2 p-2 md:p-3 bg-green-50 border border-green-200 rounded-lg">
          <div className="flex justify-between items-start">
            <div className="flex items-start">
              <Check className="w-4 h-4 md:w-5 md:h-5 text-green-500 mt-0.5 mr-1.5 md:mr-2 flex-shrink-0" />
              <div>
                <p className="text-xs md:text-sm font-medium text-green-800">
                  Cupón {appliedCoupon?.code || "FIRST96"} aplicado
                </p>
                <p className="text-xs text-green-700 mt-0.5 md:mt-1">
                  {(appliedCoupon?.discountType || "percentage") ===
                  "percentage"
                    ? `${appliedCoupon?.discountValue || 96}% de descuento`
                    : `$${appliedCoupon?.discountValue} USD de descuento`}
                </p>
                <div className="mt-1 md:mt-2">
                  <span className="text-xs text-gray-500 line-through mr-1 md:mr-2">
                    ${appliedCoupon?.originalPrice || 250} USD
                  </span>
                  <span className="text-xs md:text-sm font-bold text-green-700">
                    ${appliedCoupon?.finalPrice || 9.99} USD
                  </span>
                </div>
              </div>
            </div>
            {!isFirstPaymentDiscount && (
              <button
                onClick={handleRemoveCoupon}
                className="text-gray-500 hover:text-gray-700"
              >
                <X className="h-4 w-4 md:h-5 md:w-5" />
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const Profile = () => {
  const { auth, updateUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [activeTab, setActiveTab] = useState("profile");
  const [profileData, setProfileData] = useState({});
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [processingSubscription, setProcessingSubscription] = useState(false);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [loadingPaymentHistory, setLoadingPaymentHistory] = useState(false);
  const [couponCode, setCouponCode] = useState(null);
  const [discountedPrice, setDiscountedPrice] = useState(null);
  const [originalPrice, setOriginalPrice] = useState(null);
  const [billingLoading, setBillingLoading] = useState(false);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    business_name: "",
    website_url: "",
    business_details: "",
    digital_experience: "",
    marketing_experience: "",
    industry: "",
    company_size: "",
    monthly_budget: "",
    has_team: false,
    team_size: null,
    notifications: {
      email: true,
      push: true,
      marketing: false,
    },
    // Campos para facturación
    billing: {
      company_name: "",
      billing_email: "",
      address_line1: "",
      address_line2: "",
      city: "",
      state: "",
      postal_code: "",
      country: "",
      tax_id: "",
    },
  });

  useEffect(() => {
    if (profileData?.profile_image) {
      setImagePreview(profileData.profile_image);
    }
  }, [profileData?.profile_image]);

  const loadProfileData = useCallback(async () => {
    try {
      const response = await axios.get("/api/user");
      const data = response.data;

      // Actualizar el estado del perfil
      setProfileData(data);

      // Actualizar el formulario con los datos
      setFormData((prevFormData) => ({
        ...prevFormData,
        first_name: data.first_name || "",
        last_name: data.last_name || "",
        business_name: data.business_name || "",
        website_url: data.website_url || "",
        business_details: data.business_details || "",
        digital_experience: data.digital_experience || "",
        marketing_experience: data.marketing_experience || "",
        industry: data.industry || "",
        company_size: data.company_size || "",
        monthly_budget: data.monthly_budget || "",
        has_team: data.has_team || false,
        team_size: data.team_size || null,
        notifications: {
          email: data.notifications?.email ?? true,
          push: data.notifications?.push ?? true,
          marketing: data.notifications?.marketing ?? false,
        },
      }));
    } catch (err) {
      console.error("Error loading profile data:", err);
      setError(err.response?.data?.error || "Error cargando datos del perfil");
    }
  }, []);

  const loadSubscriptionStatus = useCallback(async () => {
    try {
      const response = await axios.get("/api/subscriptions/status");
      if (response.data.subscription) {
        setSubscriptionStatus(response.data.subscription);
      } else {
        setSubscriptionStatus(null);
      }
    } catch (err) {
      setError(
        err.response?.data?.error || "Error cargando estado de suscripción"
      );
    }
  }, []);

  const handleBillingInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      billing: {
        ...prevFormData.billing,
        [name]: value,
      },
    }));
  };

  const loadPaymentHistory = useCallback(async () => {
    try {
      setLoadingPaymentHistory(true);
      const response = await axios.get("/api/payments/history");
      setPaymentHistory(response.data.payments || []);
    } catch (err) {
      console.error("Error cargando historial de pagos:", err);
      setError(
        err.response?.data?.error || "Error cargando historial de pagos"
      );
    } finally {
      setLoadingPaymentHistory(false);
    }
  }, []);

  // Función específica para cargar datos de facturación
  const loadBillingData = useCallback(async () => {
    if (!auth.token) return;

    try {
      setBillingLoading(true);
      const response = await axios.get("/api/user/billing");

      if (response.data && response.data.billing) {
        const billingData = response.data.billing;

        // Actualizar SOLO los datos de facturación en el formulario
        setFormData((prevFormData) => ({
          ...prevFormData,
          billing: {
            company_name: billingData.company_name || "",
            billing_email: billingData.billing_email || "",
            address_line1: billingData.address_line1 || "",
            address_line2: billingData.address_line2 || "",
            city: billingData.city || "",
            state: billingData.state || "",
            postal_code: billingData.postal_code || "",
            country: billingData.country || "",
            tax_id: billingData.tax_id || "",
          },
        }));
      }
    } catch (err) {
      console.error("Error cargando datos de facturación:", err);
      // No mostramos el error en la UI para no confundir al usuario
    } finally {
      setBillingLoading(false);
    }
  }, [auth.token]);

  // Efecto para cargar datos cuando cambia a pestaña de facturación
  useEffect(() => {
    if (activeTab === "billing" && auth.token) {
      loadBillingData();
    }
  }, [activeTab, auth.token, loadBillingData]);

  // Carga inicial de datos
  useEffect(() => {
    if (!auth.token) {
      setError("No hay token de autenticación");
      setLoading(false);
      return;
    }

    const loadData = async () => {
      try {
        setLoading(true);
        await Promise.all([loadProfileData(), loadSubscriptionStatus()]);

        // No cargamos datos de facturación aquí, solo cuando se activa la pestaña
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [loadProfileData, loadSubscriptionStatus, auth.token]);

  // Cargar historial de pagos cuando se cambia a la pestaña de pagos
  useEffect(() => {
    if (activeTab === "payments" && auth.token) {
      loadPaymentHistory();
    }
  }, [activeTab, auth.token, loadPaymentHistory]);

  const handlePhotoUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Validar el tipo de archivo
    const validTypes = ["image/jpeg", "image/png", "image/gif"];
    if (!validTypes.includes(file.type)) {
      setError("Por favor selecciona una imagen en formato JPG, PNG o GIF");
      return;
    }

    // Validar el tamaño del archivo (5MB)
    if (file.size > 5 * 1024 * 1024) {
      setError("La imagen no debe superar los 5MB");
      return;
    }

    const formData = new FormData();
    formData.append("profileImage", file);

    try {
      setUploadingImage(true);
      setError("");
      setSuccess("");

      const imageResponse = await axios.post(
        "/api/user/profile-image",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (imageResponse.data && imageResponse.data.imageUrl) {
        // Actualizar el perfil con la nueva imagen
        const updateData = {
          profile_image: imageResponse.data.imageUrl,
        };

        const userResponse = await axios.put("/api/user", updateData, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        // Actualizar los estados
        setProfileData((prev) => ({
          ...prev,
          profile_image: imageResponse.data.imageUrl,
        }));

        updateUser((prev) => ({
          ...prev,
          profile_image: imageResponse.data.imageUrl,
        }));

        setImagePreview(imageResponse.data.imageUrl);
        setSuccess("Foto de perfil actualizada exitosamente");
      }
    } catch (err) {
      console.error("Full error details:", err);
      setError(
        err.response?.data?.error || "Error actualizando foto de perfil"
      );
    } finally {
      setUploadingImage(false);
    }
  };

  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    try {
      if (!formData.first_name || !formData.last_name) {
        setError("El nombre y apellido son requeridos");
        return;
      }

      const updateData = {
        first_name: formData.first_name.trim(),
        last_name: formData.last_name.trim(),
        business_name: formData.business_name?.trim(),
        website_url: formData.website_url?.trim(),
        business_details: formData.business_details?.trim(),
        digital_experience: formData.digital_experience,
        marketing_experience: formData.marketing_experience,
        industry: formData.industry,
        company_size: formData.company_size,
        monthly_budget: formData.monthly_budget
          ? parseFloat(formData.monthly_budget)
          : null,
        has_team: formData.has_team,
        team_size: formData.team_size,
        notifications: formData.notifications,
      };

      const response = await axios.put("/api/user", updateData);

      setProfileData(response.data);
      updateUser(response.data);
      setSuccess("Perfil actualizado exitosamente");
      window.scrollTo(0, 0);
    } catch (err) {
      console.error("Profile update error:", err);
      setError(err.response?.data?.error || "Error actualizando el perfil");
      window.scrollTo(0, 0);
    }
  };

  const handleBillingUpdate = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    setBillingLoading(true);

    try {
      const billingData = {
        billing: formData.billing,
      };

      const response = await axios.put("/api/user/billing", billingData);

      // Actualizar solo la información de facturación en el estado del perfil
      setProfileData((prev) => ({
        ...prev,
        billing: response.data.billing,
      }));

      setSuccess("Información de facturación actualizada exitosamente");
      window.scrollTo(0, 0);
    } catch (err) {
      console.error("Billing update error:", err);
      setError(
        err.response?.data?.error ||
          "Error actualizando la información de facturación"
      );
      window.scrollTo(0, 0);
    } finally {
      setBillingLoading(false);
    }
  };

  const handleCouponApplied = (code, finalPrice, original) => {
    setCouponCode(code);
    setDiscountedPrice(finalPrice);
    setOriginalPrice(original);
  };

  const handleSubscribe = async (planId) => {
    try {
      setProcessingSubscription(true);
      setError("");

      // Si es el plan premium, redirigir a WhatsApp
      if (SUBSCRIPTION_PLANS[planId].price === "Contactar") {
        const whatsappNumber = "+59899860399";
        const message = encodeURIComponent(
          "Hola, estoy interesado en el plan Premium de Espor.ai."
        );
        window.open(
          `https://wa.me/${whatsappNumber}?text=${message}`,
          "_blank"
        );
        return;
      }

      // Crear la solicitud incluyendo el código de cupón si existe
      const requestData = {
        plan_id: planId,
      };

      // Agregar código de cupón si existe
      if (couponCode) {
        requestData.coupon_code = couponCode;
      } else if (planId === "performance") {
        // Para el plan Performance, aplicar automáticamente el cupón FIRST96
        requestData.coupon_code = "FIRST96";
      }

      const response = await axios.post(
        "/api/subscriptions/create",
        requestData
      );

      if (response.data.contact_url) {
        window.location.href = response.data.contact_url;
        return;
      }

      if (response.data.init_point) {
        window.location.href = response.data.init_point;
      }
    } catch (err) {
      setError(err.response?.data?.error || "Error al suscribirse al plan");
      window.scrollTo(0, 0);
    } finally {
      setProcessingSubscription(false);
    }
  };

  const handleCancelSubscription = async () => {
    if (!window.confirm("¿Estás seguro que deseas cancelar tu suscripción?"))
      return;

    try {
      setProcessingSubscription(true);
      await axios.post("/api/subscriptions/cancel");
      await loadSubscriptionStatus();
      setSuccess("Suscripción cancelada exitosamente");
    } catch (err) {
      setError(err.response?.data?.error || "Error cancelando suscripción");
    } finally {
      setProcessingSubscription(false);
    }
  };

  const handleExportPaymentHistory = async () => {
    if (paymentHistory.length === 0) {
      return;
    }

    try {
      // Mostrar estado de descarga
      setLoading(true);

      // Realizar solicitud para exportar
      const response = await axios.get("/api/payments/export", {
        responseType: "blob", // Importante para manejar la descarga del archivo
      });

      // Crear una URL para el blob
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Crear un elemento de enlace y simular clic
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `historial-pagos-${new Date().toISOString().split("T")[0]}.csv`
      );
      document.body.appendChild(link);
      link.click();

      // Limpiar
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);

      setSuccess("Historial de pagos exportado exitosamente");
    } catch (err) {
      console.error("Error exportando historial de pagos:", err);
      setError("Error al exportar el historial de pagos");
    } finally {
      setLoading(false);
    }
  };

  // Mostrar foto de perfil y opción para cambiarla
  const renderProfilePhotoSection = () => {
    return (
      <div className="relative mx-auto mb-4 w-20 h-20 md:w-24 md:h-24 lg:w-32 lg:h-32">
        <div className="w-full h-full rounded-full overflow-hidden bg-white border-4 border-gray-200">
          {imagePreview ? (
            <img
              src={imagePreview}
              alt="Foto de perfil"
              className="w-full h-full object-cover"
              onError={(e) => {
                e.target.src = "/default-avatar.png";
                setImagePreview(null);
              }}
            />
          ) : (
            <div className="w-full h-full flex items-center justify-center bg-gray-100">
              <User className="w-10 h-10 md:w-12 md:h-12 lg:w-16 lg:h-16 text-gray-400" />
            </div>
          )}
        </div>

        <label
          htmlFor="photoUpload"
          className="absolute bottom-0 right-0 bg-[#0F31F7] hover:bg-blue-700 text-white rounded-full p-1.5 md:p-2 cursor-pointer shadow-md transition-all duration-150"
        >
          <input
            type="file"
            id="photoUpload"
            className="hidden"
            onChange={handlePhotoUpload}
            accept="image/jpeg,image/png,image/gif"
          />
          <Camera className="w-3.5 h-3.5 md:w-4 md:h-4 lg:w-5 lg:h-5" />
        </label>

        {uploadingImage && (
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white bg-opacity-75 rounded-full p-2 md:p-3">
            <div className="animate-spin rounded-full h-6 w-6 md:h-8 md:w-8 border-t-2 border-b-2 border-blue-500"></div>
          </div>
        )}
      </div>
    );
  };
  const renderPlanCard = (id, plan) => {
    const isCurrentPlan = subscriptionStatus?.plan_id === id;
    const isActive =
      isCurrentPlan &&
      (subscriptionStatus?.status === "active" ||
        subscriptionStatus?.status === "approved");
    const isFreeTrial = id === "free_trial";
  
    return (
      <div
        key={id}
        className={`bg-white border rounded-xl md:rounded-2xl p-3 md:p-4 lg:p-6 
          relative transition-all duration-300
          hover:border-[#0F31F7] hover:shadow-xl
          ${isActive ? "border-[#0F31F7]" : "border-gray-200"}`}
      >
        {/* Plan Badge */}
        {isActive ? (
          <PlanBadge color="bg-[#0F31F7]">Plan Actual</PlanBadge>
        ) : (
          <>
            {isFreeTrial && <PlanBadge color="bg-green-500">Gratis</PlanBadge>}
            {id === "performance" && (
              <PlanBadge color="bg-[#0F31F7]">96% OFF 1er Pago</PlanBadge>
            )}
          </>
        )}
  
        <div className="flex flex-col h-full">
          {/* Plan Header */}
          <div>
            {id === "free_trial" && (
              <Zap className="w-8 h-8 md:w-9 md:h-9 lg:w-10 lg:h-10 text-green-400 mb-2 md:mb-3 lg:mb-4" />
            )}
            {id === "performance" && (
              <Award className="w-8 h-8 md:w-9 md:h-9 lg:w-10 lg:h-10 text-[#0F31F7] mb-2 md:mb-3 lg:mb-4" />
            )}
            {id === "premium" && (
              <Shield className="w-8 h-8 md:w-9 md:h-9 lg:w-10 lg:h-10 text-blue-400 mb-2 md:mb-3 lg:mb-4" />
            )}
  
            <h3 className="text-lg md:text-xl font-bold text-black mb-1 md:mb-2">{plan.name}</h3>
            <p className="text-xs md:text-sm text-gray-600 mb-3 md:mb-4">{plan.description}</p>
  
            {/* Pricing Display */}
            <div className="mb-3 md:mb-4">
              {id === "performance" ? (
                <div className="space-y-0.5 md:space-y-1">
                  <div className="flex items-baseline">
                    <span className="text-xl md:text-2xl lg:text-3xl font-bold text-black">
                      ${discountedPrice || plan.price}
                    </span>
                    <span className="text-gray-600 ml-1 md:ml-2 text-xs md:text-sm">primer pago</span>
                  </div>
                  <div className="flex items-center gap-1 md:gap-2">
                    <span className="text-xs md:text-sm text-gray-500 line-through">
                      ${originalPrice || plan.regularPrice}
                    </span>
                    <span className="text-xs px-1 py-0.5 md:px-1.5 md:py-0.5 bg-green-100 text-green-800 rounded-md font-medium">
                      ¡{plan.discountPercentage}% OFF!
                    </span>
                  </div>
                  <p className="text-xs text-gray-500 mt-0.5 md:mt-1">
                    Luego ${plan.regularPrice}/mes
                  </p>
                </div>
              ) : (
                <span className="text-xl md:text-2xl lg:text-3xl font-bold text-black">
                  {plan.price === "Contactar"
                    ? "Personalizado"
                    : plan.price === 0
                    ? "Gratis"
                    : `${plan.price}`}
                  <span className="text-gray-600 ml-1 md:ml-2 text-xs md:text-sm lg:text-base">
                    {plan.price !== "Contactar" && plan.price !== 0 && "/mes"}
                  </span>
                </span>
              )}
            </div>
          </div>
  
          {/* Features List */}
          <div className="flex-1 mb-2 md:mb-3">
            <ul className="space-y-1.5 md:space-y-2">
              {plan.features.map((feature, index) => (
                <li
                  key={index}
                  className={`flex items-start gap-1.5 md:gap-2 ${
                    feature.enabled
                      ? "text-black"
                      : "text-gray-400 line-through"
                  }`}
                >
                  {feature.enabled ? (
                    <Check className="w-3.5 h-3.5 md:w-4 md:h-4 mt-0.5 flex-shrink-0 text-green-500" />
                  ) : (
                    <X className="w-3.5 h-3.5 md:w-4 md:h-4 mt-0.5 flex-shrink-0 text-gray-300" />
                  )}
                  <span className="text-xs md:text-sm">{feature.text}</span>
                </li>
              ))}
            </ul>
          </div>
  
          {/* Cupón Input */}
          {id === "performance" || id !== "free_trial" ? (
            <CouponInput
              planId={id}
              onCouponApplied={handleCouponApplied}
              initialCouponCode={id === "performance" ? "FIRST96" : null}
            />
          ) : null}
  
          {/* Action Button */}
          <button
            onClick={() => handleSubscribe(id)}
            disabled={processingSubscription || isActive}
            className={`w-full py-2 md:py-3 rounded-lg text-white font-semibold transition-all mt-4 md:mt-6 text-sm md:text-base
              ${
                isActive
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-[#0F31F7] hover:bg-[#0F31F7]/90"
              }`}
          >
            {processingSubscription
              ? "Procesando..."
              : isActive
              ? "Plan Actual"
              : plan.price === "Contactar"
              ? "Contáctanos"
              : isFreeTrial
              ? "Comenzar Prueba Gratis"
              : id === "performance"
              ? "Comenzar con 96% OFF"
              : "Comenzar"}
          </button>
  
          {/* Display First Payment Banner for Performance Plan */}
          {id === "performance" && !isActive && (
            <div className="mt-3 md:mt-4 -mx-3 md:-mx-4 lg:-mx-6 -mb-3 md:-mb-4 lg:-mb-6 p-2 md:p-3 lg:p-4 bg-blue-50 rounded-b-xl md:rounded-b-2xl border-t border-blue-100">
              <div className="flex items-start">
                <Sparkles className="w-4 h-4 md:w-5 md:h-5 text-blue-500 mr-1.5 md:mr-2 mt-0.5 flex-shrink-0" />
                <p className="text-xs md:text-sm text-blue-700">
                  <span className="font-medium">
                    Oferta: 96% de descuento en tu primer pago.
                  </span>{" "}
                  Paga solo $9.99 vs $250
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  // Mostrador de cargando
  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-[#F2F2F2]">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 md:h-16 md:w-16 border-t-4 border-blue-500 mx-auto mb-3 md:mb-4"></div>
          <p className="text-sm md:text-base text-gray-600">Cargando información...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#F2F2F2] py-3 md:py-4 lg:py-8">
      <div className="max-w-full md:max-w-7xl mx-auto px-3 md:px-4 lg:px-6">
        {/* Header */}
        <div className="flex flex-row justify-between items-center mb-3 md:mb-4 lg:mb-8">
          <div>
            <h1 className="text-xl md:text-2xl lg:text-3xl font-bold text-black mb-0.5 md:mb-1 lg:mb-2">
              Mi Cuenta
            </h1>
            <p className="text-xs md:text-sm lg:text-base text-gray-600">
              Administra tu perfil y suscripción
            </p>
          </div>
          <div className="flex items-center space-x-3 md:space-x-4">
            <button className="text-black-300 hover:text-black transition-colors duration-150">
              <Bell className="w-5 h-5 md:w-6 md:h-6" />
            </button>
            <div className="relative w-7 h-7 md:w-8 md:h-8 lg:w-10 lg:h-10 rounded-full overflow-hidden bg-white border-2 border-gray-200">
              {imagePreview ? (
                <img
                  src={imagePreview}
                  alt="Profile"
                  className="w-full h-full object-cover"
                  onError={(e) => {
                    console.log("Error loading image:", e);
                    e.target.src = "/default-avatar.png";
                    setImagePreview(null);
                  }}
                />
              ) : (
                <div className="w-full h-full flex items-center justify-center text-gray-400">
                  <User className="w-3.5 h-3.5 md:w-4 md:h-4 lg:w-6 lg:h-6" />
                </div>
              )}
            </div>
          </div>
        </div>
  
        {/* Alerts */}
        {error && (
          <div className="bg-red-500 bg-opacity-10 border border-red-500 text-red-500 px-3 py-2 md:px-4 md:py-3 rounded-lg mb-3 md:mb-4 lg:mb-6 text-xs md:text-sm">
            {error}
          </div>
        )}
        {success && (
          <div className="bg-green-500 bg-opacity-10 border border-green-500 text-green-500 px-3 py-2 md:px-4 md:py-3 rounded-lg mb-3 md:mb-4 lg:mb-6 text-xs md:text-sm">
            {success}
          </div>
        )}

        {/* Tabs - Mobile Version */}
        <div className="overflow-x-auto mb-3 md:mb-4">
          <div className="flex space-x-3 min-w-max border-b border-gray-200 pb-1">
            <button
              onClick={() => setActiveTab("profile")}
              className={`pb-1.5 relative flex items-center ${
                activeTab === "profile"
                  ? "text-black border-b-2 border-[#0F31F7]"
                  : "text-gray-500 hover:text-black"
              }`}
            >
              <User className="mr-1 w-3.5 h-3.5 md:w-4 md:h-4" />
              <span className="text-xs md:text-sm">Perfil</span>
            </button>
            <button
              onClick={() => setActiveTab("subscription")}
              className={`pb-1.5 relative flex items-center ${
                activeTab === "subscription"
                  ? "text-black border-b-2 border-[#0F31F7]"
                  : "text-gray-500 hover:text-black"
              }`}
            >
              <CreditCard className="mr-1 w-3.5 h-3.5 md:w-4 md:h-4" />
              <span className="text-xs md:text-sm">Suscripción</span>
            </button>
            <button
              onClick={() => setActiveTab("billing")}
              className={`pb-1.5 relative flex items-center ${
                activeTab === "billing"
                  ? "text-black border-b-2 border-[#0F31F7]"
                  : "text-gray-500 hover:text-black"
              }`}
            >
              <Receipt className="mr-1 w-3.5 h-3.5 md:w-4 md:h-4" />
              <span className="text-xs md:text-sm">Facturación</span>
            </button>
            <button
              onClick={() => setActiveTab("payments")}
              className={`pb-1.5 relative flex items-center ${
                activeTab === "payments"
                  ? "text-black border-b-2 border-[#0F31F7]"
                  : "text-gray-500 hover:text-black"
              }`}
            >
              <History className="mr-1 w-3.5 h-3.5 md:w-4 md:h-4" />
              <span className="text-xs md:text-sm">Pagos</span>
            </button>
          </div>
        </div>

        {/* Content Section */}
        <div className="w-full">
          {/* Profile Content */}
          {activeTab === "profile" && (
            <div className="bg-white border border-gray-200 rounded-lg p-3 md:p-4 lg:p-6">
         
              
              {/* Profile Form */}
              <form
                onSubmit={handleProfileUpdate}
                className="space-y-3 md:space-y-4 lg:space-y-6"
              >
                {/* Personal Information */}
                <div>
                  <h3 className="text-base md:text-lg font-semibold mb-2 md:mb-3 lg:mb-4 text-black">
                    Información Personal
                  </h3>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-3 lg:gap-4">
                    <div>
                      <label className="block text-gray-700 mb-1 md:mb-2 text-xs md:text-sm lg:text-base">
                        Nombre
                      </label>
                      <input
                        type="text"
                        className="w-full px-2 py-1.5 md:px-3 md:py-2 bg-white text-black border border-gray-200 rounded-lg 
                        focus:outline-none focus:border-[#0F31F7] text-xs md:text-sm lg:text-base"
                        value={formData.first_name}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            first_name: e.target.value,
                          }))
                        }
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-gray-700 mb-1 md:mb-2 text-xs md:text-sm lg:text-base">
                        Apellido
                      </label>
                      <input
                        type="text"
                        className="w-full px-2 py-1.5 md:px-3 md:py-2 bg-white text-black border border-gray-200 rounded-lg 
                        focus:outline-none focus:border-[#0F31F7] text-xs md:text-sm lg:text-base"
                        value={formData.last_name}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            last_name: e.target.value,
                          }))
                        }
                        required
                      />
                    </div>
                  </div>
                </div>

                {/* Business Information */}
                <div>
                  <h3 className="text-base md:text-lg font-semibold mb-2 md:mb-3 lg:mb-4 text-black">
                    Información del Negocio
                  </h3>
                  <div className="space-y-2 md:space-y-3 lg:space-y-4">
                    <div>
                      <label className="block text-gray-700 mb-1 md:mb-2 text-xs md:text-sm lg:text-base">
                        Nombre del Negocio
                      </label>
                      <input
                        type="text"
                        className="w-full px-2 py-1.5 md:px-3 md:py-2 bg-white text-black border border-gray-200 rounded-lg 
                        focus:outline-none focus:border-[#0F31F7] text-xs md:text-sm lg:text-base"
                        value={formData.business_name}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            business_name: e.target.value,
                          }))
                        }
                      />
                    </div>
                    <div>
                      <label className="block text-gray-700 mb-1 md:mb-2 text-xs md:text-sm lg:text-base">
                        URL del Sitio Web
                      </label>
                      <input
                        type="url"
                        className="w-full px-2 py-1.5 md:px-3 md:py-2 bg-white text-black border border-gray-200 rounded-lg 
                        focus:outline-none focus:border-[#0F31F7] text-xs md:text-sm lg:text-base"
                        value={formData.website_url}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            website_url: e.target.value,
                          }))
                        }
                      />
                    </div>
                    <div>
                      <label className="block text-gray-700 mb-1 md:mb-2 text-xs md:text-sm lg:text-base">
                        Detalles del Negocio
                      </label>
                      <textarea
                        className="w-full px-2 py-1.5 md:px-3 md:py-2 bg-white text-black border border-gray-200 rounded-lg 
                        focus:outline-none focus:border-[#0F31F7] min-h-[60px] md:min-h-[80px] lg:min-h-[100px] text-xs md:text-sm lg:text-base"
                        value={formData.business_details}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            business_details: e.target.value,
                          }))
                        }
                      ></textarea>
                    </div>
                  </div>
                </div>

                {/* Additional Information */}
                <div>
                  <h3 className="text-base md:text-lg font-semibold mb-2 md:mb-3 lg:mb-4 text-black">
                    Información Adicional
                  </h3>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-3 lg:gap-4">
                    <div>
                      <label className="block text-gray-700 mb-1 md:mb-2 text-xs md:text-sm lg:text-base">
                        Industria
                      </label>
                      <select
                        className="w-full px-2 py-1.5 md:px-3 md:py-2 bg-white text-black border border-gray-200 rounded-lg 
                        focus:outline-none focus:border-[#0F31F7] text-xs md:text-sm lg:text-base"
                        value={formData.industry}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            industry: e.target.value,
                          }))
                        }
                      >
                        <option value="">Seleccionar...</option>
                        <option value="ecommerce">E-commerce</option>
                        <option value="saas">SaaS</option>
                        <option value="retail">Retail</option>
                        <option value="services">Servicios</option>
                        <option value="other">Otro</option>
                      </select>
                    </div>
                    <div>
                      <label className="block text-gray-700 mb-1 md:mb-2 text-xs md:text-sm lg:text-base">
                        Tamaño de la Empresa
                      </label>
                      <select
                        className="w-full px-2 py-1.5 md:px-3 md:py-2 bg-white text-black border border-gray-200 rounded-lg 
                        focus:outline-none focus:border-[#0F31F7] text-xs md:text-sm lg:text-base"
                        value={formData.company_size}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            company_size: e.target.value,
                          }))
                        }
                      >
                        <option value="">Seleccionar...</option>
                        <option value="1-10">1-10 empleados</option>
                        <option value="11-50">11-50 empleados</option>
                        <option value="51-200">51-200 empleados</option>
                        <option value="201-500">201-500 empleados</option>
                        <option value="500+">Más de 500 empleados</option>
                      </select>
                    </div>
                    <div>
                      <label className="block text-gray-700 mb-1 md:mb-2 text-xs md:text-sm lg:text-base">
                        Experiencia en Marketing Digital
                      </label>
                      <select
                        className="w-full px-2 py-1.5 md:px-3 md:py-2 bg-white text-black border border-gray-200 rounded-lg 
                        focus:outline-none focus:border-[#0F31F7] text-xs md:text-sm lg:text-base"
                        value={formData.digital_experience}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            digital_experience: e.target.value,
                          }))
                        }
                      >
                        <option value="">Seleccionar...</option>
                        <option value="beginner">Principiante</option>
                        <option value="intermediate">Intermedio</option>
                        <option value="advanced">Avanzado</option>
                      </select>
                    </div>
                    <div>
                      <label className="block text-gray-700 mb-1 md:mb-2 text-xs md:text-sm lg:text-base">
                        Presupuesto Mensual (USD)
                      </label>
                      <input
                        type="number"
                        className="w-full px-2 py-1.5 md:px-3 md:py-2 bg-white text-black border border-gray-200 rounded-lg 
                        focus:outline-none focus:border-[#0F31F7] text-xs md:text-sm lg:text-base"
                        value={formData.monthly_budget}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            monthly_budget: e.target.value,
                          }))
                        }
                        placeholder="0"
                      />
                    </div>
                  </div>
                </div>

                {/* Submit Button */}
                <div className="flex justify-end pt-2 md:pt-4 lg:pt-6">
                  <button
                    type="submit"
                    className="px-3 py-1.5 md:px-4 md:py-2 lg:px-6 lg:py-2 bg-[#0F31F7] text-white rounded-lg hover:bg-blue-700 
                    transition-colors duration-150 flex items-center text-xs md:text-sm lg:text-base"
                  >
                    <Save className="mr-1.5 w-3.5 h-3.5 md:mr-2 md:w-4 md:h-4 lg:w-5 lg:h-5" />
                    Guardar Cambios
                  </button>
                </div>
              </form>
            </div>
          )}

         {/* Subscription Content */}
{activeTab === "subscription" && (
  <div className="bg-white rounded-lg md:rounded-xl lg:rounded-2xl p-0 md:p-0 lg:p-0 overflow-y-auto">
    <div className="mx-auto">
      <div className="bg-[#0F31F7] z-10 rounded-t-lg md:rounded-t-xl lg:rounded-t-2xl px-3 py-3 md:px-4 md:py-4 lg:px-6 lg:py-5 text-center">
        <h2 className="text-lg md:text-xl lg:text-3xl font-bold text-white mb-1 md:mb-2">
          Elige tu plan de Espor.ai
        </h2>
        <p className="text-xs md:text-sm lg:text-base text-blue-100 max-w-xl mx-auto">
          Desbloquea funcionalidades avanzadas de IA para optimizar
          tus campañas publicitarias.
        </p>
      </div>

      <div className="p-3 md:p-4 lg:p-8">
        {/* Current Subscription Status */}
        {subscriptionStatus && (
          <div className="bg-gray-100 rounded-xl md:rounded-2xl p-3 md:p-4 lg:p-6 mb-3 md:mb-4 lg:mb-8 border border-gray-200 z-10">
            <h3 className="text-base md:text-lg lg:text-xl font-semibold text-black mb-2 md:mb-3 lg:mb-4">
              Plan Actual
            </h3>
            <div className="space-y-2 md:space-y-3 lg:space-y-4">
              <div className="flex items-center justify-between border-b border-gray-200 pb-1.5 md:pb-2 lg:pb-3">
                <span className="text-xs md:text-sm lg:text-base text-gray-600">
                  Plan:
                </span>
                <span className="text-xs md:text-sm lg:text-base font-medium text-black">
                  {SUBSCRIPTION_PLANS[subscriptionStatus.plan_id]
                    ?.name || `Plan ${subscriptionStatus.plan_id}`}
                </span>
              </div>
              <div className="flex items-center justify-between border-b border-gray-200 pb-1.5 md:pb-2 lg:pb-3">
                <span className="text-xs md:text-sm lg:text-base text-gray-600">
                  Estado:
                </span>
                <span
                  className={`px-1.5 py-0.5 md:px-2 md:py-1 lg:px-3 lg:py-1 rounded-full text-[10px] md:text-xs lg:text-sm ${
                    subscriptionStatus.status === "active" ||
                    subscriptionStatus.status === "approved"
                      ? "bg-green-500 text-white"
                      : subscriptionStatus.status === "cancelled"
                      ? "bg-red-500 text-white"
                      : "bg-yellow-500 text-white"
                  }`}
                >
                  {subscriptionStatus.status === "active" ||
                  subscriptionStatus.status === "approved"
                    ? "Activo"
                    : subscriptionStatus.status === "cancelled"
                    ? "Cancelado"
                    : "Pendiente"}
                </span>
              </div>
              {subscriptionStatus.next_payment_date && (
                <div className="flex items-center justify-between border-b border-gray-200 pb-1.5 md:pb-2 lg:pb-3">
                  <span className="text-xs md:text-sm lg:text-base text-gray-600">
                    Próximo pago:
                  </span>
                  <span className="text-xs md:text-sm lg:text-base font-medium text-black">
                    {new Date(
                      subscriptionStatus.next_payment_date
                    ).toLocaleDateString()}
                  </span>
                </div>
              )}
              {subscriptionStatus.status === "active" && (
                <div className="flex justify-center mt-2 md:mt-3 lg:mt-4">
                  <button
                    onClick={handleCancelSubscription}
                    disabled={processingSubscription}
                    className="px-3 py-1.5 md:px-4 md:py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 
                    transition-colors duration-150 flex items-center text-xs md:text-sm lg:text-base"
                  >
                    {processingSubscription
                      ? "Procesando..."
                      : "Cancelar Suscripción"}
                  </button>
                </div>
              )}
            </div>
          </div>
        )}

        {!subscriptionStatus && (
          <div
            className="bg-gray-100 border border-gray-200 text-gray-700 
            px-3 py-2 md:px-4 md:py-3 rounded-lg mb-3 md:mb-4 lg:mb-8 text-center text-xs md:text-sm lg:text-base"
          >
            No tienes una suscripción activa. Selecciona un plan para
            comenzar.
          </div>
        )}

        {/* Plans Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-5 lg:gap-6 w-full">
          {Object.entries(SUBSCRIPTION_PLANS).map(([id, plan]) =>
            renderPlanCard(id, plan)
          )}
        </div>
      </div>
    </div>
  </div>
)}

          {/* Billing Information Content */}
          {activeTab === "billing" && (
            <div className="bg-white border border-gray-200 rounded-lg p-3 md:p-4 lg:p-6">
              {billingLoading ? (
                <div className="flex justify-center items-center py-8 md:py-10 lg:py-12">
                  <div className="animate-spin rounded-full h-8 w-8 md:h-10 md:w-10 lg:h-12 lg:w-12 border-t-4 border-blue-500 mx-auto"></div>
                  <span className="ml-2 md:ml-3 text-xs md:text-sm lg:text-base text-gray-600">
                    Cargando información de facturación...
                  </span>
                </div>
              ) : (
                <form
                  onSubmit={handleBillingUpdate}
                  className="space-y-3 md:space-y-4 lg:space-y-6"
                >
                  <div>
                    <h3 className="text-base md:text-lg font-semibold mb-2 md:mb-3 lg:mb-4 text-black">
                      Información de Facturación
                    </h3>

                    {/* Billing Information */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-3 lg:gap-4 mb-2 md:mb-3 lg:mb-4">
                      <div>
                        <label className="block text-gray-700 mb-1 md:mb-2 text-xs md:text-sm lg:text-base">
                          Nombre de la Compañía *
                        </label>
                        <input
                          type="text"
                          name="company_name"
                          className="w-full px-2 py-1.5 md:px-3 md:py-2 bg-white text-black border border-gray-200 rounded-lg 
                          focus:outline-none focus:border-[#0F31F7] text-xs md:text-sm lg:text-base"
                          value={formData.billing.company_name}
                          onChange={handleBillingInputChange}
                          required
                        />
                      </div>

                      <div>
                        <label className="block text-gray-700 mb-1 md:mb-2 text-xs md:text-sm lg:text-base">
                          RUT/NIF *
                        </label>
                        <input
                          type="text"
                          name="tax_id"
                          className="w-full px-2 py-1.5 md:px-3 md:py-2 bg-white text-black border border-gray-200 rounded-lg 
                          focus:outline-none focus:border-[#0F31F7] text-xs md:text-sm lg:text-base"
                          value={formData.billing.tax_id}
                          onChange={handleBillingInputChange}
                          required
                          placeholder="Ej: 12.345.678-9"
                        />
                      </div>
                    </div>

                    <div className="mb-2 md:mb-3 lg:mb-4">
                      <label className="block text-gray-700 mb-1 md:mb-2 text-xs md:text-sm lg:text-base">
                        Email de Facturación *
                      </label>
                      <input
                        type="email"
                        name="billing_email"
                        className="w-full px-2 py-1.5 md:px-3 md:py-2 bg-white text-black border border-gray-200 rounded-lg 
                        focus:outline-none focus:border-[#0F31F7] text-xs md:text-sm lg:text-base"
                        value={formData.billing.billing_email}
                        onChange={handleBillingInputChange}
                        required
                      />
                    </div>

                    {/* Address Information */}
                    <h4 className="text-sm md:text-base lg:text-md font-semibold mt-3 md:mt-4 lg:mt-6 mb-2 md:mb-3 lg:mb-4 text-black">
                      Dirección
                    </h4>

                    <div className="space-y-2 md:space-y-3 lg:space-y-4">
                      <div>
                        <label className="block text-gray-700 mb-1 md:mb-2 text-xs md:text-sm lg:text-base">
                          Dirección Línea 1 *
                        </label>
                        <input
                          type="text"
                          name="address_line1"
                          className="w-full px-2 py-1.5 md:px-3 md:py-2 bg-white text-black border border-gray-200 rounded-lg 
                          focus:outline-none focus:border-[#0F31F7] text-xs md:text-sm lg:text-base"
                          value={formData.billing.address_line1}
                          onChange={handleBillingInputChange}
                          required
                          placeholder="Calle y número"
                        />
                      </div>

                      <div>
                        <label className="block text-gray-700 mb-1 md:mb-2 text-xs md:text-sm lg:text-base">
                          Dirección Línea 2
                        </label>
                        <input
                          type="text"
                          name="address_line2"
                          className="w-full px-2 py-1.5 md:px-3 md:py-2 bg-white text-black border border-gray-200 rounded-lg 
                          focus:outline-none focus:border-[#0F31F7] text-xs md:text-sm lg:text-base"
                          value={formData.billing.address_line2}
                          onChange={handleBillingInputChange}
                          placeholder="Apartamento, oficina, etc. (opcional)"
                        />
                      </div>

                      <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-3 lg:gap-4">
                        <div>
                          <label className="block text-gray-700 mb-1 md:mb-2 text-xs md:text-sm lg:text-base">
                            Ciudad *
                          </label>
                          <input
                            type="text"
                            name="city"
                            className="w-full px-2 py-1.5 md:px-3 md:py-2 bg-white text-black border border-gray-200 rounded-lg 
                            focus:outline-none focus:border-[#0F31F7] text-xs md:text-sm lg:text-base"
                            value={formData.billing.city}
                            onChange={handleBillingInputChange}
                            required
                          />
                        </div>

                        <div>
                          <label className="block text-gray-700 mb-1 md:mb-2 text-xs md:text-sm lg:text-base">
                            Provincia/Estado *
                          </label>
                          <input
                            type="text"
                            name="state"
                            className="w-full px-2 py-1.5 md:px-3 md:py-2 bg-white text-black border border-gray-200 rounded-lg 
                            focus:outline-none focus:border-[#0F31F7] text-xs md:text-sm lg:text-base"
                            value={formData.billing.state}
                            onChange={handleBillingInputChange}
                            required
                          />
                        </div>
                      </div>

                      <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-3 lg:gap-4">
                        <div>
                          <label className="block text-gray-700 mb-1 md:mb-2 text-xs md:text-sm lg:text-base">
                            Código Postal *
                          </label>
                          <input
                            type="text"
                            name="postal_code"
                            className="w-full px-2 py-1.5 md:px-3 md:py-2 bg-white text-black border border-gray-200 rounded-lg 
                            focus:outline-none focus:border-[#0F31F7] text-xs md:text-sm lg:text-base"
                            value={formData.billing.postal_code}
                            onChange={handleBillingInputChange}
                            required
                          />
                        </div>

                        <div>
                          <label className="block text-gray-700 mb-1 md:mb-2 text-xs md:text-sm lg:text-base">
                            País *
                          </label>
                          <select
                            name="country"
                            className="w-full px-2 py-1.5 md:px-3 md:py-2 bg-white text-black border border-gray-200 rounded-lg 
                            focus:outline-none focus:border-[#0F31F7] text-xs md:text-sm lg:text-base"
                            value={formData.billing.country}
                            onChange={handleBillingInputChange}
                            required
                          >
                            <option value="">Seleccionar país...</option>
                            <option value="AR">Argentina</option>
                            <option value="BO">Bolivia</option>
                            <option value="BR">Brasil</option>
                            <option value="CL">Chile</option>
                            <option value="CO">Colombia</option>
                            <option value="CR">Costa Rica</option>
                            <option value="EC">Ecuador</option>
                            <option value="SV">El Salvador</option>
                            <option value="GT">Guatemala</option>
                            <option value="HN">Honduras</option>
                            <option value="MX">México</option>
                            <option value="NI">Nicaragua</option>
                            <option value="PA">Panamá</option>
                            <option value="PY">Paraguay</option>
                            <option value="PE">Perú</option>
                            <option value="DO">República Dominicana</option>
                            <option value="UY">Uruguay</option>
                            <option value="VE">Venezuela</option>
                            <option value="ES">España</option>
                            <option value="US">Estados Unidos</option>
                            <option value="OT">Otro</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Submit Button */}
                  <div className="flex justify-end pt-2 md:pt-4 lg:pt-6">
                    <button
                      type="submit"
                      className="px-3 py-1.5 md:px-4 md:py-2 lg:px-6 lg:py-2 bg-[#0F31F7] text-white rounded-lg hover:bg-blue-700 
                      transition-colors duration-150 flex items-center text-xs md:text-sm lg:text-base"
                    >
                      <Save className="mr-1.5 w-3.5 h-3.5 md:mr-2 md:w-4 md:h-4 lg:w-5 lg:h-5" />
                      Guardar Información
                    </button>
                  </div>
                </form>
              )}
            </div>
          )}

          {/* Payment History Content */}
          {activeTab === "payments" && (
            <div className="bg-white border border-gray-200 rounded-lg p-3 md:p-4 lg:p-6">
              <div className="mb-3 md:mb-4 lg:mb-6">
                <h3 className="text-base md:text-lg lg:text-xl font-semibold text-black mb-1 md:mb-2">
                  Historial de Pagos
                </h3>
                <div className="flex flex-col md:flex-row md:items-center md:justify-between">
                  <p className="text-xs md:text-sm lg:text-base text-gray-600 mb-2 md:mb-0">
                    Visualiza todos tus pagos realizados y su estado
                  </p>
                  {paymentHistory.length > 0 && (
                    <button
                      onClick={handleExportPaymentHistory}
                      className="px-2 py-1.5 md:px-3 md:py-2 lg:px-4 lg:py-2 bg-white text-[#0F31F7] border border-[#0F31F7] rounded-lg hover:bg-[#0F31F7] hover:text-white transition-colors duration-150 flex items-center text-xs md:text-sm w-full md:w-auto justify-center md:justify-start"
                    >
                      <Download className="w-3 h-3 md:w-3.5 md:h-3.5 lg:w-4 lg:h-4 mr-1 md:mr-1.5 lg:mr-2" />
                      Exportar Excel
                    </button>
                  )}
                </div>
              </div>

              {loadingPaymentHistory ? (
                <div className="flex justify-center py-4 md:py-6 lg:py-8">
                  <div className="animate-spin rounded-full h-6 w-6 md:h-8 md:w-8 lg:h-10 lg:w-10 border-t-2 border-b-2 border-blue-500"></div>
                </div>
              ) : paymentHistory.length === 0 ? (
                <div className="bg-gray-100 rounded-lg p-3 md:p-4 lg:p-6 text-center">
                  <AlertTriangle className="w-6 h-6 md:w-8 md:h-8 lg:w-10 lg:h-10 text-gray-400 mx-auto mb-1 md:mb-2" />
                  <p className="text-xs md:text-sm lg:text-base text-gray-600">
                    No hay registros de pagos realizados.
                  </p>
                </div>
              ) : (
                <div className="overflow-x-auto -mx-3 md:-mx-2 lg:mx-0">
                  <table className="w-full table-auto divide-y divide-gray-200 min-w-[640px]">
                    <thead className="bg-gray-100">
                      <tr>
                        <th className="px-2 md:px-3 lg:px-6 py-1.5 md:py-2 lg:py-3 text-left text-[10px] md:text-xs lg:text-sm font-medium text-gray-500 uppercase tracking-wider">
                          Fecha
                        </th>
                        <th className="px-2 md:px-3 lg:px-6 py-1.5 md:py-2 lg:py-3 text-left text-[10px] md:text-xs lg:text-sm font-medium text-gray-500 uppercase tracking-wider">
                          Descripción
                        </th>
                        <th className="px-2 md:px-3 lg:px-6 py-1.5 md:py-2 lg:py-3 text-left text-[10px] md:text-xs lg:text-sm font-medium text-gray-500 uppercase tracking-wider">
                          Monto
                        </th>
                        <th className="px-2 md:px-3 lg:px-6 py-1.5 md:py-2 lg:py-3 text-left text-[10px] md:text-xs lg:text-sm font-medium text-gray-500 uppercase tracking-wider">
                          Estado
                        </th>
                        <th className="px-2 md:px-3 lg:px-6 py-1.5 md:py-2 lg:py-3 text-right text-[10px] md:text-xs lg:text-sm font-medium text-gray-500 uppercase tracking-wider">
                          Método
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200 text-[10px] md:text-xs lg:text-sm">
                      {paymentHistory.map((payment) => (
                        <tr key={payment.id} className="hover:bg-gray-50">
                          <td className="px-2 md:px-3 lg:px-6 py-1.5 md:py-2 lg:py-4 whitespace-nowrap">
                            {new Date(payment.date).toLocaleDateString("es-ES")}
                          </td>
                          <td className="px-2 md:px-3 lg:px-6 py-1.5 md:py-2 lg:py-4 whitespace-nowrap">
                            {payment.description}
                          </td>
                          <td className="px-2 md:px-3 lg:px-6 py-1.5 md:py-2 lg:py-4 whitespace-nowrap">
                            {payment.amount > 0 ? (
                              `${parseFloat(payment.amount).toFixed(2)} USD`
                            ) : (
                              <span className="text-green-600 font-medium">
                                Gratis
                              </span>
                            )}
                          </td>
                          <td className="px-2 md:px-3 lg:px-6 py-1.5 md:py-2 lg:py-4 whitespace-nowrap">
                            <span
                              className={`px-1 py-0.5 md:px-2 md:py-1 inline-flex text-[10px] md:text-xs leading-5 font-semibold rounded-full 
                              ${
                                payment.status === "approved" ||
                                payment.status === "active"
                                  ? "bg-green-100 text-green-800"
                                  : payment.status === "pending"
                                  ? "bg-yellow-100 text-yellow-800"
                                  : payment.status === "cancelled"
                                  ? "bg-red-100 text-red-800"
                                  : "bg-gray-100 text-gray-800"
                              }`}
                            >
                              {payment.status === "approved" ||
                              payment.status === "active"
                                ? "Aprobado"
                                : payment.status === "pending"
                                ? "Pendiente"
                                : payment.status === "cancelled"
                                ? "Cancelado"
                                : payment.status}
                              {payment.status === "pending" && (
                                <Clock className="inline-block w-2.5 h-2.5 md:w-3 md:h-3 ml-0.5 md:ml-1" />
                              )}
                            </span>
                          </td>
                          <td className="px-2 md:px-3 lg:px-6 py-1.5 md:py-2 lg:py-4 whitespace-nowrap text-right">
                            {payment.payment_method === "free" ? (
                              <span className="text-gray-500">Sin pago</span>
                            ) : payment.payment_method === "mercadopago" ? (
                              <span className="text-blue-600">MercadoPago</span>
                            ) : (
                              <span className="text-gray-600">
                                {payment.payment_method}
                              </span>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;