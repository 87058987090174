import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../components/context/AuthContext';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const FacebookCallback = () => {
  const navigate = useNavigate();
  const { login, updateUser, fetchMetaData } = useAuth();
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(true);
  const [status, setStatus] = useState('Iniciando verificación...');

  useEffect(() => {
    const handleFacebookCallback = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const code = params.get('code');
        const state = params.get('state');
        const errorParam = params.get('error');
        
        // IMPORTANTE: Obtener el token existente
        const existingToken = params.get('token') || localStorage.getItem('current_user_token');
   
        // Verificar estado CSRF
        const savedState = localStorage.getItem('facebook_auth_state');
        localStorage.removeItem('facebook_auth_state');
   
        if (state !== savedState) {
          throw new Error('Estado inválido - posible ataque CSRF');
        }
   
        if (errorParam) {
          throw new Error(params.get('error_description') || 'Error de autorización');
        }
   
        if (!code) {
          throw new Error('No se recibió código de autorización');
        }
   
        setStatus('Verificando autorización...');
        
        // Registrar que estamos usando autenticación de Facebook
        sessionStorage.setItem('auth_method', existingToken ? 'existing' : 'facebook');
        sessionStorage.setItem('auth_state', 'connecting');
   
        // IMPORTANTE: Enviar el token existente al backend
        console.log('Sending verification request with code and existing token');
        const response = await axios.post(`${API_URL}/facebook/verify`, {
          code,
          redirect_uri: `${window.location.origin}/facebook-callback`,
          existing_token: existingToken // Añadir el token existente
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
   
        if (!response.data.access_token) {
          throw new Error('No se recibió token de acceso');
        }
   
        setStatus('Obteniendo información de la cuenta...');
        console.log('Facebook verify response:', response.data);
   
        // IMPORTANTE: Si hay un token existente, solo actualizar campos específicos
        if (existingToken) {
          console.log('Using existing token, updating specific fields');
          // Actualizar solo los campos de Meta manteniendo el resto de la información
          await updateUser(prevUser => ({
            ...prevUser, // Mantener todos los datos previos
            meta_connected: true,
            meta_id: response.data.user?.meta_id,
            meta_access_token: response.data.user?.meta_access_token,
            meta_ad_account_id: response.data.user?.meta_ad_account_id
          }));
          
          // IMPORTANTE: Mantener el token existente
          await login(existingToken, response.data.user, 'existing');
        } else {
          console.log('New login flow, using full user data');
          // Para usuarios nuevos, comportamiento normal
          if (response.data.user) {
            await updateUser({
              ...response.data.user,
              meta_connected: true,
              meta_ad_account_id: response.data.user.meta_ad_account_id
            });
          }
          await login(response.data.access_token, response.data.user, 'facebook');
        }
        
        // Limpiar cualquier token almacenado
        localStorage.removeItem('current_user_token');
        
        // Actualizar estado de autenticación
        sessionStorage.setItem('auth_state', 'connected');
        
        // NUEVO: Obtener datos de Meta inmediatamente después de la conexión
        setStatus('Cargando datos de cuenta de Meta...');
        try {
          console.log('Fetching Meta accounts data');
          const metaData = await fetchMetaData();
          console.log('Meta accounts data loaded:', metaData);
          
          // Opcional: actualizar el estado global con los datos de cuenta
          if (metaData && metaData.length > 0) {
            console.log('Updating user with Meta accounts data');
            await updateUser(prevUser => ({
              ...prevUser,
              meta_accounts: metaData
            }));
          }
        } catch (metaError) {
          console.error('Error loading Meta account data:', metaError);
          // Continuar de todos modos, no bloqueamos por esto
        }
        
        setStatus('Conexión exitosa. Redirigiendo...');
        
        // Redireccionar con parámetros de éxito
        navigate('/accounts', { 
          state: { 
            metaConnected: true,
            message: 'Cuenta de Meta Ads conectada exitosamente'
          }
        });
   
      } catch (err) {
        console.error('Facebook callback error:', err);
        // Limpiar datos de autenticación en caso de error
        sessionStorage.removeItem('auth_method');
        sessionStorage.removeItem('auth_state');
        
        // IMPORTANTE: No borrar el token actual en caso de error de conexión de Meta
        // Esto permitirá que el usuario mantenga su sesión aunque falle la conexión
        // localStorage.removeItem('current_user_token');
        
        setError(err.response?.data?.error || err.message || 'Error de autenticación');
      } finally {
        setIsProcessing(false);
      }
    };
   
    handleFacebookCallback();
  }, [navigate, login, updateUser, fetchMetaData]);

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center p-4 bg-gray-50">
        <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
          <div className="flex items-center justify-center text-red-600 mb-4">
            <svg
              className="w-12 h-12"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
          </div>
          <h2 className="text-xl font-semibold text-center mb-4">
            Error de conexión
          </h2>
          <p className="text-gray-600 text-center mb-6">{error}</p>
          <div className="flex justify-center">
            <button
              onClick={() => navigate('/accounts')}
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
            >
              Volver a intentar
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50">
      <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
        <div className="flex flex-col items-center">
          <div className="mb-4">
            {isProcessing && (
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600" />
            )}
          </div>
          <h2 className="text-xl font-semibold mb-4">
            Conectando con Meta Ads
          </h2>
          <p className="text-gray-600 text-center">{status}</p>
        </div>
      </div>
    </div>
  );
};

export default FacebookCallback;