import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import AICampaignCreation from '../../components/AiCampaignCreation';
import OnboardingModal from '../../components/OnboardingModal';
import { useAuth } from '../../components/context/AuthContext';
import { Loader, XCircle, CheckCircle } from 'lucide-react';

const API_URL = process.env.REACT_APP_API_URL;
const POPUP_REMIND_HOURS = 6; // Número de horas para volver a mostrar el popup

const Dashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { auth, updateUser, logout } = useAuth();

  // UI States
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingGoogle, setIsLoadingGoogle] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isNewCampaignModalOpen, setIsNewCampaignModalOpen] = useState(false);

  // Campaign States
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  // Google Account States
  const [selectedCustomerId, setSelectedCustomerId] = useState(auth.user?.google_customer_id);
  const [managerCustomerId, setManagerCustomerId] = useState(auth.user?.google_manager_id);
  
  // Modal demorado
  const [modalTimeout, setModalTimeout] = useState(null);

  // Fetch Campaigns
  const fetchCampaigns = useCallback(async () => {
    try {
      const response = await axios.get(`${API_URL}/campaigns`, {
        headers: { Authorization: `Bearer ${auth.token}` }
      });
      setCampaigns(response.data.campaigns || []);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
      setError('Error al cargar las campañas');
      setTimeout(() => setError(''), 3000);
    }
  }, [auth.token]);

  // Handle Google Auth
  useEffect(() => {
    const handleGoogleAuth = async () => {
      const params = new URLSearchParams(location.search);
      const code = params.get('code');
      const state = params.get('state');
      
      if (code && state) {
        const savedState = localStorage.getItem('google_auth_state');
        if (state === savedState) {
          setIsLoadingGoogle(true);
          try {
            const response = await axios.post(
              `${API_URL}/google/callback`,
              { code },
              {
                headers: {
                  'Authorization': `Bearer ${auth.token}`
                }
              }
            );

            if (response.data?.google_customer_id) {
              const updatedUserData = {
                ...auth.user,
                google_connected: true,
                google_customer_id: response.data.google_customer_id,
                google_manager_id: response.data.google_manager_id
              };
              await updateUser(updatedUserData);

              setSelectedCustomerId(response.data.google_customer_id);
              setManagerCustomerId(response.data.google_manager_id);
              setSuccess('Conexión con Google Ads exitosa');
              setTimeout(() => setSuccess(''), 3000);
              
              // Limpiar el registro de la sesión para el popup
              sessionStorage.removeItem('welcomeModalShown');
              localStorage.removeItem('welcomeModalLastClosed');
            }
          } catch (error) {
            console.error('Error en autenticación de Google:', error);
            setError(error.response?.data?.error || 'Error al conectar con Google Ads');
            setTimeout(() => setError(''), 3000);
          } finally {
            setIsLoadingGoogle(false);
            window.history.replaceState({}, document.title, window.location.pathname);
            localStorage.removeItem('google_auth_state');
          }
        }
      }
    };

    handleGoogleAuth();
  }, [location.search, auth.token, auth.user, updateUser]);

  // Check Onboarding Status and Show Welcome Modal if needed
  useEffect(() => {
    const checkOnboardingStatus = async () => {
      if (auth.token) {
        try {
          const response = await axios.get(
            `${API_URL}/user/onboarding-status`,
            {
              headers: {
                'Authorization': `Bearer ${auth.token}`
              }
            }
          );
          
          if (response.data) {
            const onboardingCompleted = response.data.onboarding_completed;
            const userData = response.data.user_data || {};
            
            setShowOnboarding(!onboardingCompleted);
            
            // Actualizar usuario si hay datos
            if (userData) {
              updateUser(userData);
            }
            
            // Solo mostrar el modal si:
            // 1. El onboarding está completo
            // 2. No tiene cuentas conectadas
            // 3. No se ha mostrado en esta sesión
            // 4. Si fue cerrado hace más de 6 horas (o nunca)
            if (onboardingCompleted && 
                !userData.google_connected && 
                !userData.meta_ad_account_id) {
              
              // Verificar si ya se mostró en esta sesión
              const shownInSession = sessionStorage.getItem('welcomeModalShown') === 'true';
              
              // Verificar si se cerró recientemente
              const lastClosedStr = localStorage.getItem('welcomeModalLastClosed');
              let shouldShowBasedOnTime = true;
              
              if (lastClosedStr) {
                const lastClosed = new Date(lastClosedStr);
                const now = new Date();
                const hoursDiff = (now - lastClosed) / (1000 * 60 * 60);
                shouldShowBasedOnTime = hoursDiff >= POPUP_REMIND_HOURS;
              }
              
              if (!shownInSession && shouldShowBasedOnTime) {
                // Mostrar el modal con un pequeño delay para mejor UX
                const timer = setTimeout(() => {
                  setShowWelcomeModal(true);
                  // Marcar como mostrado en esta sesión
                  sessionStorage.setItem('welcomeModalShown', 'true');
                }, 1000);
                
                setModalTimeout(timer);
              }
            }
          }
        } catch (error) {
          console.error('Error checking onboarding status:', error);
          setError('Error al verificar el estado de onboarding');
        }
      }
      setIsLoading(false);
    };

    checkOnboardingStatus();
    
    // Limpieza del timeout al desmontar
    return () => {
      if (modalTimeout) {
        clearTimeout(modalTimeout);
      }
    };
  }, [auth.token, updateUser]);

  // Load Initial Campaigns
  useEffect(() => {
    fetchCampaigns();
  }, [fetchCampaigns]);

  // Handle Onboarding Complete
  const handleOnboardingComplete = async (formData) => {
    try {
      if (!formData) {
        throw new Error('No se recibieron datos del formulario');
      }

      const response = await axios.post(
        `${API_URL}/api/user/onboarding`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${auth.token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data) {
        setShowOnboarding(false);
        const updatedUserData = {
          ...response.data,
          onboarding_completed: true
        };
        await updateUser(updatedUserData);
        setSuccess('¡Configuración inicial completada!');
        setTimeout(() => setSuccess(''), 3000);
        
        // Si completó onboarding pero no tiene cuentas, mostrar el modal de bienvenida
        if (!updatedUserData.google_connected && !updatedUserData.meta_ad_account_id) {
          setShowWelcomeModal(true);
          // Marcar como mostrado en esta sesión
          sessionStorage.setItem('welcomeModalShown', 'true');
        }
      }
    } catch (error) {
      console.error('Error completing onboarding:', error);
      const errorMessage = error.response?.data?.error || error.message || 'Error al completar la configuración inicial';
      setError(errorMessage);
      setTimeout(() => setError(''), 3000);
    }
  };

  // Función para manejar la redirección al hacer clic en el botón del modal
  const handleConnectAccounts = () => {
    navigate('/accounts');
  };

  // Manejador del cierre del modal que recuerda que el usuario eligió "Más tarde"
  const handleCloseModal = () => {
    setShowWelcomeModal(false);
    
    // Guardar timestamp del cierre para recordar cuando volver a mostrar
    localStorage.setItem('welcomeModalLastClosed', new Date().toISOString());
  };

  const WelcomeModal = ({ isOpen, onClose, onConnect }) => {
    if (!isOpen) return null;
  
    return (
      <div className="fixed inset-0 z-50 overflow-y-auto bg-black/50 flex items-center justify-center p-4 backdrop-blur-sm">
        <div className="relative bg-white rounded-2xl max-w-md w-full p-6 shadow-xl">
          <div className="text-center">
            {/* Logo más grande sin círculo */}
            <div className="mb-8 flex justify-center">
              <img src="/ch3.png" alt="Espor.ai" className="h-14 w-auto" />
            </div>
            
            {/* Título */}
            <h3 className="text-2xl font-bold text-gray-900 mb-4">
              ¡Bienvenido a Espor.ai!
            </h3>
            
            {/* Descripción */}
            <p className="text-md text-gray-600 mb-8">
              Para comenzar tu viaje con nosotros, primero necesitarás conectar tus cuentas publicitarias.
            </p>
            
            {/* Botones */}
            <div className="flex flex-col gap-4">
              <button
                onClick={onConnect}
                className="w-full px-5 py-3 bg-[#0F31F7] text-white rounded-lg hover:bg-[#0F31F7]/90 transition-colors font-medium shadow-md"
              >
                Conectar mis cuentas
              </button>
              
              <button
                onClick={onClose}
                className="w-full px-5 py-3 text-gray-600 hover:text-gray-800 transition-colors"
              >
                Más tarde
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Loading State
  if (isLoading || isLoadingGoogle) {
    return (
      <div className="flex h-screen bg-gray-50 items-center justify-center">
        <div className="text-center">
          <Loader className="w-8 h-8 text-[#974FF8] animate-spin mx-auto mb-4" />
          <p className="text-gray-600">
            {isLoadingGoogle ? 'Conectando con Google Ads...' : 'Cargando tu espacio de trabajo...'}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full relative">
      {/* Contenido Principal - Removido el header duplicado */}
      <div className="flex-1 overflow-hidden">
        <div className="h-full">
          <AICampaignCreation 
            selectedCustomerId={selectedCustomerId}
            managerCustomerId={managerCustomerId}
            onCampaignCreated={(newCampaign) => {
              setSuccess('¡Campaña creada exitosamente!');
              setTimeout(() => setSuccess(''), 3000);
              fetchCampaigns();
            }}
            onError={(errorMsg) => {
              setError(errorMsg);
              setTimeout(() => setError(''), 3000);
            }}
          />
        </div>
      </div>

      {/* Notifications */}
      <div className="fixed top-4 right-4 z-[70] space-y-2">
        {error && (
          <div className="flex items-center bg-red-500/10 border border-red-500 text-red-400 px-4 py-2 rounded-lg shadow-lg">
            <XCircle className="w-4 h-4 mr-2" />
            <span>{error}</span>
          </div>
        )}
        {success && (
          <div className="flex items-center bg-green-500/10 border border-green-500 text-green-400 px-4 py-2 rounded-lg shadow-lg">
            <CheckCircle className="w-4 h-4 mr-2" />
            <span>{success}</span>
          </div>
        )}
      </div>

      {/* Onboarding Modal */}
      {showOnboarding && (
        <div className="fixed inset-0 z-[80] bg-gray-900/80 backdrop-blur-sm">
          <OnboardingModal 
            onComplete={handleOnboardingComplete}
            className="bg-gray-800 text-gray-100 border border-gray-700 shadow-xl"
          />
        </div>
      )}

      {/* Modal de bienvenida */}
      <WelcomeModal
        isOpen={showWelcomeModal}
        onClose={handleCloseModal}
        onConnect={handleConnectAccounts}
      />
    </div>
  );
};

export default Dashboard;