import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/login/Login';
import Register from './pages/register/Register';
import Dashboard from './pages/dashboard/dashboard';
import Analytics from './pages/analytics/Analytics';
import AccountsConnection from './pages/accounts/AccountsConnection';
import FacebookCallback from './components/ui/FacebookCallback';
import GoogleCallback from './components/GoogleCallback';
import Profile from './pages/Profile/Profile';
import GoogleAuthHandler from './components/GoogleAuthHandler';
import ProtectedLayout from './components/ProtectedRoutes';
import AdminLogin from './components/admin/AdminLogin';
import IsAdmin from './components/auth/IsAdmin';
import { AuthProvider } from './components/context/AuthContext';

// Admin Components
import AdminLayout from './components/admin/Layout';
import AdminDashboard from './components/admin/Dashboard';
import UserManagement from './components/admin/Users';
import SubscriptionManagement from './components/admin/Subscriptions';
import Settings from './components/admin/Settings';
import Support from './components/admin/Support';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Public routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/google-callback" element={<GoogleCallback />} />
          
          {/* Admin login route */}
          <Route path="/admin-espor.ai/login" element={<AdminLogin />} />

          {/* Protected routes */}
          <Route element={<ProtectedLayout />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/analytics" element={<Analytics />} />
            <Route path="/accounts" element={<AccountsConnection />} />
            <Route path="/facebook-callback" element={<FacebookCallback />} />
            <Route path="/google-callback" element={<GoogleCallback />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/billing" element={<Profile />} />
            <Route path="/security" element={<Profile />} />
            <Route path="/settings" element={<Profile />} />
            <Route path="/help" element={<Profile />} />
          </Route>

          {/* Admin routes - todas protegidas con IsAdmin */}
          <Route 
            path="/admin-espor.ai" 
            element={
              <IsAdmin>
                <AdminLayout />
              </IsAdmin>
            }
          >
            <Route index element={<AdminDashboard />} />
            <Route path="users" element={<UserManagement />} />
            <Route path="subscriptions" element={<SubscriptionManagement />} />
            <Route path="settings" element={<Settings />} />
            <Route path="support" element={<Support />} />
            <Route path="*" element={<Navigate to="/admin-espor.ai" replace />} />
          </Route>

          {/* Default redirect */}
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
          
          {/* Catch-all route */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;