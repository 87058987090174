import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { useAuth } from '../components/context/AuthContext';
import { AuthContext } from '../components/context/AuthContext';
import { 
  MessageSquare, 
  BarChart2, 
  Link as LinkIcon,  
  LogOut, 
  Menu, 
  User, 
  X, 
  ChevronDown,
  RefreshCw,
  Settings,
  Plus,
  Archive,
  Pen,
  Edit,
  Check,
  Trash2,
  Library,
  GraduationCap
} from 'lucide-react';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const Sidebar = ({ 
  campaigns, 
  selectedCampaign, 
  setSelectedCampaign,
  setIsNewCampaignModalOpen,
  onExpandedChange,
  onNavigate // New prop for navigation without unmounting
}) => {
  const location = useLocation();
  const defaultNavigate = useNavigate(); // Fallback for compatibility
  const { auth, checkUserSubscription } = useContext(AuthContext);
  const { logout } = useAuth();
  
  // UI States
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [isLoadingSessions, setIsLoadingSessions] = useState(false);
  const [activeSessionId, setActiveSessionId] = useState(null);
  const [editingSessionId, setEditingSessionId] = useState(null);
  const [newSessionTitle, setNewSessionTitle] = useState('');
  const editInputRef = useRef(null);

  const [screenSize, setScreenSize] = useState({
    isMobile: false,
    isTablet: false,
    isDesktop: true
  });

  useEffect(() => {
    // When editing a session, focus the input field
    if (editingSessionId && editInputRef.current) {
      editInputRef.current.focus();
    }
  }, [editingSessionId]);

  useEffect(() => {
    const handleNewSession = (event) => {
      // You can add the new session directly without reloading all of them
      if (event.detail && event.detail.sessionData) {
        setSessions(prevSessions => [event.detail.sessionData, ...prevSessions]);
        
        // Update cache
        const updatedSessions = [event.detail.sessionData, ...sessions];
        sessionStorage.setItem('recent_sessions', JSON.stringify(updatedSessions));
      } else {
        // Fallback: reload all sessions
        fetchChatSessions();
      }
    };
    
    window.addEventListener('newChatSessionCreated', handleNewSession);
    return () => window.removeEventListener('newChatSessionCreated', handleNewSession);
  }, [sessions]);

  // Function to get the active session from backend and cache
  const fetchActiveSession = async () => {
    try {
      // First try to get from sessionStorage for immediate response
      const cachedSessionId = sessionStorage.getItem('active_session_id');
      if (cachedSessionId) {
        return cachedSessionId;
      }

      // If not in cache, get from backend
      const response = await axios.get(`${API_URL}/api/users/active-session`, {
        headers: { Authorization: `Bearer ${auth.token}` }
      });
      
      if (response.data.active_session_id) {
        // Save in cache for future reference
        sessionStorage.setItem('active_session_id', response.data.active_session_id);
        setActiveSessionId(response.data.active_session_id);
        return response.data.active_session_id;
      }
      
      // If there's no active session but there are sessions in cache
      const cachedSessions = sessionStorage.getItem('recent_sessions');
      if (cachedSessions) {
        try {
          const parsedSessions = JSON.parse(cachedSessions);
          if (parsedSessions.length > 0) {
            return parsedSessions[0].id;
          }
        } catch (e) {
          console.error('Error parsing cached sessions:', e);
        }
      }
      
      return null;
    } catch (error) {
      console.error('Error fetching active session:', error);
      return null;
    }
  };

  // Function to set a session as active
  const setActiveSession = async (sessionId) => {
    try {
      await axios.put(
        `${API_URL}/api/users/active-session`,
        { session_id: sessionId },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );
      
      // Update local state
      setActiveSessionId(sessionId);
      
      // Update cache
      sessionStorage.setItem('active_session_id', sessionId);
    } catch (error) {
      console.error('Error setting active session:', error);
    }
  };

  // Chat Sessions Management
  const fetchChatSessions = async () => {
    setIsLoadingSessions(true);
    try {
      // Try loading first from cache for immediate UI
      const cachedSessions = sessionStorage.getItem('recent_sessions');
      if (cachedSessions) {
        try {
          const parsedSessions = JSON.parse(cachedSessions);
          setSessions(parsedSessions);
        } catch (e) {
          console.error('Error parsing cached sessions:', e);
        }
      }
      
      // Get fresh data from server
      const [sessionsResponse, activeSessionResponse] = await Promise.all([
        axios.get(`${API_URL}/api/chat/sessions`, {
          headers: { Authorization: `Bearer ${auth.token}` }
        }),
        axios.get(`${API_URL}/api/users/active-session`, {
          headers: { Authorization: `Bearer ${auth.token}` }
        })
      ]);
      
      // Sort by most recent update date
      const orderedSessions = sessionsResponse.data.sort((a, b) => {
        return new Date(b.updated_at) - new Date(a.updated_at);
      });
      
      // Save in cache for quick access
      sessionStorage.setItem('recent_sessions', JSON.stringify(orderedSessions));
      
      // Update state
      setSessions(orderedSessions);
      
      if (activeSessionResponse.data.active_session_id) {
        const newActiveId = activeSessionResponse.data.active_session_id;
        setActiveSessionId(newActiveId);
        sessionStorage.setItem('active_session_id', newActiveId);
      } else if (orderedSessions.length > 0) {
        // If there's no active session, set the most recent one
        await setActiveSession(orderedSessions[0].id);
      }
    } catch (error) {
      console.error('Error fetching chat sessions:', error);
      toast.error('Could not load conversations');
    } finally {
      setIsLoadingSessions(false);
    }
  };

  const isActiveSession = (sessionId) => {
    return sessionId === activeSessionId;
  };

  const handleLogoutClick = useCallback(async () => {
    setShowProfileMenu(false);
    await logout();
    
    // Clear cache when logging out
    sessionStorage.removeItem('active_session_id');
    sessionStorage.removeItem('recent_sessions');
    
    // Use traditional navigation for complete logout
    defaultNavigate('/login');
  }, [logout, defaultNavigate]);

  // Enhanced navigation function
  const handleNavigation = async (path, params = {}) => {
    // Build the final URL based on path and parameters
    let finalPath = path;
    
    // Special logic for dashboard/chat
    if (path === '/dashboard') {
      // Try to get the active session
      const activeChatId = await fetchActiveSession();
      
      if (activeChatId) {
        finalPath = `${path}?chat=${activeChatId}`;
      } else if (sessions.length > 0) {
        // If there's no active session but there are sessions, use the most recent one
        await setActiveSession(sessions[0].id);
        finalPath = `${path}?chat=${sessions[0].id}`;
      }
      // If there are no sessions, navigate to dashboard without parameters
    } else if (params && Object.keys(params).length > 0) {
      // Add query parameters if they exist
      const queryParams = new URLSearchParams(params).toString();
      finalPath = `${path}?${queryParams}`;
    }
    
    // Use the custom navigation function if available
    if (typeof onNavigate === 'function') {
      onNavigate(finalPath);
    } else {
      // Fallback to standard navigation
      defaultNavigate(finalPath);
    }
    
    // Close the menu on mobile/tablet if necessary
    if (screenSize.isMobile || screenSize.isTablet) {
      setIsMobileOpen(false);
    }
  };

  const createNewSession = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/api/chat/sessions`,
        {
          title: `Chat ${new Date().toLocaleString()}`
        },
        {
          headers: {
            Authorization: `Bearer ${auth.token}`
          }
        }
      );
      
      // Get the new session data
      const newSession = response.data;
      
      // Update the state with the new session
      setSessions(prevSessions => {
        // Create the updated sessions array
        const updatedSessions = [newSession, ...prevSessions];
        
        // Update the cache with the newly created array, not using the outdated 'sessions'
        sessionStorage.setItem('recent_sessions', JSON.stringify(updatedSessions));
        
        // Return the updated array for state update
        return updatedSessions;
      });
      
      // Set as active
      await setActiveSession(newSession.id);
      
      // Navigate to the new session
      handleNavigation('/dashboard', { chat: newSession.id });
    } catch (error) {
      console.error('Error creating new session:', error);
      toast.error('Could not create a new conversation');
    }
  };

  // New function to start editing a session title
  const startEditingSession = (session) => {
    setEditingSessionId(session.id);
    setNewSessionTitle(session.title || `Chat ${new Date(session.created_at).toLocaleString()}`);
  };

  // New function to save an edited session title
  const saveSessionTitle = async (sessionId) => {
    if (!newSessionTitle.trim()) {
      toast.error('Title cannot be empty');
      return;
    }

    try {
      const response = await axios.put(
        `${API_URL}/api/chat/sessions/${sessionId}`,
        { title: newSessionTitle.trim() },
        {
          headers: {
            Authorization: `Bearer ${auth.token}`
          }
        }
      );
      
      // Update local state with the new title
      const updatedSessions = sessions.map(s => 
        s.id === sessionId ? { ...s, title: newSessionTitle.trim() } : s
      );
      
      setSessions(updatedSessions);
      
      // Update cache
      sessionStorage.setItem('recent_sessions', JSON.stringify(updatedSessions));
      
      // Clear editing state
      setEditingSessionId(null);
      setNewSessionTitle('');
      
      toast.success('Chat renamed successfully');
    } catch (error) {
      console.error('Error updating session title:', error);
      toast.error('Could not rename the chat');
    }
  };

  // Cancel editing
  const cancelEditingSession = () => {
    setEditingSessionId(null);
    setNewSessionTitle('');
  };

  const archiveSession = async (sessionId) => {
    try {
      // Confirm before deleting
      if (!window.confirm('Are you sure you want to delete this chat?')) {
        return;
      }
      
      await axios.delete(
        `${API_URL}/api/chat/sessions/${sessionId}`,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`
          }
        }
      );
      
      // Update local state
      const updatedSessions = sessions.filter(s => s.id !== sessionId);
      setSessions(updatedSessions);
      
      // Update cache
      sessionStorage.setItem('recent_sessions', JSON.stringify(updatedSessions));
      
      // If we archive the active session, set a new active session
      if (sessionId === activeSessionId && updatedSessions.length > 0) {
        const nextActiveSession = updatedSessions[0];
        await setActiveSession(nextActiveSession.id);
        handleNavigation('/dashboard', { chat: nextActiveSession.id });
      } else if (updatedSessions.length === 0) {
        // If all sessions are deleted, refresh the page
        handleNavigation('/dashboard');
      }
      
      toast.success('Chat deleted successfully');
    } catch (error) {
      console.error('Error archiving session:', error);
      toast.error('Could not delete the chat');
    }
  };

  // Load sessions on mount
  useEffect(() => {
    if (auth.token) {
      fetchChatSessions();
    }
  }, [auth.token]);

  // Detect screen size
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      const newScreenSize = {
        isMobile: width < 640,
        isTablet: width >= 640 && width < 1024,
        isDesktop: width >= 1024
      };
      
      setScreenSize(newScreenSize);
      
      // If changing to desktop, expand sidebar
      if (newScreenSize.isDesktop && !screenSize.isDesktop) {
        onExpandedChange?.(true);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [onExpandedChange, screenSize.isDesktop]);

  // Close menu when changing route on mobile/tablet
  useEffect(() => {
    if (screenSize.isMobile || screenSize.isTablet) {
      setIsMobileOpen(false);
      setShowProfileMenu(false);
    }
  }, [location.pathname, screenSize.isMobile, screenSize.isTablet]);

  // Keep sidebar expanded on desktop
  useEffect(() => {
    if (screenSize.isDesktop) {
      onExpandedChange?.(true);
    }
  }, [screenSize.isDesktop, onExpandedChange]);

  // Check active session on mount or when route changes
  useEffect(() => {
    const checkActiveSession = async () => {
      if (location.pathname === '/dashboard') {
        const params = new URLSearchParams(location.search);
        const currentChatId = params.get('chat');
        
        // If there's no chat in the URL but there are sessions
        if (!currentChatId && sessions.length > 0) {
          // Try to get the active session
          const activeChatId = await fetchActiveSession();
          
          if (activeChatId) {
            // Navigate without unmounting components
            handleNavigation('/dashboard', { chat: activeChatId });
          } else {
            // If there's no active session, use the first available one
            await setActiveSession(sessions[0].id);
            handleNavigation('/dashboard', { chat: sessions[0].id });
          }
        } else if (currentChatId && (!activeSessionId || currentChatId !== activeSessionId.toString())) {
          // Update active session if it changed
          await setActiveSession(parseInt(currentChatId));
        }
      }
    };
    
    if (auth.token && sessions.length > 0) {
      checkActiveSession();
    }
  }, [location.pathname, location.search, sessions.length, auth.token]);

  // Navigation menu items
  const menuItems = [
    { 
      icon: MessageSquare, 
      label: 'Chat IA', 
      path: '/dashboard',
      description: 'Asistente de Marketing'
    },
    { 
      icon: BarChart2, 
      label: 'Analytics', 
      path: '/analytics',
      description: 'Análisis de Campañas'
    },
    { 
      icon: LinkIcon, 
      label: 'Conexiones', 
      path: '/accounts',
      description: 'Gestión de Cuentas'
    },
    {
      icon: Pen,
      label: 'Diseñador IA',
      path: '/design',
      description: 'Próximamente Disponible',
      disabled: true,
      tooltip: 'Próximamente Disponible',
      tooltipStyle: { backgroundColor: '#2745F8', color: '#fff', borderRadius: '5px', padding: '5px' }
    },
    {
      icon: GraduationCap,
      label: 'AcademIA',
      path: '/academia',
      description: 'Próximamente Disponible',
      disabled: true,
      tooltip: 'Próximamente Disponible',
      tooltipStyle: { backgroundColor: '#2745F8', color: '#fff', borderRadius: '5px', padding: '5px' }
    }
  ];

  const isExpanded = screenSize.isDesktop || isMobileOpen;

  return (
    <>
      {/* Backdrop */}
      {(screenSize.isMobile || screenSize.isTablet) && isExpanded && (
        <div 
          className="fixed inset-0 bg-gray-500/80 transition-opacity z-30 backdrop-blur-[1px]"
          onClick={() => setIsMobileOpen(false)}
        />
      )}
      
      {/* Mobile Toggle Button */}
      <button 
        className="lg:hidden fixed top-4 left-4 z-[60] p-2.5 rounded-xl bg-white/95 shadow-lg
          hover:bg-gray-100 active:bg-gray-200 transition-all duration-200 backdrop-blur-sm
          border border-gray-200"
        onClick={() => setIsMobileOpen(!isMobileOpen)}
      >
        {isMobileOpen ? 
          <X size={22} className="text-gray-700" /> : 
          <Menu size={22} className="text-gray-700" />
        }
      </button>

      {/* Sidebar Container */}
      <aside
        className={`fixed top-0 left-0 z-50 h-[100vh] transition-all duration-300 ease-in-out
          ${
            screenSize.isMobile || screenSize.isTablet 
              ? isExpanded 
                ? 'translate-x-0 w-[280px]' 
                : '-translate-x-full w-0'
              : isExpanded 
                ? 'w-64' 
                : 'w-16'
          }
        `}
      >
        <div className="flex flex-col h-full overflow-hidden bg-white border-r border-gray-200">
          {/* Header Section */}
          <div className="shrink-0 p-4 border-b border-gray-200">
            <div className="flex items-center min-w-0">
              <div className="relative flex items-center justify-center w-36">
                <img 
                  src="/ch3.png" 
                  alt="CH3" 
                  className="h-8 w-auto relative z-10"
                />
              </div>
            </div>
          </div>

          {/* Navigation and Content */}
          <div className="flex-1 flex flex-col min-h-0 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent">
            {/* Main Navigation */}
            <nav className="px-2 space-y-1 py-2">
              {menuItems.map((item) => (
                <button
                  key={item.path}
                  onClick={() => !item.disabled && handleNavigation(item.path)}
                  className={`relative group flex items-center w-full rounded-lg px-3 py-2.5 transition-all duration-200
                    ${item.disabled 
                      ? 'cursor-not-allowed opacity-50' 
                      : location.pathname === item.path
                        ? 'text-blue-600 bg-blue-50'
                        : 'text-gray-600 hover:text-gray-900 hover:bg-gray-100'
                    }`}
                  title={item.tooltip}
                >
                  {location.pathname === item.path && !item.disabled && (
                    <div className="absolute inset-y-1 left-0 w-1 bg-blue-600 rounded-r-full" />
                  )}
                  
                  <item.icon size={18} className="flex-shrink-0" />
                  
                  <div className="ml-3 text-left min-w-0">
                    <div className="font-medium truncate">{item.label}</div>
                    <div className="text-xs text-gray-500 truncate">{item.description}</div>
                  </div>
                  
                  {item.disabled && (
                    <div className="absolute invisible group-hover:visible bg-gray-800 text-white text-xs py-1 px-2 rounded-md -top-8 left-1/2 transform -translate-x-1/2">
                      {item.tooltip}
                    </div>
                  )}
                </button>
              ))}
            </nav>

            {/* Chat Sessions Section */}
            <div className="px-2 py-4">
              <div className="flex items-center justify-between px-3 mb-2">
                <span className="text-sm font-medium text-gray-600">Chats</span>
                <div className="flex items-center gap-1">
                  <button
                    onClick={fetchChatSessions}
                    className="p-1 rounded-lg text-gray-500 hover:text-gray-900 hover:bg-gray-100 transition-colors"
                    title="Actualizar chats"
                  >
                    <RefreshCw size={14} />
                  </button>
                  <button
                    onClick={createNewSession}
                    className="p-1 rounded-lg text-gray-500 hover:text-gray-900 hover:bg-gray-100 transition-colors"
                    title="Nuevo chat"
                  >
                    <Plus size={14} />
                  </button>
                </div>
              </div>

              {isLoadingSessions ? (
                <div className="flex justify-center py-4">
                  <RefreshCw className="animate-spin text-blue-600" size={18} />
                </div>
              ) : (
                <div className="space-y-1">
                  {sessions.map((session) => (
                    <div
                      key={session.id}
                      className={`group flex ${editingSessionId === session.id ? 'flex-col' : 'items-center'} gap-2 px-3 py-2 rounded-lg 
                        transition-colors ${editingSessionId === session.id ? 'cursor-default' : 'cursor-pointer'} 
                        ${isActiveSession(session.id) 
                          ? 'bg-blue-50 border-blue-200 border' 
                          : 'hover:bg-gray-100 border border-transparent'}`}
                    >
                      {editingSessionId === session.id ? (
                        // Editing mode
                        <>
                          <div className="flex w-full items-center mb-2">
                            <input
                              ref={editInputRef}
                              type="text"
                              value={newSessionTitle}
                              onChange={(e) => setNewSessionTitle(e.target.value)}
                              className="flex-1 text-sm px-2 py-1 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  saveSessionTitle(session.id);
                                } else if (e.key === 'Escape') {
                                  cancelEditingSession();
                                }
                              }}
                            />
                          </div>
                          <div className="flex justify-end w-full gap-2">
                            <button
                              onClick={() => saveSessionTitle(session.id)}
                              className="p-1 text-green-600 rounded hover:bg-green-50"
                            >
                              <Check size={16} />
                            </button>
                            <button
                              onClick={cancelEditingSession}
                              className="p-1 text-gray-600 rounded hover:bg-gray-100"
                            >
                              <X size={16} />
                            </button>
                          </div>
                        </>
                      ) : (
                        // Display mode
                        <>
                          <div 
                            className="flex-1 min-w-0"
                            onClick={async () => {
                              // Only navigate if not currently editing
                              if (editingSessionId === null) {
                                // Set this session as active
                                await setActiveSession(session.id);
                                
                                // Navigate without unmounting components
                                handleNavigation('/dashboard', { chat: session.id });
                              }
                            }}
                          >
                            <div className="text-sm font-medium text-gray-800 truncate">
                              {session.title || `Chat ${new Date(session.created_at).toLocaleString()}`}
                            </div>
                            {session.last_message && (
                              <div className="text-xs text-gray-500 truncate">
                                {session.last_message.substring(0, 60)}
                                {session.last_message.length > 60 ? '...' : ''}
                              </div>
                            )}
                          </div>
                          <div className="flex items-center">
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                startEditingSession(session);
                              }}
                              className="p-1 text-gray-500 hover:text-blue-600 transition-all"
                              title="Renombrar chat"
                            >
                              <Edit size={16} />
                            </button>
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                archiveSession(session.id);
                              }}
                              className="p-1 text-gray-500 hover:text-red-500 transition-all"
                              title="Eliminar chat"
                            >
                              <Trash2 size={16} />
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                  
                  {sessions.length === 0 && (
                    <div className="text-center py-6">
                      <p className="text-sm text-gray-500">No hay conversaciones</p>
                      <button
                        onClick={createNewSession}
                        className="mt-2 text-xs text-blue-600 hover:text-blue-800"
                      >
                        Crear nueva conversación
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          {/* Profile and Logout Section */}
          <div className="shrink-0 border-t border-gray-200">
            <div className="relative">
              <button
                onClick={() => setShowProfileMenu(!showProfileMenu)}
                className="flex items-center w-full px-4 py-3 text-gray-700 hover:text-gray-900 
                  hover:bg-gray-100 transition-colors duration-200"
              >
                <div className="flex items-center flex-1 min-w-0">
                  <div className="relative flex-shrink-0">
                    <div className="h-8 w-8 rounded-full bg-blue-100 flex items-center justify-center
                      ring-2 ring-blue-200">
                      <span className="text-sm font-medium text-blue-600">
                        {auth.user?.name?.[0] || 'U'}
                      </span>
                    </div>
                    <div className="absolute bottom-0 right-0 h-2.5 w-2.5 bg-green-500 rounded-full
                      ring-2 ring-white" />
                  </div>
                  <div className="ml-3 flex-1 min-w-0">
                    <p className="text-sm font-medium truncate">
                      {auth.user?.name || 'User'}
                    </p>
                    <p className="text-xs text-gray-500 truncate">
                      {auth.user?.email}
                    </p>
                  </div>
                </div>
                <ChevronDown
                  size={16}
                  className={`ml-2 transform transition-transform duration-200
                    ${showProfileMenu ? 'rotate-180' : ''}`}
                />
              </button>

              {/* Profile Menu Dropdown */}
              {showProfileMenu && (
                <div className="absolute bottom-full left-0 right-0 mb-1 bg-white rounded-lg
                  shadow-lg overflow-hidden border border-gray-200">
                  <button
                    onClick={() => {
                      handleNavigation('/profile');
                      setShowProfileMenu(false);
                    }}
                    className="flex items-center w-full px-4 py-2 text-gray-600
                      hover:bg-gray-100 hover:text-gray-900 transition-colors duration-200"
                  >
                    <User size={16} className="mr-2" />
                    <span className="text-sm">Mi Perfil</span>
                  </button>
                  <button
                    onClick={handleLogoutClick}
                    className="flex items-center w-full px-4 py-2 text-red-600
                      hover:bg-red-50 hover:text-red-700 transition-colors duration-200"
                  >
                    <LogOut size={16} className="mr-2" />
                    <span className="text-sm">Cerrar Sesión</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </aside>
    </>
  );
};

// Updated PropTypes
Sidebar.propTypes = {
  campaigns: PropTypes.array,
  selectedCampaign: PropTypes.object,
  setSelectedCampaign: PropTypes.func,
  setIsNewCampaignModalOpen: PropTypes.func,
  onExpandedChange: PropTypes.func,
  onNavigate: PropTypes.func // New prop
};

// Default values
Sidebar.defaultProps = {
  campaigns: [],
  onNavigate: null // Use normal navigation if not provided
};

export default Sidebar;