import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../components/context/AuthContext'; 
import { ArrowRight, ArrowLeft, Check } from 'lucide-react';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const OnboardingModal = ({ onComplete }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { auth, updateUser } = useAuth();
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);
  const [formData, setFormData] = useState({
    first_name: '',
    businessName: '',
    websiteUrl: '',
    businessDetails: '',
    previousAds: {
      hasAdvertised: null, 
      platforms: []
    },
    digitalExperience: '',
    firstAction: 'later',
    selected_plan: 'free_trial', // Cambiado a free_trial para consistencia
  });

  // Crear suscripción gratuita al iniciar
  useEffect(() => {
    const createFreeSubscription = async () => {
      if (!auth.token) return;
      
      try {
        console.log("Intentando crear suscripción gratuita...");
        const response = await fetch(`${API_URL}/api/subscriptions/create`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${auth.token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            plan_id: 'free_trial'
          })
        });
    
        if (!response.ok) {
          const errorData = await response.json();
          console.error('Error al crear la suscripción gratuita:', errorData);
          return;
        }
        
        const data = await response.json();
        console.log("Suscripción gratuita creada exitosamente:", data);
      } catch (error) {
        console.error('Error al crear suscripción gratuita:', error);
      }
    };

    createFreeSubscription();
  }, [auth.token]);

  const totalSteps = 6;
  const progress = (step / totalSteps) * 100;

  // Guardar progreso del onboarding
  const saveProgress = async (data) => {
    if (!auth.token) return null;
    
    try {
      console.log("Guardando progreso del onboarding...");
      // Preparar los datos (asegurarnos que previousAds se envía correctamente)
      const progressData = {
        ...data,
        selected_plan: data.selected_plan || 'free_trial'
      };
      
      const response = await fetch(`${API_URL}/api/user/save-onboarding-progress`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${auth.token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(progressData)
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || 'Error al guardar el progreso');
      }

      const responseData = await response.json();
      console.log("Progreso guardado exitosamente:", responseData);
      return responseData;
    } catch (err) {
      console.error('Error al guardar progreso:', err);
      setError(err.message);
      throw err;
    }
  };

  // Verificar estado del onboarding al cargar
  useEffect(() => {
    const checkOnboardingStatus = async () => {
      if (!auth.token) return;
      
      try {
        console.log("Verificando estado del onboarding...");
        const response = await fetch(`${API_URL}/api/user/check-onboarding`, {
          headers: {
            'Authorization': `Bearer ${auth.token}`,
            'Content-Type': 'application/json'
          }
        });
        
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Error al verificar el estado del onboarding');
        }
        
        const data = await response.json();
        console.log("Estado del onboarding:", data);
        
        if (data.onboarding_completed) {
          console.log("Onboarding ya completado, redirigiendo al dashboard");
          navigate('/dashboard');
        }
      } catch (error) {
        console.error('Error al verificar estado del onboarding:', error);
        setError('Error al verificar el estado del onboarding');
      }
    };

    if (auth.token) {
      checkOnboardingStatus();
    }
  }, [auth.token, navigate]);

  // Validar cada paso del formulario
  const validateStep = useCallback(() => {
    try {
      switch (step) {
        case 1:
          if (!formData.first_name?.trim()) throw new Error('El nombre es requerido');
          return true;
        case 2:
          if (!formData.businessName?.trim()) throw new Error('El nombre del negocio es requerido');
          return true;
        case 3:
          if (!formData.websiteUrl?.trim()) throw new Error('La URL del sitio web es requerida');
          return true;
        case 4:
          if (!formData.businessDetails?.trim()) throw new Error('Los detalles del negocio son requeridos');
          return true;
        case 5:
          if (formData.previousAds.hasAdvertised === null) throw new Error('Indica si ya has promocionado tu negocio');
          if (formData.previousAds.hasAdvertised && formData.previousAds.platforms.length === 0) {
            throw new Error('Selecciona al menos una plataforma de anuncios');
          }
          return true;
        case 6:
          if (!formData.digitalExperience) throw new Error('La experiencia en Marketing Digital es requerida');
          return true;
        default:
          return false;
      }
    } catch (e) {
      setError(e.message);
      return false;
    }
  }, [step, formData]);

  // Avanzar al siguiente paso
  const handleNextStep = useCallback(async () => {
    setHasAttemptedSubmit(true);
    if (validateStep()) {
      try {
        await saveProgress(formData);
        setStep(s => s + 1);
        setHasAttemptedSubmit(false);
        setError(null);
        
        setTimeout(() => {
          const input = document.querySelector('input[autoFocus], textarea[autoFocus]');
          if (input) input.focus();
        }, 50);
      } catch (err) {
        console.error('Error al avanzar al siguiente paso:', err);
      }
    }
  }, [validateStep, formData, saveProgress, step]);

  // Manejar tecla Enter
  useEffect(() => {
    const handleKeyPress = async (event) => {
      if (event.key === 'Enter' && step < totalSteps) {
        event.preventDefault();
        await handleNextStep();
      }
    };

    document.addEventListener('keypress', handleKeyPress);
    return () => document.removeEventListener('keypress', handleKeyPress);
  }, [step, totalSteps, handleNextStep]);

  // Retroceder al paso anterior
  const handlePrevStep = useCallback(() => {
    setStep(s => s - 1);
    setError(null);
    setHasAttemptedSubmit(false);
  }, []);

  // Función para verificar el estado del onboarding
  const checkOnboardingStatus = async () => {
    if (!auth.token) return null;
    
    try {
      console.log("Verificando estado del onboarding...");
      const response = await fetch(`${API_URL}/api/user/check-onboarding`, {
        headers: {
          'Authorization': `Bearer ${auth.token}`,
          'Content-Type': 'application/json'
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Error al verificar el estado del onboarding');
      }
      
      const data = await response.json();
      console.log("Estado actual del onboarding:", data);
      
      if (data.onboarding_completed) {
        console.log("Onboarding completado, preparando redirección...");
      }
      
      return data;
    } catch (error) {
      console.error('Error al verificar estado del onboarding:', error);
      setError('Error al verificar el estado del onboarding');
      return null;
    }
  };

  // Enviar formulario final
  const handleSubmit = useCallback(async () => {
    if (loading) return;
  
    try {
      setLoading(true);
      setError(null);
      
      console.log("Completando onboarding...");
  
      // Validar el último paso
      if (!validateStep()) {
        setLoading(false);
        return;
      }
  
      // Guardar progreso final
      await saveProgress(formData);
  
      // Llamar a la API para completar el onboarding
      console.log("Enviando datos finales al servidor...");
      const response = await fetch(`${API_URL}/api/user/onboarding`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${auth.token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...formData,
          selected_plan: 'free_trial'
        })
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Error al completar el onboarding');
      }
  
      const responseData = await response.json();
      console.log("Respuesta del servidor al completar onboarding:", responseData);
  
      // Actualizar datos del usuario
      await updateUser({
        ...responseData,
        onboarding_completed: true
      });
      console.log("Usuario actualizado con onboarding_completed=true");
  
      // Verificar estado final
      await checkOnboardingStatus();
  
      if (onComplete) {
        onComplete(formData);
      }
  
      // Navegar al dashboard
      console.log("Redirigiendo al dashboard...");
      navigate('/dashboard');
  
    } catch (error) {
      console.error('Error al completar onboarding:', error);
      setError(error.message || 'Error al completar el onboarding');
    } finally {
      setLoading(false);
    }
  }, [auth.token, formData, loading, onComplete, updateUser, navigate, saveProgress, validateStep]);

  // Renderizar pasos del formulario
  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div>
            <h2 className="text-2xl font-bold mb-6">¿Cuál es tu nombre?</h2>
            <input
              autoFocus
              type="text"
              name="first_name"
              value={formData.first_name}
              onChange={(e) => setFormData(prev => ({ ...prev, first_name: e.target.value }))}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
              placeholder="Tu nombre"
            />
            {hasAttemptedSubmit && !formData.first_name?.trim() && (
              <p className="text-red-500 text-sm mt-2">El nombre es requerido</p>
            )}
          </div>
        );

      case 2:
        return (
          <div>
            <h2 className="text-2xl font-bold mb-6">¿Cómo se llama tu negocio?</h2>
            <input
              autoFocus
              type="text"
              name="businessName"
              value={formData.businessName}
              onChange={(e) => setFormData(prev => ({ ...prev, businessName: e.target.value }))}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
              placeholder="Nombre del negocio"
            />
            {hasAttemptedSubmit && !formData.businessName?.trim() && (
              <p className="text-red-500 text-sm mt-2">El nombre del negocio es requerido</p>
            )}
          </div>
        );

      case 3:
        return (
          <div>
            <h2 className="text-2xl font-bold mb-6">¿Cuál es la URL de tu sitio web?</h2>
            <input
              autoFocus
              type="url"
              name="websiteUrl"
              value={formData.websiteUrl}
              onChange={(e) => setFormData(prev => ({ ...prev, websiteUrl: e.target.value }))}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
              placeholder="https://"
            />
            {hasAttemptedSubmit && !formData.websiteUrl?.trim() && (
              <p className="text-red-500 text-sm mt-2">La URL del sitio web es requerida</p>
            )}
          </div>
        );

      case 4:
        return (
          <div>
            <h2 className="text-2xl font-bold mb-6">Cuéntanos más sobre tu negocio</h2>
            <textarea
              autoFocus
              name="businessDetails"
              value={formData.businessDetails}
              onChange={(e) => setFormData(prev => ({ ...prev, businessDetails: e.target.value }))}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleNextStep();
                }
              }}
              className="w-full h-32 px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
              placeholder="Describe tu negocio..."
            />
            {hasAttemptedSubmit && !formData.businessDetails?.trim() && (
              <p className="text-red-500 text-sm mt-2">Los detalles del negocio son requeridos</p>
            )}
          </div>
        );

      case 5:
        return (
          <div>
            <h2 className="text-2xl font-bold mb-6">¿Ya promocionaste tu negocio con anuncios digitales?</h2>
            <div className="space-y-3 sm:space-y-4">
              {['Sí', 'No'].map((option) => (
                <button
                  key={option}
                  onClick={() => {
                    setFormData(prev => ({
                      ...prev,
                      previousAds: { 
                        ...prev.previousAds,
                        hasAdvertised: option === 'Sí',
                        platforms: option === 'No' ? [] : prev.previousAds.platforms
                      }
                    }));
                  }}
                  className={`w-full px-4 py-3 border rounded-md text-left ${
                    formData.previousAds.hasAdvertised === (option === 'Sí')
                      ? 'border-indigo-500 bg-indigo-50 text-indigo-700'
                      : 'border-gray-300 hover:border-indigo-300'
                  }`}
                >
                  {option}
                </button>
              ))}
            </div>

            {formData.previousAds.hasAdvertised && (
              <div className="mt-6">
                <h3 className="text-lg font-semibold mb-4">¿En qué plataformas?</h3>
                <div className="space-y-3">
                  {['Google', 'Meta'].map((platform) => (
                    <button
                      key={platform}
                      onClick={() => {
                        const platforms = formData.previousAds.platforms;
                        setFormData(prev => ({
                          ...prev,
                          previousAds: {
                            ...prev.previousAds,
                            platforms: platforms.includes(platform)
                              ? platforms.filter(p => p !== platform)
                              : [...platforms, platform]
                          }
                        }));
                      }}
                      className={`w-full px-4 py-3 border rounded-md text-left ${
                        formData.previousAds.platforms.includes(platform)
                          ? 'border-indigo-500 bg-indigo-50 text-indigo-700'
                          : 'border-gray-300 hover:border-indigo-300'
                      }`}
                    >
                      {platform}
                    </button>
                  ))}
                </div>
                {hasAttemptedSubmit && 
                 formData.previousAds.hasAdvertised && 
                 formData.previousAds.platforms.length === 0 && (
                  <p className="text-red-500 text-sm mt-2">Selecciona al menos una plataforma</p>
                )}
              </div>
            )}

            {/* Mostrar errores solo después de intentar avanzar */}
            {hasAttemptedSubmit && formData.previousAds.hasAdvertised === null && (
              <p className="text-red-500 text-sm mt-4">Indica si ya has promocionado tu negocio</p>
            )}
          </div>
        );

      case 6:
        return (
          <div>
            <h2 className="text-2xl font-bold mb-6">¿Cuánta experiencia tienes en Marketing Digital?</h2>
            <div className="space-y-3">
              {['Ninguna', 'Un poco', 'Soy un experto'].map((level) => (
                <button
                  key={level}
                  onClick={() => {
                    setFormData(prev => ({ ...prev, digitalExperience: level }));
                    setError(null);
                  }}
                  className={`w-full px-4 py-3 border rounded-md text-left ${
                    formData.digitalExperience === level
                      ? 'border-indigo-500 bg-indigo-50 text-indigo-700'
                      : 'border-gray-300 hover:border-indigo-300'
                  }`}
                >
                  {level}
                </button>
              ))}
            </div>
            {hasAttemptedSubmit && !formData.digitalExperience && (
              <p className="text-red-500 text-sm mt-2">La experiencia en Marketing Digital es requerida</p>
            )}
          </div>
        );

      default:
        return null;
    }
  };

  // Textos de botones y navegación
  const navigationTexts = {
    next: "Siguiente",
    previous: "Anterior",
    complete: "Completar"
  };

  return (
    <div className="min-h-screen flex flex-col md:flex-row relative overflow-hidden">
      {/* Logo */}
      <div className="absolute top-6 sm:top-8 left-1/2 transform -translate-x-1/2 z-50">
        <img 
          src="/ch3.png" 
          alt="Logo" 
          className="h-12 sm:h-16 w-auto" 
        />
      </div>

      {/* Left side - Form - Ajustado para mejor responsividad */}
      <div className="w-full md:w-1/2 flex flex-col justify-center p-4 pt-20 sm:pt-24 md:p-8 min-h-screen md:min-h-0 bg-white">
        <div className="w-full max-w-md mx-auto overflow-y-auto">
          {/* Progress bar */}
          <div className="mb-6 sm:mb-8">
            <div className="flex justify-between items-center mb-2">
              <span className="text-sm text-gray-500">Paso {step} de {totalSteps}</span>
              <span className="text-sm font-medium">{Math.round(progress)}%</span>
            </div>
            <div className="h-2 bg-gray-200 rounded-full">
              <div
                className="h-full bg-indigo-600 rounded-full transition-all duration-300"
                style={{ width: `${progress}%` }}
              />
            </div>
          </div>

          {/* Error message */}
          {error && (
            <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded-md">
              <p className="text-red-600 text-sm">{error}</p>
            </div>
          )}

          {/* Form content - Ajustado para mejor adaptabilidad */}
          <div className="bg-white rounded-lg shadow-lg p-4 sm:p-6 md:p-8">
            {renderStep()}

            {/* Navigation buttons - Mejorado para responsividad */}
            <div className="mt-6 sm:mt-8 flex justify-between items-center">
              {step > 1 ? (
                <button
                  onClick={handlePrevStep}
                  className="flex items-center px-3 py-2 sm:px-4 sm:py-2 text-sm font-medium text-gray-700 hover:text-gray-900"
                >
                  <ArrowLeft className="w-4 h-4 mr-1 sm:mr-2" />
                  <span>Anterior</span>
                </button>
              ) : (
                <div></div> // Espacio vacío para mantener la alineación
              )}
              
              {step < totalSteps ? (
                <button
                  onClick={handleNextStep}
                  disabled={loading}
                  className={`flex items-center px-4 py-2 sm:px-6 sm:py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 ml-auto ${
                    loading ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                >
                  {loading ? (
                    <span>Cargando...</span>
                  ) : (
                    <>
                      <span>Siguiente</span>
                      <ArrowRight className="w-4 h-4 ml-1 sm:ml-2" />
                    </>
                  )}
                </button>
              ) : (
                <button
                  onClick={handleSubmit}
                  disabled={loading}
                  className={`flex items-center px-4 py-2 sm:px-6 sm:py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 ml-auto ${
                    loading ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                >
                  {loading ? (
                    <span>Cargando...</span>
                  ) : (
                    <>
                      <span>Completar</span>
                      <Check className="w-4 h-4 ml-1 sm:ml-2" />
                    </>
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Right side - Video - Ajustado para mejor adaptabilidad */}
      <div className="hidden md:flex md:w-1/2 relative overflow-hidden flex justify-center items-center bg-white">
        <div className="w-full max-w-[600px] aspect-square relative overflow-hidden rounded-lg mx-auto">
          <video
            className="absolute w-full h-full object-cover"
            autoPlay
            loop
            muted
            playsInline
          >
            <source src="https://www.espor.ai/images/hero-gif.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
};

export default OnboardingModal;