// AuthContext.js
import React, { createContext, useState, useEffect, useCallback, useContext, useRef } from 'react';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';

// Configuración global de Axios
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

// Interceptor para manejar Content-Type
axios.interceptors.request.use((config) => {
  // Si estamos enviando FormData, dejamos que axios maneje el Content-Type
  if (config.data instanceof FormData) {
    delete config.headers['Content-Type'];
  } else {
    // Para todas las demás peticiones, usamos application/json
    config.headers['Content-Type'] = 'application/json';
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

const LoadingScreen = () => (
  <div className="min-h-screen flex bg-gray-50 flex-col items-center justify-center fixed inset-0 z-50">
    <div className="flex flex-col items-center justify-center gap-4">
      <div className="relative w-16 h-16">
        <div className="absolute inset-0 border-4 border-[#974FF8]/20 rounded-full"></div>
        <div className="absolute inset-0 border-4 border-[#974FF8] rounded-full animate-spin border-t-transparent"></div>
      </div>
      <p className="text-gray-600">Espere un momento...</p>
    </div>
  </div>
);

export const AuthContext = createContext();

// Control de estado de validación global
const validationState = {
  inProgress: false,
  lastValidation: null,
  validationPromise: null,
  VALIDATION_INTERVAL: 5 * 60 * 1000 // 5 minutos
};

// Gestor de caché global
const cacheManager = {
  cache: new Map(),
  timeouts: new Map(),

  set: (key, value, ttl = validationState.VALIDATION_INTERVAL) => {
    if (cacheManager.timeouts.has(key)) {
      clearTimeout(cacheManager.timeouts.get(key));
    }
    cacheManager.cache.set(key, value);
    cacheManager.timeouts.set(key, setTimeout(() => {
      cacheManager.cache.delete(key);
      cacheManager.timeouts.delete(key);
    }, ttl));
  },

  get: (key) => cacheManager.cache.get(key),

  clear: () => {
    cacheManager.timeouts.forEach(timeout => clearTimeout(timeout));
    cacheManager.cache.clear();
    cacheManager.timeouts.clear();
  }
};

// Función para limpiar Facebook SDK session
const logoutFromFacebook = () => {
  return new Promise((resolve) => {
    // Verificar si FB está disponible (SDK de Facebook cargado)
    if (typeof window.FB !== 'undefined') {
      window.FB.getLoginStatus(function(response) {
        if (response && response.status === 'connected') {
          // Si hay una sesión activa de Facebook, cerrarla
          window.FB.logout(function() {
            console.log('Sesión de Facebook cerrada');
            resolve(true);
          });
        } else {
          // No hay sesión activa
          resolve(true);
        }
      });
    } else {
      // Si el SDK de FB no está cargado, simplemente continuamos
      resolve(true);
    }
  });
};

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
      try {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp && decoded.exp > currentTime) {
          return { 
            token, 
            user: { 
              ...decoded,
              campaigns: [],
              google_connected: decoded.google_connected || false,
              meta_connected: decoded.meta_connected || false,
              google_customer_id: decoded.google_customer_id || null,
              meta_ad_account_id: decoded.meta_ad_account_id || null
            } 
          };
        }
        // Si el token ha expirado, limpiamos todo
        localStorage.removeItem('access_token');
        sessionStorage.removeItem('auth_method');
        sessionStorage.removeItem('auth_state');
        localStorage.removeItem('facebook_auth_state');
      } catch (error) {
        console.error('Error decodificando el token JWT:', error);
        localStorage.removeItem('access_token');
        sessionStorage.removeItem('auth_method');
        sessionStorage.removeItem('auth_state');
        localStorage.removeItem('facebook_auth_state');
      }
    }
    return { token: null, user: null };
  });

  const [loading, setLoading] = useState(true);
  const validationTimeoutRef = useRef(null);
  const isLoadingRef = useRef(false);

  // Validación de token optimizada con control de concurrencia
  const validateToken = useCallback(async (token, force = false) => {
    if (!token) return false;
    
    const now = Date.now();
    const cacheKey = `token_validation_${token}`;

    // Si no es forzado y hay una validación en progreso, esperar
    if (!force && validationState.inProgress && validationState.validationPromise) {
      return validationState.validationPromise;
    }

    // Si no es forzado y hay una validación reciente en caché, usarla
    if (!force && validationState.lastValidation && 
        (now - validationState.lastValidation) < validationState.VALIDATION_INTERVAL) {
      const cachedValidation = cacheManager.get(cacheKey);
      if (cachedValidation !== undefined) {
        return cachedValidation;
      }
    }

    try {
      validationState.inProgress = true;
      validationState.validationPromise = axios.get('/api/validate-token', {
        headers: { Authorization: `Bearer ${token}` }
      });

      const response = await validationState.validationPromise;
      const isValid = response.data.valid;
      
      validationState.lastValidation = now;
      cacheManager.set(cacheKey, isValid);
      
      return isValid;
    } catch (error) {
      console.error('Error validando token:', error);
      return false;
    } finally {
      validationState.inProgress = false;
      validationState.validationPromise = null;
    }
  }, []);

  const logout = useCallback(async () => {
    if (isLoadingRef.current) return;
    
    try {
      isLoadingRef.current = true;
      
      // 1. Cerrar sesión en el backend
      if (auth.token) {
        try {
          await axios.post('/api/logout', {});
        } catch (error) {
          console.error('Error en el logout del backend:', error);
          // Continuamos con el proceso de logout aunque falle el backend
        }
      }
      
      // 2. Cerrar sesión de Facebook si estaba conectado
      const authMethod = sessionStorage.getItem('auth_method');
      if (authMethod === 'facebook' || (auth.user && auth.user.meta_connected)) {
        await logoutFromFacebook();
      }
      
      // 3. Limpiar Google Auth si estaba conectado
      if (authMethod === 'google' || (auth.user && auth.user.google_connected)) {
        // Utilizar Google Sign-In API para cerrar sesión si está disponible
        if (window.google && window.google.accounts && window.google.accounts.id) {
          window.google.accounts.id.disableAutoSelect();
        }
      }
      
      // 4. Limpiar token y datos locales
      localStorage.removeItem('access_token');
      sessionStorage.removeItem('auth_method');
      sessionStorage.removeItem('auth_state');
      localStorage.removeItem('facebook_auth_state');
      
      // 5. Limpiar caché y estado de aplicación
      cacheManager.clear();
      delete axios.defaults.headers.common['Authorization'];
      
      // 6. Actualizar estado React
      setAuth({ token: null, user: null });
      
      if (validationTimeoutRef.current) {
        clearTimeout(validationTimeoutRef.current);
      }
    } catch (error) {
      console.error('Error durante el logout:', error);
    } finally {
      isLoadingRef.current = false;
    }
  }, [auth.token, auth.user]);

  const login = useCallback(async (token, userData = null, method = null) => {
    if (isLoadingRef.current) return false;
    
    try {
      isLoadingRef.current = true;
      const isValid = await validateToken(token, true);
      if (!isValid) throw new Error('Token inválido');

      // Guardar el token y el método de autenticación
      localStorage.setItem('access_token', token);
      if (method) {
        sessionStorage.setItem('auth_method', method);
      }
      
      // Si es el mismo token, mantener los datos sensibles
      if (token === auth.token && method === 'existing') {
        console.log('Using existing token, preserving user data');
        
        // Solo actualizar los campos específicos que llegan en userData
        setAuth(prev => ({
          ...prev,
          user: { 
            ...prev.user,
            ...(userData || {})
          }
        }));
        
        return true;
      }
      
      // Para token nuevo, decodificar y configurar
      const decoded = jwtDecode(token);
      console.log('Decoded token:', decoded);
      
      // Configurar el estado con los datos del token y los específicos
      setAuth({ 
        token, 
        user: { 
          ...decoded,
          ...(userData || {}),
          // Asegurar que estos campos siempre existan
          google_connected: (userData?.google_connected || decoded.google_connected) || false,
          meta_connected: (userData?.meta_connected || decoded.meta_connected) || false,
          google_customer_id: userData?.google_customer_id || decoded.google_customer_id || null,
          meta_ad_account_id: userData?.meta_ad_account_id || decoded.meta_ad_account_id || null,
          meta_access_token: userData?.meta_access_token || null,
          campaigns: []
        }
      });

      // Configurar Axios con el nuevo token
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      console.log('Login success, token set');
      return true;
    } catch (error) {
      console.error('Error en login:', error);
      await logout();
      return false;
    } finally {
      isLoadingRef.current = false;
    }
  }, [auth.token, logout, validateToken]);

  const updateUser = useCallback(async (userData) => {
    if (isLoadingRef.current || !auth.token) return false;

    try {
      isLoadingRef.current = true;
      console.log('Updating user with:', userData);
      
      // Si userData es una función, ejecutarla con el usuario actual
      const dataToUpdate = typeof userData === 'function' 
        ? userData(auth.user) 
        : userData;
      
      console.log('Data after processing:', dataToUpdate);
      
      const response = await axios.put('/api/user', dataToUpdate, {
        headers: {
          'Authorization': `Bearer ${auth.token}`,
          'Content-Type': 'application/json'
        }
      });
      
      console.log('Update response:', response.data);
      
      if (response.data) {
        // Asegurarnos de mantener todos los datos existentes y solo actualizar los nuevos
        setAuth(prev => ({
          ...prev,
          user: { 
            ...prev.user, 
            ...response.data 
          }
        }));
        return response.data;
      }
      return false;
    } catch (error) {
      console.error('Error updating user:', error);
      // Si hay un error 415, es probable que sea por Content-Type
      if (error.response?.status === 415) {
        console.error('Content-Type error: Trying again with correct headers');
        try {
          const dataToUpdate = typeof userData === 'function' 
            ? userData(auth.user) 
            : userData;
            
          // Reintentar con el formato correcto
          const retryResponse = await axios.put('/api/user', dataToUpdate, {
            headers: {
              'Authorization': `Bearer ${auth.token}`,
              'Content-Type': 'application/json'
            }
          });
          
          if (retryResponse.data) {
            setAuth(prev => ({
              ...prev,
              user: { ...prev.user, ...retryResponse.data }
            }));
            return retryResponse.data;
          }
        } catch (retryError) {
          console.error('Retry failed:', retryError);
        }
      }
      // Si el error es 401, hacer logout
      if (error.response?.status === 401) {
        await logout();
      }
      return false;
    } finally {
      isLoadingRef.current = false;
    }
  }, [auth.token, auth.user, logout]);

  const fetchMetaData = useCallback(async () => {
    if (!auth.token || !auth.user?.meta_connected) {
      return null;
    }
    
    try {
      console.log('Fetching Meta account data');
      const response = await axios.get('/api/meta/accounts', {
        headers: { 
          'Authorization': `Bearer ${auth.token}`,
          'Content-Type': 'application/json'
        }
      });
      
      console.log('Meta accounts data:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching Meta data:', error);
      return null;
    }
  }, [auth.token, auth.user?.meta_connected]);

  // Configurar interceptores de Axios una sola vez
  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      config => {
        if (auth.token) {
          config.headers['Authorization'] = `Bearer ${auth.token}`;
        }
        return config;
      },
      error => Promise.reject(error)
    );

    const responseInterceptor = axios.interceptors.response.use(
      response => response,
      async error => {
        if (error.response?.status === 401 && auth.token) {
          // Si recibimos un 401, hacemos logout completo
          await logout();
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
      cacheManager.clear();
    };
  }, [auth.token, logout]);

  // Validación inicial optimizada
  useEffect(() => {
    const validateSession = async () => {
      if (!auth.token || isLoadingRef.current) {
        setLoading(false);
        return;
      }

      try {
        isLoadingRef.current = true;
        const isValid = await validateToken(auth.token);
        if (!isValid) {
          await logout();
        }
      } catch (error) {
        await logout();
      } finally {
        setLoading(false);
        isLoadingRef.current = false;
      }
    };

    validateSession();

    // Verificación periódica del token (cada 15 minutos)
    const tokenCheckInterval = setInterval(() => {
      if (auth.token) {
        validateSession();
      }
    }, 15 * 60 * 1000);

    return () => {
      if (validationTimeoutRef.current) {
        clearTimeout(validationTimeoutRef.current);
      }
      clearInterval(tokenCheckInterval);
      isLoadingRef.current = false;
    };
  }, [auth.token, logout, validateToken]);

  if (loading) return <LoadingScreen />;

  const checkUserSubscription = async () => {
    try {
      const response = await axios.get('/api/subscriptions/status');
      return response.data;
    } catch (error) {
      console.error('Error checking subscription status:', error);
      return { is_free_trial: true };  // Asumimos que es prueba gratuita si hay un error
    }
  };
  
  return (
    <AuthContext.Provider
      value={{
        auth,
        login,
        logout,
        updateUser,
        validateToken,
        fetchMetaData,
        isAuthenticated: !!auth.token,
        checkUserSubscription,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth debe ser usado dentro de un AuthProvider');
  }
  return context;
};

// Configuración global de Axios
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common['Content-Type'] = 'application/json';