import React, { useState, useEffect, useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { 
  Users, 
  Edit, 
  Trash2, 
  Search, 
  Plus, 
  LayoutGrid, 
  CreditCard, 
  LogOut,
  MessageSquare,
  Filter,
  ChevronDown,
  ChevronUp,
  RefreshCw
} from 'lucide-react';

const UserManagement = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [subscriptionFilter, setSubscriptionFilter] = useState('all');
  const [selectedUser, setSelectedUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isChatModalOpen, setIsChatModalOpen] = useState(false);
  const [userChatSessions, setUserChatSessions] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [loadingChats, setLoadingChats] = useState(false);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [showThinking, setShowThinking] = useState({});
  // Estados para ordenamiento
  const [sortField, setSortField] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');

  const menuItems = [
    { 
      icon: LayoutGrid, 
      label: 'Dashboard', 
      path: '/admin-espor.ai', 
    },
    { 
      icon: Users, 
      label: 'Usuarios', 
      path: '/admin-espor.ai/users', 
    },
    { 
      icon: CreditCard, 
      label: 'Suscripciones', 
      path: '/admin-espor.ai/subscriptions', 
    },
  ];
  const toggleThinking = (messageIndex) => {
    setShowThinking(prev => ({
      ...prev,
      [messageIndex]: !prev[messageIndex]
    }));
  };
  const handleLogout = () => {
    localStorage.removeItem('adminToken');
    navigate('/admin-espor.ai/login');
  };

  // Fetch all subscriptions separately
  const fetchSubscriptions = async () => {
    try {
      const token = localStorage.getItem('adminToken');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/subscriptions`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Error fetching subscriptions');
      }

      const data = await response.json();
      console.log('Subscriptions data from API:', data); // Debug log
      setSubscriptions(Array.isArray(data) ? data : []);
      return data;
    } catch (err) {
      console.error('Fetch subscriptions error:', err);
      return [];
    }
  };

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('adminToken');
      
      // Fetch users
      const userResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/users`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
  
      if (!userResponse.ok) {
        throw new Error('Error fetching users');
      }
      
      const userData = await userResponse.json();
      console.log('User data from API:', userData); // Debug log
      
      // Extract user data properly from the API response
      const extractedUsers = userData.users || (Array.isArray(userData) ? userData : []);
      
      // Si hay suscripciones en la respuesta, las guardamos directamente
      let allSubscriptions = [];
      if (userData.subscriptions && Array.isArray(userData.subscriptions)) {
        setSubscriptions(userData.subscriptions);
        allSubscriptions = userData.subscriptions;
      } else {
        // Si no hay suscripciones en la respuesta, intentamos obtenerlas por separado
        allSubscriptions = await fetchSubscriptions();
      }
      
      // Get the latest subscription for each user
      const userToSubscriptionMap = {};
      
      // Organize subscriptions by user and sort by date
      const userSubscriptionsMap = {};
      allSubscriptions.forEach(sub => {
        if (!userSubscriptionsMap[sub.user_id]) {
          userSubscriptionsMap[sub.user_id] = [];
        }
        userSubscriptionsMap[sub.user_id].push(sub);
      });
      
      // For each user, find their latest active subscription
      Object.keys(userSubscriptionsMap).forEach(userId => {
        const userSubs = userSubscriptionsMap[userId];
        
        // Sort by created_at date, newest first
        userSubs.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        
        // Prioritize approved subscriptions
        const approvedSubs = userSubs.filter(sub => sub.status === 'approved');
        
        // If there are approved subscriptions, take the most recent
        if (approvedSubs.length > 0) {
          userToSubscriptionMap[userId] = approvedSubs[0];
        } else {
          // If no approved subscriptions, take the most recent
          userToSubscriptionMap[userId] = userSubs[0];
        }
      });
      
      const mappedUsers = extractedUsers.map(user => {
        // Get user's subscription info
        const activeSub = userToSubscriptionMap[user.id];
      
        // Use backend values directly without mapping
        const subscriptionPlan = activeSub?.plan_name || activeSub?.plan_id || user.subscription_type || 'free_trial';
        const subscriptionStatus = activeSub?.status || user.subscription_status || 'pending';
      
        // Determine user status
        let userStatus = 'inactive';
        if (user.is_admin === true || user.is_admin === 'TRUE') {
          userStatus = 'admin';
        } else if (subscriptionStatus === 'approved') {
          userStatus = 'active';
        } else if (subscriptionStatus === 'banned') {
          userStatus = 'banned';
        }
      
        // Asegurarse de que el ID sea un número si es posible
        const userId = typeof user.id === 'string' && !isNaN(parseInt(user.id)) 
          ? parseInt(user.id, 10) 
          : user.id;
        
        return {
          id: userId,
          name: user.name || `${user.first_name || ''} ${user.last_name || ''}`.trim(),
          email: user.email,
          subscription_plan: subscriptionPlan,
          subscription_status: subscriptionStatus,
          status: userStatus,
          last_login: user.onboarding_completed_at || null
        };
      });
      
      // Log the mapped users for debugging
      console.log('Mapped users with subscriptions:', mappedUsers);
      
      setUsers(Array.isArray(mappedUsers) ? mappedUsers : []);
    } catch (err) {
      console.error('Fetch users error:', err);
      setError(err.message);
      setUsers([]);
    } finally {
      setLoading(false);
    }
  };
  const fetchUserChatSessions = async (userId) => {
    try {
      setLoadingChats(true);
      
      // Asegúrate de que userId es un número
      if (typeof userId !== 'number' || isNaN(userId) || userId <= 0) {
        console.error("ID de usuario inválido:", userId);
        throw new Error('ID de usuario inválido');
      }
      
      console.log("Obteniendo chats para el ID:", userId);
      
      const token = localStorage.getItem('adminToken');
      let chatSessions = [];
      let analysisSessions = [];
      
      // Obtener sesiones de chat
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/users/${userId}/chat-sessions`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
  
        if (response.ok) {
          const data = await response.json();
          console.log('Respuesta de sesiones de chat:', data);
          chatSessions = Array.isArray(data) ? data.map(session => ({
            ...session,
            type: 'chat'
          })) : [];
        } else {
          console.warn('Error al obtener sesiones de chat:', response.status);
        }
      } catch (chatError) {
        console.error('Error al obtener sesiones de chat:', chatError);
      }
      
      // Obtener sesiones de análisis (independientemente de si las sesiones de chat se cargaron con éxito)
      try {
        const analysisResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/users/${userId}/analysis-sessions`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
  
        if (analysisResponse.ok) {
          const analysisData = await analysisResponse.json();
          console.log('Respuesta de sesiones de análisis:', analysisData);
          
          analysisSessions = Array.isArray(analysisData) ? analysisData.map(session => ({
            ...session,
            type: 'analysis',
            title: session.title || 'Análisis de datos',
            last_message: session.query || 'Consulta de análisis',
            updated_at: session.timestamp || new Date().toISOString()
          })) : [];
        } else {
          console.warn('Error al obtener sesiones de análisis:', analysisResponse.status);
        }
      } catch (analysisError) {
        console.error('Error al obtener sesiones de análisis:', analysisError);
      }
      
      // Combinar todas las sesiones, incluso si alguna parte falló
      const allSessions = [...chatSessions, ...analysisSessions];
      
      // Ordenar por fecha más reciente
      allSessions.sort((a, b) => {
        const dateA = new Date(a.updated_at || a.timestamp || 0);
        const dateB = new Date(b.updated_at || b.timestamp || 0);
        return dateB - dateA;
      });
      
      console.log('Sesiones combinadas:', allSessions);
      
      setUserChatSessions(allSessions);
      setSelectedChat(null);
      setChatMessages([]);
    } catch (err) {
      console.error('Error general al obtener sesiones:', err);
      setError('Error al cargar sesiones de chat: ' + err.message);
      setUserChatSessions([]);
    } finally {
      setLoadingChats(false);
    }
  };
  const fetchChatMessages = async (sessionId, sessionType = 'chat') => {
    try {
      setLoadingMessages(true);
      const token = localStorage.getItem('adminToken');
      
      // URL diferente según el tipo de sesión
      const url = sessionType === 'analysis' 
        ? `${process.env.REACT_APP_API_URL}/api/admin/analysis-sessions/${sessionId}`
        : `${process.env.REACT_APP_API_URL}/api/admin/chat-sessions/${sessionId}`;
        
      console.log(`Obteniendo mensajes de ${sessionType} para sesión ${sessionId} desde ${url}`);
        
      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
  
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(`Error fetching ${sessionType} messages: ${response.status} - ${errorData.error || 'Unknown error'}`);
      }
  
      const data = await response.json();
      console.log(`Session ${sessionType} messages data:`, data);
      
      // Formato diferente según el tipo de sesión
      if (sessionType === 'analysis') {
        try {
          // Para sesiones de análisis, crear mensajes de usuario y asistente
          const messages = [
            {
              id: `${sessionId}-user`,
              role: 'user',
              content: data.query || "Consulta de análisis",
              timestamp: data.timestamp,
              type: 'analysis'
            },
            {
              id: `${sessionId}-assistant`,
              role: 'assistant',
              content: data.response || "Respuesta no disponible",
              timestamp: data.timestamp,
              type: 'analysis',
              user_message: data.query, // Referencia al mensaje original
              metadata: data.metadata
            }
          ];
          setChatMessages(messages);
        } catch (formatError) {
          console.error('Error al formatear mensajes de análisis:', formatError);
          setChatMessages([
            {
              id: 'error',
              role: 'system',
              content: `Error al cargar mensajes de análisis: ${formatError.message}. Datos recibidos: ${JSON.stringify(data, null, 2)}`,
              timestamp: new Date().toISOString()
            }
          ]);
        }
      } else {
        // Para sesiones regulares de chat
        if (Array.isArray(data.messages)) {
          setChatMessages(data.messages);
        } else {
          console.warn('Formato de mensajes inesperado:', data);
          setChatMessages([
            {
              id: 'warning',
              role: 'system',
              content: 'Formato de mensajes inesperado o mensajes no disponibles',
              timestamp: new Date().toISOString()
            }
          ]);
        }
      }
    } catch (err) {
      console.error(`Fetch ${sessionType} messages error:`, err);
      setChatMessages([
        {
          id: 'error',
          role: 'system',
          content: `Error al cargar mensajes: ${err.message}`,
          timestamp: new Date().toISOString()
        }
      ]);
    } finally {
      setLoadingMessages(false);
    }
  };
  useEffect(() => {
    fetchUsers();
  }, []);
  const fetchUserAnalysisSessions = async (userId) => {
    try {
      setLoadingChats(true);
      
      // Asegúrate de que userId es un número
      if (typeof userId !== 'number' || isNaN(userId) || userId <= 0) {
        console.error("ID de usuario inválido:", userId);
        throw new Error('ID de usuario inválido');
      }
      
      console.log("Obteniendo análisis para el ID:", userId);
      
      const token = localStorage.getItem('adminToken');
      
      // Usar el nuevo endpoint específico para análisis
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/users/${userId}/only-analysis-sessions`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
  
      if (!response.ok) {
        throw new Error(`Error fetching analysis sessions: ${response.status}`);
      }
  
      const data = await response.json();
      console.log('Respuesta de sesiones de análisis:', data);
      
      // Setear directamente las sesiones de análisis
      setUserChatSessions(Array.isArray(data) ? data : []);
      setSelectedChat(null);
      setChatMessages([]);
      
      // Abrir el modal automáticamente
      setIsChatModalOpen(true);
      
    } catch (err) {
      console.error('Error al obtener sesiones de análisis:', err);
      setError('Error al cargar sesiones de análisis: ' + err.message);
      setUserChatSessions([]);
    } finally {
      setLoadingChats(false);
    }
  };
  
  // Función para ordenar usuarios
  const handleSort = (field) => {
    if (field === sortField) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const filteredUsers = useMemo(() => {
    // Ensure users is always an array
    const safeUsers = Array.isArray(users) ? users : [];

    return safeUsers.filter(user => {
      // Additional safety checks
      if (!user || typeof user !== 'object') return false;

      const matchesSearch = 
        (user.name && user.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (user.email && user.email.toLowerCase().includes(searchTerm.toLowerCase()));
      
      const matchesStatus = statusFilter === 'all' || user.status === statusFilter;
      const matchesSubscription = subscriptionFilter === 'all' || user.subscription_plan === subscriptionFilter;

      return matchesSearch && matchesStatus && matchesSubscription;
    });
  }, [users, searchTerm, statusFilter, subscriptionFilter]);

  // Aplicar ordenamiento a los usuarios filtrados
  const sortedUsers = useMemo(() => {
    return [...filteredUsers].sort((a, b) => {
      let comparison = 0;
      
      if (sortField === 'name') {
        comparison = a.name.localeCompare(b.name);
      } else if (sortField === 'email') {
        comparison = a.email.localeCompare(b.email);
      } else if (sortField === 'subscription_plan') {
        comparison = a.subscription_plan.localeCompare(b.subscription_plan);
      } else if (sortField === 'status') {
        comparison = a.status.localeCompare(b.status);
      } else if (sortField === 'last_login') {
        const dateA = a.last_login ? new Date(a.last_login) : new Date(0);
        const dateB = b.last_login ? new Date(b.last_login) : new Date(0);
        comparison = dateA - dateB;
      }
      
      return sortDirection === 'asc' ? comparison : -comparison;
    });
  }, [filteredUsers, sortField, sortDirection]);

  // Modal para editar usuario
  const EditUserModal = ({ user, onClose, onSave }) => {
    const [userData, setUserData] = useState({ ...user });

    const handleSave = async () => {
      try {
        const token = localStorage.getItem('adminToken');
        
        // Map frontend fields to backend fields - use plan directly
        const backendData = {
          name: userData.name,
          email: userData.email,
          is_admin: userData.status === 'admin' ? true : false,
          // Add subscription data
          subscription_type: userData.subscription_plan,
          subscription_status: userData.status === 'active' ? 'approved' : 
                              userData.status === 'banned' ? 'banned' : 'pending'
        };
        
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/users/${user.id}`, {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(backendData)
        });
    
        if (!response.ok) {
          throw new Error('Error updating user');
        }
    
        // After updating the user, we need to update their subscription if needed
        if (userData.subscription_plan !== user.subscription_plan) {
          const subscriptionData = {
            user_id: user.id,
            plan_name: userData.subscription_plan,
            status: 'approved'
          };
          
          await fetch(`${process.env.REACT_APP_API_URL}/api/admin/subscriptions`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(subscriptionData)
          });
        }
    
        // Update user locally
        setUsers(users.map(u => u.id === user.id ? { ...u, ...userData } : u));
        onClose();
        
        // Refresh user list to get updated data
        fetchUsers();
      } catch (err) {
        setError(err.message);
      }
    };

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white rounded-xl p-8 w-96">
          <h2 className="text-xl font-bold mb-6">Editar Usuario</h2>
          
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Nombre</label>
              <input
                type="text"
                value={userData.name}
                onChange={(e) => setUserData({ ...userData, name: e.target.value })}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3"
              />
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                value={userData.email}
                onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3"
              />
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700">Plan de Suscripción</label>
              <select
                value={userData.subscription_plan}
                onChange={(e) => setUserData({ ...userData, subscription_plan: e.target.value })}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3"
              >
                <option value="free_trial">Free Trial</option>
                <option value="performance">Performance</option>
                <option value="enterprise">Enterprise</option>
              </select>
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700">Estado</label>
              <select
                value={userData.status}
                onChange={(e) => setUserData({ ...userData, status: e.target.value })}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3"
              >
                <option value="active">Activo</option>
                <option value="inactive">Inactivo</option>
                <option value="banned">Baneado</option>
                <option value="admin">Administrador</option>
              </select>
            </div>
          </div>
          
          <div className="flex justify-end space-x-4 mt-8">
            <button 
              onClick={onClose}
              className="px-4 py-2 bg-gray-100 text-gray-900 rounded-lg hover:bg-gray-200"
            >
              Cancelar
            </button>
            <button 
              onClick={handleSave}
              className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
            >
              Guardar Cambios
            </button>
          </div>
        </div>
      </div>
    );
  };

  // Modal para crear usuario
  const CreateUserModal = ({ onClose }) => {
    const [newUser, setNewUser] = useState({
      name: '',
      email: '',
      subscription_plan: 'free_trial',
      status: 'active'
    });

    const handleCreate = async () => {
      try {
        const token = localStorage.getItem('adminToken');
        
        // Map frontend fields to backend fields
        const backendData = {
          name: newUser.name,
          email: newUser.email,
          is_admin: newUser.status === 'admin',
          // Split name into first/last if possible
          first_name: newUser.name.split(' ')[0] || '',
          last_name: newUser.name.split(' ').slice(1).join(' ') || '',
          onboarding_completed: true
        };
        
        // Create the user first
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/users`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(backendData)
        });
    
        if (!response.ok) {
          throw new Error('Error creating user');
        }
    
        const createdUser = await response.json();
        
        // Crear suscripción para el nuevo usuario
        const subscriptionData = {
          user_id: createdUser.id,
          plan_name: newUser.subscription_plan,
          status: 'approved'
        };
        
        await fetch(`${process.env.REACT_APP_API_URL}/api/admin/subscriptions`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(subscriptionData)
        });
        
        onClose();
        
        // Refresh user list to include the new user with subscription
        fetchUsers();
      } catch (err) {
        setError(err.message);
      }
    };
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white rounded-xl p-8 w-96">
          <h2 className="text-xl font-bold mb-6">Crear Nuevo Usuario</h2>
          
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Nombre</label>
              <input
                type="text"
                value={newUser.name}
                onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3"
              />
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                value={newUser.email}
                onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3"
              />
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700">Plan de Suscripción</label>
              <select
                value={newUser.subscription_plan}
                onChange={(e) => setNewUser({ ...newUser, subscription_plan: e.target.value })}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3"
              >
                <option value="free_trial">Free Trial</option>
                <option value="performance">Performance</option>
                <option value="enterprise">Enterprise</option>
              </select>
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700">Estado</label>
              <select
                value={newUser.status}
                onChange={(e) => setNewUser({ ...newUser, status: e.target.value })}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3"
              >
                <option value="active">Activo</option>
                <option value="inactive">Inactivo</option>
                <option value="banned">Baneado</option>
                <option value="admin">Administrador</option>
              </select>
            </div>
          </div>
          
          <div className="flex justify-end space-x-4 mt-8">
            <button 
              onClick={onClose}
              className="px-4 py-2 bg-gray-100 text-gray-900 rounded-lg hover:bg-gray-200"
            >
              Cancelar
            </button>
            <button 
              onClick={handleCreate}
              className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
            >
              Crear Usuario
            </button>
          </div>
        </div>
      </div>
    );
  };

  const ChatSessionsModal = ({ user, sessions, onClose }) => {
    const [sessionTypeFilter, setSessionTypeFilter] = useState('all'); // Nuevo estado para el filtro
    const [analysisTasks, setAnalysisTasks] = useState({});
  
    // Filtrar sesiones según el tipo seleccionado
    const filteredSessions = useMemo(() => {
      if (sessionTypeFilter === 'all') return sessions;
      return sessions.filter(s => s.type === sessionTypeFilter);
    }, [sessions, sessionTypeFilter]);
  
    // Función para cargar los mensajes de análisis
    const fetchAnalysisMessages = async (sessionId) => {
      try {
        setLoadingMessages(true);
        const token = localStorage.getItem('adminToken');
        
        const url = `${process.env.REACT_APP_API_URL}/api/admin/analysis-sessions/${sessionId}/messages`;
        
        console.log(`Obteniendo mensajes de análisis para sesión ${sessionId} desde ${url}`);
          
        const response = await fetch(url, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
    
        if (!response.ok) {
          const errorData = await response.json().catch(() => ({}));
          throw new Error(`Error fetching analysis messages: ${response.status} - ${errorData.error || 'Unknown error'}`);
        }
    
        const data = await response.json();
        console.log(`Analysis messages data:`, data);
        
        if (Array.isArray(data)) {
          // Formatear los mensajes para mostrarlos
          const formattedMessages = data.map(msg => ({
            id: msg.id,
            role: msg.role,
            content: msg.content,
            timestamp: msg.created_at,
            type: 'analysis',
            thinking: msg.thinking,
            metadata: msg.metadata || {}
          }));
          
          setChatMessages(formattedMessages);
        } else {
          // Si no hay un array de mensajes, crear mensajes a partir de query y response
          const session = data;
          const messages = [
            {
              id: `${sessionId}-user`,
              role: 'user',
              content: session.query || "Consulta de análisis",
              timestamp: session.created_at,
              type: 'analysis'
            },
            {
              id: `${sessionId}-assistant`,
              role: 'assistant',
              content: session.response || "Respuesta no disponible",
              timestamp: session.updated_at,
              type: 'analysis',
              thinking: session.thinking,
              user_message: session.query,
              metadata: {
                platform_used: session.platform_used,
                thinking: session.thinking
              }
            }
          ];
          setChatMessages(messages);
        }
      } catch (err) {
        console.error(`Fetch analysis messages error:`, err);
        setChatMessages([
          {
            id: 'error',
            role: 'system',
            content: `Error al cargar mensajes de análisis: ${err.message}`,
            timestamp: new Date().toISOString()
          }
        ]);
      } finally {
        setLoadingMessages(false);
      }
    };
  
    // Actualizar fetchChatMessages para manejar mensajes de análisis
    const handleFetchMessages = (sessionId, sessionType) => {
      setSelectedChat({...sessions.find(s => s.id === sessionId), type: sessionType});
      
      if (sessionType === 'analysis') {
        fetchAnalysisMessages(sessionId);
      } else {
        fetchChatMessages(sessionId, 'chat');
      }
    };
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white rounded-xl p-8 w-3/4 h-3/4 flex flex-col">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-bold">Sesiones de Chat y Análisis - {user.name}</h2>
            
            {/* Botones de filtro */}
            <div className="flex space-x-2">
              <button 
                onClick={() => setSessionTypeFilter('all')}
                className={`px-3 py-1 rounded-lg text-sm font-medium transition-colors ${
                  sessionTypeFilter === 'all' 
                    ? 'bg-gray-800 text-white' 
                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                }`}
              >
                Todos
              </button>
              <button 
                onClick={() => setSessionTypeFilter('chat')}
                className={`px-3 py-1 rounded-lg text-sm font-medium transition-colors ${
                  sessionTypeFilter === 'chat' 
                    ? 'bg-purple-600 text-white' 
                    : 'bg-purple-100 text-purple-700 hover:bg-purple-200'
                }`}
              >
                <MessageSquare className="w-3 h-3 inline mr-1" />
                Solo Chat
              </button>
              <button 
                onClick={() => setSessionTypeFilter('analysis')}
                className={`px-3 py-1 rounded-lg text-sm font-medium transition-colors ${
                  sessionTypeFilter === 'analysis' 
                    ? 'bg-blue-600 text-white' 
                    : 'bg-blue-100 text-blue-700 hover:bg-blue-200'
                }`}
              >
                <Filter className="w-3 h-3 inline mr-1" />
                Solo Análisis
              </button>
            </div>
          </div>
          
          <div className="flex flex-1 space-x-4 overflow-hidden">
            {/* Lista de sesiones */}
            <div className="w-1/3 overflow-y-auto border rounded-lg p-2">
              {loadingChats ? (
                <div className="flex justify-center items-center h-full">
                  <RefreshCw className="h-6 w-6 text-purple-600 animate-spin" />
                  <span className="ml-2 text-purple-600">Cargando sesiones...</span>
                </div>
              ) : filteredSessions.length === 0 ? (
                <p className="text-gray-500 text-center py-8">
                  {sessionTypeFilter === 'analysis' 
                    ? 'No hay sesiones de análisis' 
                    : sessionTypeFilter === 'chat'
                      ? 'No hay sesiones de chat'
                      : 'No hay sesiones recientes'}
                </p>
              ) : (
                <div className="space-y-2">
                  {/* Separador para sesiones de análisis (solo si hay y si no estamos filtrando) */}
                  {sessionTypeFilter !== 'chat' && filteredSessions.some(s => s.type === 'analysis') && (
                    <div className="px-2 py-1 bg-blue-50 text-blue-600 text-xs font-medium rounded mb-2">
                      Sesiones de Análisis de Datos
                    </div>
                  )}
                  
                  {/* Mostrar primero las sesiones de análisis */}
                  {filteredSessions.filter(s => s.type === 'analysis').map(session => (
                    <div 
                      key={`analysis-${session.id}`} 
                      className={`border rounded-lg p-3 cursor-pointer hover:bg-gray-50 transition-colors ${
                        selectedChat?.id === session.id && selectedChat?.type === 'analysis' 
                          ? 'bg-blue-50 border-blue-200' 
                          : 'border-blue-100'
                      }`}
                      onClick={() => handleFetchMessages(session.id, 'analysis')}
                    >
                      <div className="flex justify-between items-center mb-1">
                        <h3 className="font-medium text-sm flex items-center">
                          <Filter className="w-4 h-4 mr-1 text-blue-500" />
                          {session.title || "Análisis de datos"}
                        </h3>
                        <span className="text-xs text-gray-500">
                          {new Date(session.updated_at || session.timestamp || session.created_at).toLocaleDateString()}
                        </span>
                      </div>
                      <p className="text-xs text-gray-600 truncate">
                        {session.query || session.last_message || "Sin mensajes"}
                      </p>
                      <div className="flex justify-between mt-1">
                        <span className="text-xs text-blue-500 block">
                          Análisis de datos
                        </span>
                        {session.messages_count && (
                          <span className="text-xs text-gray-500">
                            {session.messages_count} mensajes
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
                  
                  {/* Separador para sesiones regulares si hay ambos tipos */}
                  {sessionTypeFilter === 'all' && filteredSessions.some(s => s.type === 'analysis') && filteredSessions.some(s => s.type === 'chat') && (
                    <div className="px-2 py-1 bg-gray-50 text-gray-600 text-xs font-medium rounded my-3">
                      Sesiones de Chat
                    </div>
                  )}
                  
                  {/* Mostrar sesiones regulares */}
                  {filteredSessions.filter(s => s.type !== 'analysis').map(session => (
                    <div 
                      key={`chat-${session.id}`} 
                      className={`border rounded-lg p-3 cursor-pointer hover:bg-gray-50 transition-colors ${
                        selectedChat?.id === session.id && selectedChat?.type !== 'analysis'
                          ? 'bg-purple-50 border-purple-200' 
                          : ''
                      }`}
                      onClick={() => handleFetchMessages(session.id, 'chat')}
                    >
                      <div className="flex justify-between items-center mb-1">
                        <h3 className="font-medium text-sm flex items-center">
                          <MessageSquare className="w-4 h-4 mr-1 text-gray-500" />
                          {session.title || "Sin título"}
                        </h3>
                        <span className="text-xs text-gray-500">
                          {new Date(session.updated_at || session.created_at).toLocaleDateString()}
                        </span>
                      </div>
                      <p className="text-xs text-gray-600 truncate">{session.last_message || "Sin mensajes"}</p>
                      <span className="text-xs text-gray-500 mt-1 block">{session.messages_count} mensajes</span>
                    </div>
                  ))}
                </div>
              )}
            </div>
            
            {/* Panel de mensajes */}
            <div className="w-2/3 border rounded-lg p-2 flex flex-col overflow-hidden">
              {!selectedChat ? (
                <div className="flex-1 flex items-center justify-center">
                  <p className="text-gray-500">Selecciona una sesión para ver los mensajes</p>
                </div>
              ) : loadingMessages ? (
                <div className="flex-1 flex items-center justify-center">
                  <div className="animate-spin rounded-full h-10 w-10 border-4 border-purple-500 border-t-transparent"></div>
                </div>
              ) : (
                <>
                  <div className={`p-3 rounded-lg mb-2 ${
                    selectedChat.type === 'analysis' ? 'bg-blue-50' : 'bg-gray-100'
                  }`}>
                    <h3 className="font-bold flex items-center">
                      {selectedChat.type === 'analysis' ? (
                        <>
                          <Filter className="w-5 h-5 mr-2 text-blue-500" />
                          <span className="text-blue-700">{selectedChat.title || "Análisis de Datos"}</span>
                        </>
                      ) : (
                        <>
                          <MessageSquare className="w-5 h-5 mr-2 text-gray-500" />
                          <span>{selectedChat.title || "Sin título"}</span>
                        </>
                      )}
                    </h3>
                    <p className="text-xs text-gray-500">
                      Creado: {new Date(selectedChat.created_at || selectedChat.timestamp || selectedChat.updated_at).toLocaleString()}
                    </p>
                    {selectedChat.type === 'analysis' && selectedChat.platform_used && (
                      <div className="mt-2 text-xs text-blue-600">
                        <span className="font-semibold">Plataformas: </span>
                        {selectedChat.platform_used}
                      </div>
                    )}
                  </div>
                  
                  <div className="flex-1 overflow-y-auto p-2 space-y-3">
                    {chatMessages.length === 0 ? (
                      <p className="text-center text-gray-500 py-4">No hay mensajes en esta sesión</p>
                    ) : (
                      chatMessages.map((message, index) => (
                        <div 
                          key={message.id || index} 
                          className={`p-3 rounded-lg ${
                            message.role === 'user' 
                              ? 'bg-blue-100 ml-auto max-w-3/4' 
                              : message.role === 'assistant' && message.type === 'analysis'
                                ? 'bg-indigo-100 mr-auto max-w-3/4' 
                                : message.role === 'system'
                                  ? 'bg-yellow-100 w-full'
                                  : 'bg-gray-100 mr-auto max-w-3/4'
                          }`}
                        >
                          <div className="flex justify-between mb-1">
                            <span className={`text-xs font-semibold ${
                              message.type === 'analysis' ? 'text-indigo-700' : ''
                            }`}>
                              {message.role === 'user' 
                                ? 'Usuario' 
                                : message.role === 'assistant' && message.type === 'analysis'
                                  ? 'Análisis IA' 
                                  : message.role === 'system'
                                    ? 'Sistema'
                                    : 'Asistente'
                              }
                            </span>
                            {message.timestamp && (
                              <span className="text-xs text-gray-500">
                                {new Date(message.timestamp).toLocaleTimeString()}
                              </span>
                            )}
                          </div>
                          <p className={`text-sm whitespace-pre-wrap ${
                            message.type === 'analysis' ? 'text-indigo-900' : ''
                          }`}>
                            {message.content}
                          </p>
                          
                          {/* Si es un mensaje de análisis, mostrar la pregunta original */}
                          {message.type === 'analysis' && message.user_message && (
                            <div className="mt-2 pt-2 border-t border-indigo-200">
                              <p className="text-xs text-indigo-800">
                                <span className="font-semibold">Consulta:</span> {message.user_message}
                              </p>
                            </div>
                          )}
                          
                          {/* Mostrar el pensamiento si está disponible */}
                          {(message.role === 'assistant' && (message.thinking || (message.metadata && message.metadata.thinking))) && (
                            <div className="mt-3">
                              <button
                                onClick={() => toggleThinking(index)}
                                className="text-xs text-indigo-700 flex items-center"
                              >
                                {showThinking[index] ? (
                                  <>Ocultar proceso de análisis <ChevronUp className="w-3 h-3 ml-1" /></>
                                ) : (
                                  <>Ver proceso de análisis <ChevronDown className="w-3 h-3 ml-1" /></>
                                )}
                              </button>
                              
                              {showThinking[index] && (
                                <div className="mt-2 p-2 bg-indigo-50 rounded-md text-xs text-indigo-900 overflow-auto max-h-64">
                                  <pre className="whitespace-pre-wrap font-mono text-xs">
                                    {message.thinking || (message.metadata && message.metadata.thinking) || "No hay proceso de análisis disponible"}
                                  </pre>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      ))
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          
          <div className="flex justify-end mt-6">
            <button 
              onClick={onClose}
              className="px-4 py-2 bg-gray-100 text-gray-900 rounded-lg hover:bg-gray-200"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    );
  };
  // Eliminar usuario
  const handleDeleteUser = async (userId) => {
    try {
      const token = localStorage.getItem('adminToken');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/users/${userId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Error deleting user');
      }

      // Eliminar usuario local
      setUsers(users.filter(u => u.id !== userId));
    } catch (err) {
      console.error('Error deleting user:', err);
      setError(err.message);
    }
  };

  // Función para generar el color de estado
  const getStatusColor = (status) => {
    switch (status) {
      case 'active':
        return 'bg-green-100 text-green-800';
      case 'inactive':
        return 'bg-yellow-100 text-yellow-800';
      case 'banned':
        return 'bg-red-100 text-red-800';
      case 'admin':
        return 'bg-purple-100 text-purple-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  // Función para generar el texto de estado
  const getStatusText = (status) => {
    switch (status) {
      case 'active':
        return 'Activo';
      case 'inactive':
        return 'Inactivo';
      case 'banned':
        return 'Baneado';
      case 'admin':
        return 'Admin';
      default:
        return 'Desconocido';
    }
  };

  // Función para generar el color de suscripción
  const getSubscriptionColor = (plan) => {
    switch (plan) {
      case 'enterprise':
        return 'bg-purple-100 text-purple-800';
      case 'performance':
        return 'bg-blue-100 text-blue-800';
      case 'free_trial':
        return 'bg-gray-100 text-gray-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };
  
  // Función para dar formato al texto del plan
  const getSubscriptionText = (plan) => {
    return plan || 'Desconocido';
  };

  // Si está cargando
  if (loading) {
    return (
      <div className="flex h-screen">
        {/* Sidebar */}
        <div className="w-64 bg-white border-r shadow-sm flex flex-col">
          <div className="p-6 border-b">
            <h2 className="text-2xl font-bold text-gray-800">Admin Panel</h2>
            <p className="text-sm text-gray-500">Espor.ai</p>
          </div>

          <nav className="flex-1 p-4">
            {menuItems.map((item) => (
              <Link 
                key={item.path} 
                to={item.path} 
                className={`
                  flex items-center p-3 rounded-lg mb-2 transition-colors 
                  ${location.pathname === item.path 
                    ? 'bg-purple-100 text-purple-600' 
                    : 'text-gray-600 hover:bg-gray-100 hover:text-gray-900'
                  }
                `}
              >
                <item.icon className="w-5 h-5 mr-3" />
                <span className="text-sm font-medium">{item.label}</span>
              </Link>
            ))}
          </nav>

          <div className="p-4 border-t">
            <button 
              onClick={handleLogout}
              className="w-full flex items-center justify-center p-3 text-red-600 hover:bg-red-50 rounded-lg transition-colors"
            >
              <LogOut className="w-5 h-5 mr-3" />
              <span className="text-sm font-medium">Cerrar Sesión</span>
            </button>
          </div>
        </div>

        <div className="flex-1 flex flex-col justify-center items-center p-6">
          <div className="w-16 h-16 border-4 border-purple-500 border-t-transparent rounded-full animate-spin mb-4"></div>
          <p className="text-gray-600">Cargando usuarios...</p>
        </div>
      </div>
    );
  }

  // Si hay error
  if (error && users.length === 0) {
    return (
      <div className="flex h-screen">
        {/* Sidebar */}
        <div className="w-64 bg-white border-r shadow-sm flex flex-col">
          <div className="p-6 border-b">
            <h2 className="text-2xl font-bold text-gray-800">Admin Panel</h2>
            <p className="text-sm text-gray-500">Espor.ai</p>
          </div>

          <nav className="flex-1 p-4">
            {menuItems.map((item) => (
              <Link 
                key={item.path} 
                to={item.path} 
                className={`
                  flex items-center p-3 rounded-lg mb-2 transition-colors 
                  ${location.pathname === item.path 
                    ? 'bg-purple-100 text-purple-600' 
                    : 'text-gray-600 hover:bg-gray-100 hover:text-gray-900'
                  }
                `}
              >
                <item.icon className="w-5 h-5 mr-3" />
                <span className="text-sm font-medium">{item.label}</span>
              </Link>
            ))}
          </nav>

          <div className="p-4 border-t">
            <button 
              onClick={handleLogout}
              className="w-full flex items-center justify-center p-3 text-red-600 hover:bg-red-50 rounded-lg transition-colors"
            >
              <LogOut className="w-5 h-5 mr-3" />
              <span className="text-sm font-medium">Cerrar Sesión</span>
            </button>
          </div>
        </div>

        <div className="flex-1 flex flex-col justify-center items-center p-6">
          <div className="bg-red-50 border border-red-200 rounded-xl p-6 max-w-md w-full">
            <p className="text-red-700 mb-4">{error}</p>
            <button
              onClick={fetchUsers}
              className="w-full px-4 py-2 bg-red-100 text-red-700 rounded-lg hover:bg-red-200"
            >
              Reintentar
            </button>
          </div>
        </div>
      </div>
    );
  }

  // Renderizado principal
  return (
    <div className="flex h-screen">
      {/* Sidebar */}
      <div className="w-64 bg-white border-r shadow-sm flex flex-col">
        <div className="p-6 border-b">
          <h2 className="text-2xl font-bold text-gray-800">Admin Panel</h2>
          <p className="text-sm text-gray-500">Espor.ai</p>
        </div>

        <nav className="flex-1 p-4">
          {menuItems.map((item) => (
            <Link 
              key={item.path} 
              to={item.path} 
              className={`
                flex items-center p-3 rounded-lg mb-2 transition-colors 
                ${location.pathname === item.path 
                  ? 'bg-purple-100 text-purple-600' 
                  : 'text-gray-600 hover:bg-gray-100 hover:text-gray-900'
                }
              `}
            >
              <item.icon className="w-5 h-5 mr-3" />
              <span className="text-sm font-medium">{item.label}</span>
            </Link>
          ))}
        </nav>

        <div className="p-4 border-t">
          <button 
            onClick={handleLogout}
            className="w-full flex items-center justify-center p-3 text-red-600 hover:bg-red-50 rounded-lg transition-colors"
          >
            <LogOut className="w-5 h-5 mr-3" />
            <span className="text-sm font-medium">Cerrar Sesión</span>
          </button>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 bg-gray-50 overflow-y-auto">
        <div className="p-6 space-y-6">
          {/* Header */}
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-bold text-gray-900">Gestión de Usuarios</h2>
            <button
              onClick={() => setIsCreateModalOpen(true)}
              className="flex items-center space-x-2 px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
            >
              <Plus className="w-5 h-5" />
              <span>Crear Usuario</span>
            </button>
          </div>

          {/* Filtros y Búsqueda */}
          <div className="flex flex-wrap gap-4 mb-6">
            <div className="relative flex-grow">
              <input
                type="text"
                placeholder="Buscar usuario..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-10 pr-4 py-2 border rounded-lg"
              />
              <Search className="absolute left-3 top-3 text-gray-400" />
            </div>

            <div className="relative">
              <select
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
                className="pl-3 pr-10 py-2 border rounded-lg appearance-none"
              >
                <option value="all">Todos los estados</option>
                <option value="active">Activo</option>
                <option value="inactive">Inactivo</option>
                <option value="banned">Baneado</option>
                <option value="admin">Administrador</option>
              </select>
              <ChevronDown className="absolute right-3 top-3 h-4 w-4 pointer-events-none text-gray-400" />
            </div>

            <div className="relative">
              <select
                value={subscriptionFilter}
                onChange={(e) => setSubscriptionFilter(e.target.value)}
                className="pl-3 pr-10 py-2 border rounded-lg appearance-none"
              >
                <option value="all">Todos los planes</option>
                <option value="free">Free</option>
                <option value="pro">Pro</option>
                <option value="enterprise">Enterprise</option>
              </select>
              <ChevronDown className="absolute right-3 top-3 h-4 w-4 pointer-events-none text-gray-400" />
            </div>

            <button
              onClick={fetchUsers}
              className="px-4 py-2 border rounded-lg hover:bg-gray-50 flex items-center gap-2"
              title="Actualizar usuarios"
            >
              <RefreshCw className="h-4 w-4" />
              <span>Actualizar</span>
            </button>
          </div>

          {/* Tabla de Usuarios */}
          <div className="bg-white shadow-sm rounded-lg overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th 
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort('name')}
                  >
                    <div className="flex items-center">
                      Usuario
                      {sortField === 'name' && (
                        sortDirection === 'asc' ? 
                          <ChevronUp className="h-4 w-4 ml-1" /> : 
                          <ChevronDown className="h-4 w-4 ml-1" />
                      )}
                    </div>
                  </th>
                  <th 
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort('subscription_plan')}
                  >
                    <div className="flex items-center">
                      Plan
                      {sortField === 'subscription_plan' && (
                        sortDirection === 'asc' ? 
                          <ChevronUp className="h-4 w-4 ml-1" /> : 
                          <ChevronDown className="h-4 w-4 ml-1" />
                      )}
                    </div>
                  </th>
                  <th 
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort('status')}
                  >
                    <div className="flex items-center">
                      Estado
                      {sortField === 'status' && (
                        sortDirection === 'asc' ? 
                          <ChevronUp className="h-4 w-4 ml-1" /> : 
                          <ChevronDown className="h-4 w-4 ml-1" />
                      )}
                    </div>
                  </th>
                  <th 
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort('last_login')}
                  >
                    <div className="flex items-center">
                      Última Conexión
                      {sortField === 'last_login' && (
                        sortDirection === 'asc' ? 
                          <ChevronUp className="h-4 w-4 ml-1" /> : 
                          <ChevronDown className="h-4 w-4 ml-1" />
                      )}
                    </div>
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Acciones</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {sortedUsers.map((user) => (
                  <tr key={user.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 h-10 w-10 bg-gray-100 rounded-full flex items-center justify-center">
                          <Users className="h-6 w-6 text-gray-500" />
                        </div>
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">{user.name}</div>
                          <div className="text-sm text-gray-500">{user.email}</div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getSubscriptionColor(user.subscription_plan)}`}>
                        {getSubscriptionText(user.subscription_plan)}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(user.status)}`}>
                        {getStatusText(user.status)}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {user.last_login ? new Date(user.last_login).toLocaleString() : 'Nunca'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
  <div className="flex items-center space-x-3">
    <button 
      onClick={() => {
        setSelectedUser(user);
        setIsModalOpen(true);
      }}
      className="text-blue-600 hover:text-blue-900"
      title="Editar"
    >
      <Edit className="w-5 h-5" />
    </button>
    <button 
      onClick={() => {
        // Obtener el ID numérico correcto
        const userId = typeof user.id === 'string' ? parseInt(user.id, 10) : user.id;
        
        // Verificar si hay un ID numérico en el objeto del usuario
        if (typeof userId === 'string' && !isNaN(parseInt(userId, 10))) {
          // Si es una cadena numérica, conviértela
          setSelectedUser(user);
          fetchUserChatSessions(parseInt(userId, 10));
          setIsChatModalOpen(true);
        } else if (typeof userId === 'number') {
          // Si ya es un número, úsalo directamente
          setSelectedUser(user);
          fetchUserChatSessions(userId);
          setIsChatModalOpen(true);
        } else {
          // Si no se puede convertir a número, muestra un error
          console.error("El usuario no tiene un ID numérico válido:", user);
          alert("No se puede acceder a los chats de este usuario debido a un ID no válido");
        }
      }}
      className="text-green-600 hover:text-green-900"
      title="Ver todos los chats"
    >
      <MessageSquare className="w-5 h-5" />
    </button>
    {/* Nuevo botón para ver solo análisis */}
    <button 
      onClick={() => {
        // Obtener el ID numérico correcto
        const userId = typeof user.id === 'string' ? parseInt(user.id, 10) : user.id;
        
        // Verificar si hay un ID numérico en el objeto del usuario
        if (typeof userId === 'string' && !isNaN(parseInt(userId, 10))) {
          // Si es una cadena numérica, conviértela
          setSelectedUser(user);
          fetchUserAnalysisSessions(parseInt(userId, 10));
        } else if (typeof userId === 'number') {
          // Si ya es un número, úsalo directamente
          setSelectedUser(user);
          fetchUserAnalysisSessions(userId);
        } else {
          // Si no se puede convertir a número, muestra un error
          console.error("El usuario no tiene un ID numérico válido:", user);
          alert("No se puede acceder a los análisis de este usuario debido a un ID no válido");
        }
      }}
      className="text-blue-600 hover:text-blue-900"
      title="Ver solo análisis"
    >
      <Filter className="w-5 h-5" />
    </button>
    <button 
      onClick={() => handleDeleteUser(user.id)}
      className="text-red-600 hover:text-red-900"
      title="Eliminar"
    >
      <Trash2 className="w-5 h-5" />
    </button>
  </div>
</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Info de paginación */}
          <div className="flex justify-between items-center mt-4 text-sm text-gray-500">
            <span>
              Mostrando {sortedUsers.length} de {users.length} usuarios
            </span>
          </div>
        </div>
      </div>

      {/* Modales */}
      {isModalOpen && selectedUser && (
        <EditUserModal 
          user={selectedUser} 
          onClose={() => setIsModalOpen(false)}
          onSave={() => {
            fetchUsers();
            setIsModalOpen(false);
          }} 
        />
      )}

      {isCreateModalOpen && (
        <CreateUserModal 
          onClose={() => setIsCreateModalOpen(false)}
        />
      )}

      {isChatModalOpen && selectedUser && (
        <ChatSessionsModal 
          user={selectedUser}
          sessions={userChatSessions}
          onClose={() => {
            setIsChatModalOpen(false);
            setSelectedChat(null);
            setChatMessages([]);
          }}
        />
      )}
    </div>
  );
};

export default UserManagement;