import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { 
  Users, 
  DollarSign, 
  Target, 
  ArrowUp, 
  ArrowDown,
  RefreshCcw,
  LayoutGrid, 
  CreditCard, 
  Settings, 
  HelpCircle, 
  LogOut 
} from 'lucide-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, PieChart as RePieChart, Pie, Cell } from 'recharts';
import { Card, CardContent } from '../../components/ui/card';

// StatCard Component
const StatCard = ({ title, value, icon: Icon, trend = null, prefix = '', color = 'purple' }) => (
  <div className="bg-white rounded-xl shadow-sm p-6 hover:shadow-md transition-shadow">
    <div className="flex justify-between items-start mb-4">
      <div className={`p-3 bg-${color}-100 rounded-lg`}>
        {Icon && <Icon className={`w-6 h-6 text-${color}-600`} />}
      </div>
      <div className="flex flex-col items-end">
        <p className="text-sm text-gray-500">{title}</p>
        <h4 className="text-2xl font-bold">{prefix}{value.toLocaleString()}</h4>
      </div>
    </div>
    {trend !== null && (
      <div className="flex items-center justify-end space-x-2">
        {trend > 0 ? (
          <ArrowUp className="w-4 h-4 text-green-500" />
        ) : (
          <ArrowDown className="w-4 h-4 text-red-500" />
        )}
        <span className={`text-sm ${trend > 0 ? 'text-green-500' : 'text-red-500'}`}>
          {Math.abs(trend)}%
        </span>
      </div>
    )}
  </div>
);

// RevenueChart Component
const RevenueChart = ({ data = [] }) => {
  const chartData = data
    .filter(campaign => campaign?.metrics?.spend > 0)
    .reduce((acc, campaign) => {
      const date = new Date(campaign.created_at).toLocaleDateString('es-ES', { 
        year: 'numeric', 
        month: 'short' 
      });
      const existing = acc.find(item => item.date === date);
      if (existing) {
        existing.value += campaign.metrics.spend;
      } else {
        acc.push({ date, value: campaign.metrics.spend });
      }
      return acc;
    }, [])
    .sort((a, b) => new Date(a.date) - new Date(b.date));

  return (
    <Card className="w-full">
      <CardContent className="pt-6">
        <h3 className="text-lg font-semibold mb-6">Ingresos por Mes</h3>
        <div className="h-72">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip 
                formatter={(value) => `$${value.toLocaleString()}`}
              />
              <Line 
                type="monotone" 
                dataKey="value" 
                stroke="#8b5cf6" 
                strokeWidth={2}
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </CardContent>
    </Card>
  );
};

// PerformanceDonut Component
const PerformanceDonut = ({ data = [] }) => {
  const platformData = data.reduce((acc, campaign) => {
    const platform = campaign.platform === 'meta_ads' ? 'Meta Ads' : 'Google Ads';
    const existing = acc.find(item => item.name === platform);
    if (existing) {
      existing.value += campaign.metrics.spend;
    } else {
      acc.push({
        name: platform,
        value: campaign.metrics.spend,
        color: platform === 'Meta Ads' ? '#4267B2' : '#4285F4'
      });
    }
    return acc;
  }, []);

  return (
    <Card className="w-full">
      <CardContent className="pt-6">
        <h3 className="text-lg font-semibold mb-6">Inversión por Plataforma</h3>
        <div className="h-64">
          <ResponsiveContainer width="100%" height="100%">
            <RePieChart>
              <Pie
                data={platformData}
                innerRadius={60}
                outerRadius={80}
                paddingAngle={5}
                dataKey="value"
              >
                {platformData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Tooltip formatter={(value) => `$${value.toLocaleString()}`} />
            </RePieChart>
          </ResponsiveContainer>
        </div>
        <div className="grid grid-cols-2 gap-4 mt-4">
          {platformData.map((platform) => (
            <div key={platform.name} className="text-center">
              <div className="text-sm font-medium">{platform.name}</div>
              <div className="text-lg font-bold">${platform.value.toLocaleString()}</div>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

// Main AdminDashboard Component
const AdminDashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [stats, setStats] = useState({
    total_users: 0,
    active_subscriptions: 0,
    revenue_mtd: 0,
    active_campaigns: 0,
    trend_data: {
      users_change: 0,
      revenue_change: 0,
      campaigns_change: 0
    }
  });
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [timeRange, setTimeRange] = useState('7d');
  const [searchTerm, setSearchTerm] = useState('');
  const [platformFilter, setPlatformFilter] = useState('all');
  const [statusFilter, setStatusFilter] = useState('all');

  const menuItems = [
    { 
      icon: LayoutGrid, 
      label: 'Dashboard', 
      path: '/admin-espor.ai', 
    },
    { 
      icon: Users, 
      label: 'Usuarios', 
      path: '/admin-espor.ai/users', 
    },
    { 
      icon: CreditCard, 
      label: 'Suscripciones', 
      path: '/admin-espor.ai/subscriptions', 
    },

  ];

  const handleLogout = () => {
    localStorage.removeItem('adminToken');
    navigate('/admin-espor.ai/login');
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem('adminToken');
        if (!token) throw new Error('No se encontró el token de autenticación');

        const [statsResponse, campaignsResponse] = await Promise.all([
          fetch(`${process.env.REACT_APP_API_URL}/api/admin/stats?range=${timeRange}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }),
          fetch(`${process.env.REACT_APP_API_URL}/api/admin/campaigns`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })
        ]);

        if (!statsResponse.ok || !campaignsResponse.ok) {
          throw new Error('Error al cargar los datos');
        }

        const [statsData, campaignsData] = await Promise.all([
          statsResponse.json(),
          campaignsResponse.json()
        ]);

        setStats(statsData);
        setCampaigns(campaignsData.campaigns);
        setError(null);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [timeRange]);

  const getFilteredCampaigns = () => {
    return campaigns.filter(campaign => {
      const matchesSearch = 
        campaign.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        campaign.user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        campaign.user.business_name?.toLowerCase().includes(searchTerm.toLowerCase());
      
      const matchesPlatform = platformFilter === 'all' || campaign.platform === platformFilter;
      const matchesStatus = statusFilter === 'all' || campaign.status === statusFilter;
      
      return matchesSearch && matchesPlatform && matchesStatus;
    });
  };

  if (loading) {
    return (
      <div className="flex h-screen">
        <div className="w-64 bg-white border-r shadow-sm">
          <div className="p-6 border-b">
            <h2 className="text-2xl font-bold text-gray-800">Admin Panel</h2>
            <p className="text-sm text-gray-500">Espor.ai</p>
          </div>
        </div>
        <div className="flex-1 flex justify-center items-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-purple-600"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex h-screen">
        <div className="w-64 bg-white border-r shadow-sm">
          <div className="p-6 border-b">
            <h2 className="text-2xl font-bold text-gray-800">Admin Panel</h2>
            <p className="text-sm text-gray-500">Espor.ai</p>
          </div>
        </div>
        <div className="flex-1 flex flex-col justify-center items-center p-6">
          <div className="bg-red-50 border border-red-200 rounded-xl p-6 max-w-md w-full">
            <p className="text-red-700 mb-4">{error}</p>
            <button
              onClick={() => window.location.reload()}
              className="w-full px-4 py-2 bg-red-100 text-red-700 rounded-lg hover:bg-red-200"
            >
              Reintentar
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-screen">
      {/* Sidebar */}
      <div className="w-64 bg-white border-r shadow-sm flex flex-col">
        {/* Logo and Title */}
        <div className="p-6 border-b">
          <h2 className="text-2xl font-bold text-gray-800">Admin Panel</h2>
          <p className="text-sm text-gray-500">Espor.ai</p>
        </div>

        {/* Navigation Menu */}
        <nav className="flex-1 p-4">
          {menuItems.map((item) => (
            <Link 
              key={item.path} 
              to={item.path} 
              className={`
                flex items-center p-3 rounded-lg mb-2 transition-colors 
                ${location.pathname === item.path 
                  ? 'bg-purple-100 text-purple-600' 
                  : 'text-gray-600 hover:bg-gray-100 hover:text-gray-900'
                }
              `}
            >
              <item.icon className="w-5 h-5 mr-3" />
              <span className="text-sm font-medium">{item.label}</span>
            </Link>
          ))}
        </nav>

        {/* Logout Button */}
        <div className="p-4 border-t">
          <button 
            onClick={handleLogout}
            className="w-full flex items-center justify-center p-3 text-red-600 hover:bg-red-50 rounded-lg transition-colors"
          >
            <LogOut className="w-5 h-5 mr-3" />
            <span className="text-sm font-medium">Cerrar Sesión</span>
          </button>
        </div>
      </div>

      {/* Main Content Area */}
      <div className="flex-1 bg-gray-50 overflow-y-auto">
        <div className="space-y-6 p-6">
          {/* Header */}
          <div className="flex justify-between items-center">
            <div>
              <h2 className="text-2xl font-bold text-gray-900">Dashboard</h2>
              <p className="text-gray-600">Vista general del rendimiento</p>
            </div>
            <button
              onClick={() => window.location.reload()}
              className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 flex items-center space-x-2"
            >
              <RefreshCcw className="w-4 h-4" />
              <span>Actualizar</span>
            </button>
          </div>

          {/* Stats Cards */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            <StatCard 
              title="Usuarios Totales"
              value={stats.total_users}
              icon={Users}
              trend={stats.trend_data?.users_change}
              color="blue"
            />
            <StatCard 
              title="Suscripciones"
              value={stats.active_subscriptions}
              icon={Target}
              trend={stats.trend_data?.subscriptions_change}
              color="green"
            />
            <StatCard 
              title="Ingresos"
              value={stats.revenue_mtd}
              icon={DollarSign}
              trend={stats.trend_data?.revenue_change}
              prefix="$"
              color="purple"
            />
            <StatCard 
              title="Campañas"
              value={stats.active_campaigns}
              icon={Target}
              trend={stats.trend_data?.campaigns_change}
              color="orange"
            />
          </div>

          {/* Charts */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            <div className="lg:col-span-2">
              <RevenueChart data={campaigns} />
            </div>
            <div>
              <PerformanceDonut data={campaigns} />
            </div>
          </div>

          {/* Campaigns Table */}
          <div className="bg-white rounded-xl shadow-sm">
            <div className="p-6">
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-lg font-semibold">Campañas</h3>
                <div className="flex items-center space-x-4">
                  <input
                    type="text"
                    placeholder="Buscar campañas..."
                    className="px-4 py-2 border rounded-lg"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <select
                    className="px-4 py-2 border rounded-lg"
                    value={platformFilter}
                    onChange={(e) => setPlatformFilter(e.target.value)}
                  >
                    <option value="all">Todas las plataformas</option>
                    <option value="meta_ads">Meta Ads</option>
                    <option value="google_ads">Google Ads</option>
                  </select>
                  <select
                    className="px-4 py-2 border rounded-lg"
                    value={statusFilter}
                    onChange={(e) => setStatusFilter(e.target.value)}
                  >
                    <option value="all">Todos los estados</option>
                    <option value="ENABLED">Activa</option>
                    <option value="PAUSED">Pausada</option>
                    <option value="PENDING_REVIEW">Pendiente</option>
                  </select>
                </div>
              </div>

              <div className="overflow-x-auto">
                <table className="min-w-full">
                  <thead>
                    <tr className="text-left text-sm text-gray-500">
                      <th className="pb-4 font-medium">Cliente</th>
                      <th className="pb-4 font-medium">Campaña</th>
                      <th className="pb-4 font-medium">Plataforma</th>
                      <th className="pb-4 font-medium">Estado</th>
                      <th className="pb-4 font-medium">Presupuesto</th>
                      <th className="pb-4 font-medium">Performance</th>
                      <th className="pb-4 font-medium">ROI</th>
                      <th className="pb-4 font-medium">Fecha</th>
                    </tr>
                  </thead>
                  <tbody className="text-sm">
                    {getFilteredCampaigns().map((campaign) => (
                      <tr key={campaign.id} className="border-t">
                        <td className="py-4">
                          <div>
                            <p className="font-medium text-gray-900">{campaign.user.business_name}</p>
                            <p className="text-gray-500 text-xs">{campaign.user.email}</p>
                          </div>
                        </td>
                        <td className="py-4">
                          <p className="font-medium text-gray-900">{campaign.name}</p>
                          <p className="text-gray-500 text-xs">{campaign.objective}</p>
                        </td>
                        <td className="py-4">
                          <span className={`px-2 py-1 rounded-full text-xs ${
                            campaign.platform === 'meta_ads' ? 'bg-blue-100 text-blue-800' : 'bg-red-100 text-red-800'
                          }`}>
                            {campaign.platform === 'meta_ads' ? 'Meta Ads' : 'Google Ads'}
                          </span>
                        </td>
                        <td className="py-4">
                          <span className={`px-2 py-1 rounded-full text-xs ${
                            campaign.status === 'ENABLED' ? 'bg-green-100 text-green-800' :
                            campaign.status === 'PAUSED' ? 'bg-yellow-100 text-yellow-800' :
                            'bg-gray-100 text-gray-800'
                          }`}>
                            {campaign.status === 'ENABLED' ? 'Activa' :
                             campaign.status === 'PAUSED' ? 'Pausada' :
                             'Pendiente'}
                          </span>
                        </td>
                        <td className="py-4">
                          ${campaign.metrics.spend.toLocaleString()}
                        </td>
                        <td className="py-4">
                          <div className="flex items-center space-x-2">
                            <div className="w-24 h-2 bg-gray-200 rounded-full">
                              <div 
                                className={`h-full rounded-full ${
                                  campaign.performance >= 75 ? 'bg-green-500' :
                                  campaign.performance >= 50 ? 'bg-yellow-500' :
                                  'bg-red-500'
                                }`}
                                style={{ width: `${campaign.performance}%` }}
                              />
                            </div>
                            <span>{campaign.performance.toFixed(1)}%</span>
                          </div>
                        </td>
                        <td className="py-4">
                          <span className={`font-medium ${
                            campaign.roi >= 2 ? 'text-green-600' :
                            campaign.roi >= 1 ? 'text-yellow-600' :
                            'text-red-600'
                          }`}>
                            {campaign.roi.toFixed(2)}x
                          </span>
                        </td>
                        <td className="py-4 text-gray-500">
                          {new Date(campaign.created_at).toLocaleDateString()}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="mt-4 text-sm text-gray-500">
                Mostrando {getFilteredCampaigns().length} de {campaigns.length} campañas
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;