import React, { useState, useEffect } from 'react';
import { Save, RefreshCw } from 'lucide-react';

const Settings = () => {
  const [settings, setSettings] = useState({
    payment_gateway: {
      mercadopago_public_key: '',
      mercadopago_access_token: '',
      test_mode: true
    },
    email_settings: {
      smtp_host: '',
      smtp_port: '',
      smtp_user: '',
      smtp_password: '',
      from_email: '',
      from_name: ''
    },
    subscription_settings: {
      trial_days: 14,
      default_plan: 'basic',
      allow_cancellation: true
    }
  });
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [message, setMessage] = useState({ type: '', text: '' });

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/settings`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
        }
      });
      const data = await response.json();
      setSettings(data);
    } catch (error) {
      console.error('Error fetching settings:', error);
      setMessage({ type: 'error', text: 'Error al cargar la configuración' });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/settings`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('adminToken')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(settings)
      });

      if (response.ok) {
        setMessage({ type: 'success', text: 'Configuración guardada exitosamente' });
      } else {
        throw new Error('Error al guardar la configuración');
      }
    } catch (error) {
      console.error('Error saving settings:', error);
      setMessage({ type: 'error', text: 'Error al guardar la configuración' });
    } finally {
      setSaving(false);
    }
  };

  const handleChange = (section, field, value) => {
    setSettings(prev => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: value
      }
    }));
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-500"></div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">Configuración</h2>
        <div className="flex space-x-4">
          <button
            onClick={fetchSettings}
            className="flex items-center px-4 py-2 text-gray-600 bg-white rounded-lg border hover:bg-gray-50"
          >
            <RefreshCw size={18} className="mr-2" />
            Recargar
          </button>
          <button
            onClick={handleSubmit}
            disabled={saving}
            className="flex items-center px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
          >
            <Save size={18} className="mr-2" />
            {saving ? 'Guardando...' : 'Guardar Cambios'}
          </button>
        </div>
      </div>

      {message.text && (
        <div className={`mb-6 p-4 rounded-lg ${
          message.type === 'success' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
        }`}>
          {message.text}
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-8">
        {/* Payment Gateway Settings */}
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h3 className="text-lg font-semibold mb-4">Configuración de Pagos</h3>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                MercadoPago Public Key
              </label>
              <input
                type="text"
                value={settings.payment_gateway.mercadopago_public_key}
                onChange={(e) => handleChange('payment_gateway', 'mercadopago_public_key', e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                MercadoPago Access Token
              </label>
              <input
                type="password"
                value={settings.payment_gateway.mercadopago_access_token}
                onChange={(e) => handleChange('payment_gateway', 'mercadopago_access_token', e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
              />
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                checked={settings.payment_gateway.test_mode}
                onChange={(e) => handleChange('payment_gateway', 'test_mode', e.target.checked)}
                className="h-4 w-4 text-purple-600 focus:ring-purple-500 border-gray-300 rounded"
              />
              <label className="ml-2 block text-sm text-gray-700">
                Modo de pruebas
              </label>
            </div>
          </div>
        </div>

        {/* Email Settings */}
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h3 className="text-lg font-semibold mb-4">Configuración de Email</h3>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                SMTP Host
              </label>
              <input
                type="text"
                value={settings.email_settings.smtp_host}
                onChange={(e) => handleChange('email_settings', 'smtp_host', e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                SMTP Port
              </label>
              <input
                type="text"
                value={settings.email_settings.smtp_port}
                onChange={(e) => handleChange('email_settings', 'smtp_port', e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                SMTP Usuario
              </label>
              <input
                type="text"
                value={settings.email_settings.smtp_user}
                onChange={(e) => handleChange('email_settings', 'smtp_user', e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                SMTP Contraseña
              </label>
              <input
                type="password"
                value={settings.email_settings.smtp_password}
                onChange={(e) => handleChange('email_settings', 'smtp_password', e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Email Remitente
              </label>
              <input
                type="email"
                value={settings.email_settings.from_email}
                onChange={(e) => handleChange('email_settings', 'from_email', e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Nombre Remitente
              </label>
              <input
                type="text"
                value={settings.email_settings.from_name}
                onChange={(e) => handleChange('email_settings', 'from_name', e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
              />
            </div>
          </div>
        </div>

        {/* Subscription Settings */}
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h3 className="text-lg font-semibold mb-4">Configuración de Suscripciones</h3>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Días de Prueba
              </label>
              <input
                type="number"
                value={settings.subscription_settings.trial_days}
                onChange={(e) => handleChange('subscription_settings', 'trial_days', parseInt(e.target.value))}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Plan por Defecto
              </label>
              <select
                value={settings.subscription_settings.default_plan}
                onChange={(e) => handleChange('subscription_settings', 'default_plan', e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
              >
                <option value="basic">Básico</option>
                <option value="performance">Performance</option>
                <option value="premium">Premium</option>
              </select>
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                checked={settings.subscription_settings.allow_cancellation}
                onChange={(e) => handleChange('subscription_settings', 'allow_cancellation', e.target.checked)}
                className="h-4 w-4 text-purple-600 focus:ring-purple-500 border-gray-300 rounded"
              />
              <label className="ml-2 block text-sm text-gray-700">
                Permitir cancelación de suscripción
              </label>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Settings;