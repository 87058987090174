import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../components/context/AuthContext";
import { FcGoogle } from "react-icons/fc";
import { FaFacebook } from "react-icons/fa";
import { jwtDecode } from "jwt-decode";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:5000";

const Register = () => {
  const navigate = useNavigate();
  const { login, auth, logout } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [fbLoaded, setFbLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);

  // Función para limpiar los datos de sesión anteriores
  const clearPreviousSessions = async () => {
    // Limpiar cualquier token o estado de sesión anterior
    localStorage.removeItem("facebook_auth_state");
    sessionStorage.removeItem("auth_method");
    sessionStorage.removeItem("auth_state");

    // Si hay una sesión activa, hacer logout completo
    if (auth?.token) {
      await logout();
    }
  };

  useEffect(() => {
    // Si ya está autenticado con token válido, redirigir al dashboard
    if (auth?.token) {
      try {
        const decodedToken = jwtDecode(auth.token);
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp && decodedToken.exp > currentTime) {
          navigate("/dashboard");
          return;
        } else {
          // Si el token ha expirado, hacer logout
          logout();
        }
      } catch (error) {
        console.error("Error validando token existente:", error);
        logout();
      }
    }

    const loadFacebookSDK = () => {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: process.env.REACT_APP_FACEBOOK_APP_ID,
          cookie: true,
          xfbml: true,
          version: "v18.0",
        });

        setFbLoaded(true);

        // No autoconectar al cargar la página, dejar que el usuario inicie sesión explícitamente
        window.FB.getLoginStatus(function (response) {
          console.log("Facebook login status:", response.status);
          // No iniciamos sesión automáticamente
        });
      };

      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");
    };

    loadFacebookSDK();

    // Comprobar parámetros de URL solo si no está redirigiendo ya
    if (!isRedirecting) {
      const queryParams = new URLSearchParams(window.location.search);
      const token = queryParams.get("token");
      const authError = queryParams.get("error");

      if (token) {
        setIsRedirecting(true);
        try {
          const decodedToken = jwtDecode(token);
          const currentTime = Date.now() / 1000;

          if (decodedToken.exp && decodedToken.exp > currentTime) {
            // Limpiar sesiones anteriores antes de iniciar una nueva
            clearPreviousSessions().then(() => {
              // Método 'query' para este tipo de inicio de sesión
              login(token, null, "query");
              navigate("/onboarding"); // En registro, enviamos a onboarding en lugar de dashboard
            });
          } else {
            setError("La sesión ha expirado. Por favor, intenta nuevamente.");
            setIsRedirecting(false);
          }
        } catch (error) {
          console.error("Error decodificando token:", error);
          setError("Error de autenticación. Token inválido.");
          setIsRedirecting(false);
        }
      } else if (authError) {
        setError("Error de autenticación. Por favor, intenta de nuevo.");
      }
    }

    // Limpiar la URL después de procesar los parámetros
    if (window.history && window.history.replaceState) {
      const cleanUrl = window.location.pathname;
      window.history.replaceState({}, document.title, cleanUrl);
    }
  }, [login, navigate, auth?.token, isRedirecting, logout]);

  const handleEmailRegister = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    if (password !== confirmPassword) {
      setError("Las contraseñas no coinciden");
      setIsLoading(false);
      return;
    }

    try {
      // Limpiar sesiones anteriores
      await clearPreviousSessions();

      // Registro
      const registerResponse = await fetch(`${API_URL}/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
        credentials: "include",
      });

      if (!registerResponse.ok) {
        const errorData = await registerResponse.json();
        throw new Error(errorData.error || "Error en el registro");
      }

      // Inicio de sesión automático después del registro
      const loginResponse = await fetch(`${API_URL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
        credentials: "include",
      });

      if (!loginResponse.ok) {
        throw new Error("Error al iniciar sesión automáticamente");
      }

      const data = await loginResponse.json();
      await login(data.access_token, null, "credentials");
      navigate("/onboarding"); // Los nuevos usuarios van al onboarding
    } catch (error) {
      console.error("Error:", error);
      setError(
        error.message || "Error de conexión. Por favor, intenta de nuevo."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleRegister = async () => {
    try {
      setIsLoading(true);

      // Limpiar sesiones anteriores
      await clearPreviousSessions();

      // Guardar URL actual para redirigir después del inicio de sesión
      localStorage.setItem("redirectAfterLogin", "/onboarding");

      // Generar un estado CSRF para Google
      const googleState = Math.random().toString(36).substring(2, 15);
      localStorage.setItem("google_auth_state", googleState);

      const googleAuthUrl = `${API_URL}/login/google?state=${googleState}`;
      window.location.href = googleAuthUrl;
    } catch (error) {
      console.error("Error preparando registro con Google:", error);
      setError("Error preparando registro con Google");
      setIsLoading(false);
    }
  };

  const handleMetaRegister = async () => {
    if (!fbLoaded) {
      setError("Por favor espera mientras se carga el SDK de Facebook");
      return;
    }

    try {
      setError("");
      setIsLoading(true);

      // Limpiar sesiones anteriores
      await clearPreviousSessions();

      // Generar estado CSRF para Meta
      const fbState = Math.random().toString(36).substring(2, 15);
      localStorage.setItem("facebook_auth_state", fbState);

      const requiredPermissions = [
        "email",
        "read_insights",
        "catalog_management",
        "pages_show_list",
        "ads_management",
        "ads_read",
        "business_management",
        "instagram_basic",
        "pages_read_engagement",
        "pages_manage_ads",
        "commerce_account_read_reports",
        "commerce_account_read_orders",
        "commerce_account_manage_orders",
        "commerce_account_read_settings",
        "instagram_shopping_tag_products",
        "attribution_read",
        "pages_manage_cta",
        "pages_manage_engagement",
        "pages_manage_instant_articles",
        "page_events",
        "manage_app_solution",
        "instagram_manage_events",
        "pages_manage_posts",
        "pages_manage_metadata",
        "pages_messaging",
        "pages_messaging_phone_number",
        "pages_messaging_subscriptions",
        "read_page_mailboxes",
        "pages_read_user_content",
        "instagram_branded_content_ads_brand",
        "instagram_branded_content_brand",
        "instagram_branded_content_creator",
        "instagram_content_publish",
        "instagram_manage_comments",
        "instagram_manage_insights",
        "instagram_manage_upcoming_events",
        "instagram_manage_messages",
        "manage_fundraisers",
        "leads_retrieval",
        "private_computation_access",
        "publish_video",
        "whatsapp_business_management",
        "whatsapp_business_messaging",
      ];

      // Registrar que estamos utilizando autenticación de Facebook
      sessionStorage.setItem("auth_method", "facebook");
      sessionStorage.setItem("auth_state", "connecting");

      window.FB.login(
        function (response) {
          if (response.authResponse) {
            fetchFacebookUserData(response.authResponse.accessToken);
          } else {
            setError("Registro de Facebook cancelado");
            setIsLoading(false);
            // Limpiar estado de autenticación si se cancela
            sessionStorage.removeItem("auth_method");
            sessionStorage.removeItem("auth_state");
          }
        },
        {
          config_id: process.env.REACT_APP_FACEBOOK_CONFIG_ID,
          scope: requiredPermissions.join(","),
          auth_type: "rerequest",
          return_scopes: true,
          state: fbState, // Incluir estado CSRF
        }
      );
    } catch (error) {
      console.error("Error registrando con Meta:", error);
      setError("Error preparando registro con Meta");
      setIsLoading(false);
      // Limpiar estado de autenticación en caso de error
      sessionStorage.removeItem("auth_method");
      sessionStorage.removeItem("auth_state");
    }
  };

  const fetchFacebookUserData = async (accessToken) => {
    try {
      const permissionsResponse = await fetch(
        `https://graph.facebook.com/me/permissions?access_token=${accessToken}`
      );
      const permissionsData = await permissionsResponse.json();

      if (
        !permissionsData.data.some(
          (permission) =>
            permission.permission === "email" && permission.status === "granted"
        )
      ) {
        throw new Error(
          "Se requiere acceso al correo electrónico para continuar"
        );
      }

      const userDataResponse = await fetch(
        `https://graph.facebook.com/me?fields=id,name,email&access_token=${accessToken}`
      );
      const userData = await userDataResponse.json();

      const response = await fetch(`${API_URL}/login/facebook/verify`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          facebookId: userData.id,
          email: userData.email,
          name: userData.name,
          accessToken: accessToken,
          permissions: permissionsData.data,
          isRegistration: true, // Agregamos este campo para indicar que es un registro
        }),
        credentials: "include",
      });

      const data = await response.json();

      if (response.ok) {
        // Actualizar estado de autenticación
        sessionStorage.setItem("auth_state", "connected");

        // Iniciar sesión con el token recibido
        await login(data.access_token, userData, "facebook");
        navigate("/onboarding"); // Redirigir al onboarding para usuarios nuevos
      } else {
        // Limpiar estado de autenticación en caso de error
        sessionStorage.removeItem("auth_method");
        sessionStorage.removeItem("auth_state");

        setError(data.error || "Error en el registro");
      }
    } catch (error) {
      console.error("Error:", error);

      // Limpiar estado de autenticación en caso de error
      sessionStorage.removeItem("auth_method");
      sessionStorage.removeItem("auth_state");

      setError(error.message || "Error al procesar el registro de Facebook");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col md:flex-row">
      {/* Left Side - Background Image */}
      <div className="hidden md:flex md:w-1/2 relative overflow-hidden flex justify-center items-center">
        <div className="w-[700px] aspect-square relative overflow-hidden rounded-lg mx-auto">
          <video
            className="absolute w-full h-full object-cover"
            autoPlay
            loop
            muted
            playsInline
          >
            <source
              src="https://www.espor.ai/images/hero-gif.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      </div>

      {/* Form container */}
      <div className="flex-1 flex items-center justify-center p-8">
        <div className="max-w-md w-full space-y-8">
          {/* Logo and title */}
          <div className="text-center">
            <img
              className="mx-auto h-20 w-auto"
              src="/ch3.png"
              alt="Logo de la compañía"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Crea tu cuenta
            </h2>
          </div>

          {/* Error message */}
          {error && (
            <div className="rounded-md bg-red-50 p-4">
              <div className="flex">
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">{error}</h3>
                </div>
              </div>
            </div>
          )}

          {/* Registration form */}
          <form className="mt-8 space-y-6" onSubmit={handleEmailRegister}>
            <input type="hidden" name="remember" value="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Correo electrónico"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={isLoading}
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="new-password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Contraseña"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  disabled={isLoading}
                />
              </div>
              <div>
                <label htmlFor="confirm-password" className="sr-only">
                  Confirm Password
                </label>
                <input
                  id="confirm-password"
                  name="confirm-password"
                  type="password"
                  autoComplete="new-password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Confirmar contraseña"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  disabled={isLoading}
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
                disabled={isLoading}
              >
                {isLoading ? "Creando cuenta..." : "Registrarse"}
              </button>
            </div>
          </form>

          {/* Separator */}
          <div className="mt-6">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300"></div>
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">
                  O continuar con
                </span>
              </div>
            </div>

            {/* Login link */}
            <div className="mt-6 text-center">
              <p className="text-sm text-gray-600">
                ¿Ya tienes una cuenta?{" "}
                <a
                  href="/login"
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Inicia sesión aquí
                </a>
              </p>
            </div>

            {/* Social buttons */}
            <div className="mt-6 grid grid-cols-2 gap-3">
              <button
                onClick={handleGoogleRegister}
                className="w-full flex items-center justify-center px-8 py-3 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
                disabled={isLoading}
              >
                <FcGoogle className="h-5 w-5 mr-2" />
                Google
              </button>

              {/* Meta Register Button */}
              <button
                onClick={handleMetaRegister}
                className="w-full flex items-center justify-center px-8 py-3 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
                disabled={isLoading || !fbLoaded}
              >
                <FaFacebook className="h-5 w-5 mr-2 text-blue-600" />
                Meta
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
