import React, { useState, useEffect, useContext, useRef } from 'react';
import { AuthContext } from '../../components/context/AuthContext';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
} from 'recharts';
import {
  DollarSign,
  Target,
  Eye,
  MousePointer,
  RefreshCw,
  Calendar,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  CalendarDays,
  Send,
  BarChart as BarChartIcon,
  MessageCircle,
  FileText,
  Download,
  Menu,
  X,
  Home,
  Settings,
  User,
  ChevronUp,
} from 'lucide-react';
import { format, subYears, addDays, subDays, subMonths, startOfMonth, endOfMonth, startOfYear } from 'date-fns';
import { enUS } from 'date-fns/locale';
import * as XLSX from 'xlsx';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import html2canvas from 'html2canvas';

const API_URL = process.env.REACT_APP_API_URL;

// Helper function to safely format numbers
const safeToLocaleString = (value) => {
  if (value === null || value === undefined) return '0';
  return value.toLocaleString('en-US', { maximumFractionDigits: 2 });
};

// Excel Export Function
const exportToExcel = (data, fileName) => {
  // Create workbook and add worksheets
  const wb = XLSX.utils.book_new();

  // Summary worksheet
  const summaryData = [
    ['Analytics Report', ''],
    ['Generated Date', format(new Date(), 'yyyy-MM-dd')],
    ['Period', `${format(data.startDate, 'yyyy-MM-dd')} to ${format(data.endDate, 'yyyy-MM-dd')}`],
    ['Data Source', data.source],
    ['', ''],
    ['Key Metrics', ''],
    ['Impressions', data.metrics.impressions],
    ['Clicks', data.metrics.clicks],
    ['Conversions/Purchases', data.source === 'Google Ads' ? data.metrics.conversions : data.metrics.website_purchases],
    ['Total Cost', `$${data.metrics.cost}`],
    ['CTR', `${(data.metrics.ctr || 0).toFixed(2)}%`],
    ['Average CPC', `$${data.source === 'Google Ads' ? data.metrics.average_cpc : data.metrics.cpc}`],
    ['Conversion Rate', `${(data.source === 'Google Ads' ? data.metrics.conversion_rate : data.metrics.cr || 0).toFixed(2)}%`],
    ['Cost per Conversion', `$${data.source === 'Google Ads' ? data.metrics.cost_per_conversion : (data.metrics.cost / (data.metrics.website_purchases || 1))}`],
    ['', ''],
    ['Projection Index', ''],
    ['Estimated Monthly Impressions', Math.round(data.metrics.impressions * (30 / data.daysDifference))],
    ['Estimated Monthly Clicks', Math.round(data.metrics.clicks * (30 / data.daysDifference))],
    ['Estimated Monthly Conversions', Math.round((data.source === 'Google Ads' ? data.metrics.conversions : data.metrics.website_purchases) * (30 / data.daysDifference))],
    ['Estimated Monthly Cost', `$${Math.round(data.metrics.cost * (30 / data.daysDifference))}`],
  ];

  const summaryWS = XLSX.utils.aoa_to_sheet(summaryData);
  XLSX.utils.book_append_sheet(wb, summaryWS, 'Summary');

  // Daily metrics worksheet - first convert the data
  const dailyHeaders = ['Date', 'Impressions', 'Clicks', data.source === 'Google Ads' ? 'Conversions' : 'Purchases', 'Cost', 'CTR'];
  const dailyData = [dailyHeaders];

  data.dailyMetrics.forEach(day => {
    const dateKey = data.source === 'Google Ads' ? 'date' : 'Date';
    const impressionsKey = data.source === 'Google Ads' ? 'impressions' : 'Impressions';
    const clicksKey = data.source === 'Google Ads' ? 'clicks' : 'Clicks';
    const convKey = data.source === 'Google Ads' ? 'conversions' : 'Website purchases';
    const costKey = data.source === 'Google Ads' ? 'cost' : 'Total Cost';
    const ctrKey = data.source === 'Google Ads' ? 'ctr' : 'CTR';

    dailyData.push([
      day[dateKey],
      day[impressionsKey],
      day[clicksKey],
      day[convKey],
      day[costKey],
      `${(day[ctrKey] || 0).toFixed(2)}%`
    ]);
  });

  const dailyWS = XLSX.utils.aoa_to_sheet(dailyData);
  XLSX.utils.book_append_sheet(wb, dailyWS, 'Daily Metrics');

  // Campaign metrics worksheet
  const campaignHeaders = ['Campaign', 'Impressions', 'Clicks', 'CTR', 'Cost', data.source === 'Google Ads' ? 'Conversions' : 'Purchases', 'Cost per Conversion'];
  const campaignData = [campaignHeaders];

  data.campaignMetrics.forEach(campaign => {
    const campaignName = campaign.campaign_name || campaign['Campaign name'];
    const impressions = campaign.impressions || campaign.Impressions;
    const clicks = campaign.clicks || campaign.Clicks;
    const ctr = campaign.ctr || campaign.CTR || 0;
    const cost = campaign.cost || campaign['Total Cost'];
    const conversions = campaign.conversions || campaign['Website purchases'] || 0;
    const costPerConversion = campaign.cost_per_conversion ||
      (cost && conversions ? cost / conversions : 0);

    campaignData.push([
      campaignName,
      impressions,
      clicks,
      `${ctr.toFixed(2)}%`,
      `$${cost}`,
      conversions,
      `$${costPerConversion.toFixed(2)}`
    ]);
  });

  const campaignWS = XLSX.utils.aoa_to_sheet(campaignData);
  XLSX.utils.book_append_sheet(wb, campaignWS, 'Campaign Metrics');

  // Write the workbook and trigger download
  XLSX.writeFile(wb, `${fileName}.xlsx`);
};

// PDF Export Function
const exportToPDF = async (data, chartRefs, fileName) => {
  try {
    // Create PDF document
    const pdf = new jsPDF('p', 'mm', 'a4');
    const width = pdf.internal.pageSize.getWidth();
    const height = pdf.internal.pageSize.getHeight();
    const margin = 10;
    let yPos = 10; // Start a bit higher to accommodate logo if any

    // Add header
    pdf.setFontSize(20);
    pdf.setTextColor(55, 55, 150);
    pdf.text(`${data.source} Analytics Report`, width / 2, yPos, { align: 'center' });
    yPos += 10;

    // Add date range
    pdf.setFontSize(12);
    pdf.setTextColor(70, 70, 70);
    pdf.text(`Period: ${format(data.startDate, 'yyyy-MM-dd')} to ${format(data.endDate, 'yyyy-MM-dd')}`, width / 2, yPos, { align: 'center' });
    yPos += 10;

    // Add generated date
    pdf.setFontSize(10);
    pdf.setTextColor(100, 100, 100);
    pdf.text(`Generated on: ${format(new Date(), 'yyyy-MM-dd HH:mm')}`, width / 2, yPos, { align: 'center' });
    yPos += 15;

    // Add key metrics
    pdf.setFontSize(14);
    pdf.setTextColor(50, 50, 50);
    pdf.text("Key Metrics", margin, yPos);
    yPos += 8;

    const metricsData = [
      ["Impressions", safeToLocaleString(data.metrics.impressions)],
      ["Clicks", safeToLocaleString(data.metrics.clicks)],
      [data.source === 'Google Ads' ? 'Conversions' : 'Purchases',
      safeToLocaleString(data.source === 'Google Ads' ? data.metrics.conversions : data.metrics.website_purchases)],
      ["Total Cost", `$${safeToLocaleString(data.metrics.cost)}`],
      ["CTR", `${(data.metrics.ctr || 0).toFixed(2)}%`],
      ["Average CPC", `$${safeToLocaleString(data.source === 'Google Ads' ? data.metrics.average_cpc : data.metrics.cpc)}`],
      ["Cost per Conversion", `$${safeToLocaleString(data.source === 'Google Ads' ?
        data.metrics.cost_per_conversion : (data.metrics.cost / (data.metrics.website_purchases || 1)))}`]
    ];

    autoTable(pdf, {
      startY: yPos,
      head: [['Metric', 'Value']],
      body: metricsData,
      theme: 'grid',
      styles: { fontSize: 10, cellPadding: 3 },
      headStyles: { fillColor: [151, 79, 248], textColor: [255, 255, 255] },
      margin: { left: margin, right: margin }
    });

    yPos = pdf.lastAutoTable.finalY + 15;

    // Add projection index
    pdf.setFontSize(14);
    pdf.setTextColor(50, 50, 50);
    pdf.text("Projection Index (Monthly Estimates)", margin, yPos);
    yPos += 8;

    const projectionData = [
      ["Impresiones Mensuales Estimadas", Math.round(data.metrics.impressions * (30 / data.daysDifference)).toLocaleString()],
      ["Clics Mensuales Estimados", Math.round(data.metrics.clicks * (30 / data.daysDifference)).toLocaleString()],
      ["Conversiones Mensuales Estimadas", Math.round((data.source === 'Google Ads' ?
        data.metrics.conversions : data.metrics.website_purchases) * (30 / data.daysDifference)).toLocaleString()],
      ["Costo Mensual Estimado", `$${Math.round(data.metrics.cost * (30 / data.daysDifference)).toLocaleString()}`]
    ];

    pdf.autoTable({
      startY: yPos,
      body: projectionData,
      theme: 'grid',
      styles: { fontSize: 10, cellPadding: 3 },
      alternateRowStyles: { fillColor: [245, 245, 255] },
      margin: { left: margin, right: margin }
    });

    yPos = pdf.lastAutoTable.finalY + 15;

    // Add charts (depends on available chartRefs)
    // Check if we need a new page for charts
    if (yPos > height - 100) {
      pdf.addPage();
      yPos = 20;
    }

    // Title for charts
    pdf.setFontSize(14);
    pdf.setTextColor(50, 50, 50);
    pdf.text("Gráficos de Rendimiento", margin, yPos);
    yPos += 10;

    // Convert the performance chart to an image if it exists
    if (chartRefs.performanceRef && chartRefs.performanceRef.current) {
      try {
        const performanceCanvas = await html2canvas(chartRefs.performanceRef.current);
        const performanceImgData = performanceCanvas.toDataURL('image/png');

        // Calculate the image size to fit the page width
        const imgWidth = width - 2 * margin;
        const imgHeight = (performanceCanvas.height * imgWidth) / performanceCanvas.width;

        // Check if we need a new page
        if (yPos + imgHeight > height - margin) {
          pdf.addPage();
          yPos = 20;
        }

        pdf.addImage(performanceImgData, 'PNG', margin, yPos, imgWidth, imgHeight);
        yPos += imgHeight + 15;
      } catch (error) {
        console.error("Error converting chart to image:", error);
      }
    }

    // Add campaign data
    if (yPos > height - 60) {
      pdf.addPage();
      yPos = 20;
    }

    pdf.setFontSize(14);
    pdf.setTextColor(50, 50, 50);
    pdf.text("Campaign Performance", margin, yPos);
    yPos += 8;

    // Prepare campaign data for the table
    const campaignTableHead = [['Campaign', 'Impressions', 'Clicks', 'CTR', 'Cost',
      data.source === 'Google Ads' ? 'Conv.' : 'Purch.',
      data.source === 'Google Ads' ? 'Cost/Conv.' : 'Cost/Purch.']];

    const campaignTableBody = data.campaignMetrics.map(campaign => {
      const campaignName = campaign.campaign_name || campaign['Campaign name'];
      const impressions = campaign.impressions || campaign.Impressions;
      const clicks = campaign.clicks || campaign.Clicks;
      const ctr = campaign.ctr || campaign.CTR || 0;
      const cost = campaign.cost || campaign['Total Cost'];
      const conversions = campaign.conversions || campaign['Website purchases'] || 0;
      const costPerConversion = campaign.cost_per_conversion ||
        (cost && conversions ? cost / conversions : 0);

      return [
        campaignName.length > 20 ? campaignName.substring(0, 20) + '...' : campaignName,
        safeToLocaleString(impressions),
        safeToLocaleString(clicks),
        `${ctr.toFixed(2)}%`,
        `$${safeToLocaleString(cost)}`,
        safeToLocaleString(conversions),
        `$${costPerConversion.toFixed(2)}`
      ];
    });

    pdf.autoTable({
      startY: yPos,
      head: campaignTableHead,
      body: campaignTableBody,
      theme: 'grid',
      styles: { fontSize: 9, cellPadding: 2 },
      headStyles: { fillColor: [15, 49, 247], textColor: [255, 255, 255] },
      margin: { left: margin, right: margin }
    });

    // Add footer with page numbers and company name
    const pageCount = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      pdf.setPage(i);
      pdf.setFontSize(8);
      pdf.setTextColor(120, 120, 120);
      // Bottom center for page number
      pdf.text(`Page ${i} of ${pageCount}`, width / 2, height - 10, { align: 'center' });
      // Bottom left for company name
      pdf.setTextColor(80, 80, 150);
      pdf.text('Espor.ai Analytics Report', margin, height - 10);
      // Bottom right for date
      pdf.setTextColor(120, 120, 120);
      pdf.text(format(new Date(), 'yyyy-MM-dd'), width - margin, height - 10, { align: 'right' });
    }

    // Save PDF
    pdf.save(`${fileName}.pdf`);
    return true;
  } catch (error) {
    console.error('Error generating PDF:', error);
    throw error;
  }
};

// Mobile-optimized Header Component with better positioning
const Header = ({ activeSource, setActiveSource, activeTab, setActiveTab, menuOpen, setMenuOpen }) => {
  return (
    <header className="bg-white p-3 sm:p-4 md:p-6 rounded-b-xl md:rounded-b-3xl shadow-sm border-b border-gray-200">
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          {/* Move hamburger button to right side of title for mobile */}
          <div>
            <h1 className="text-lg sm:text-xl md:text-3xl font-bold text-gray-800">Analytics</h1>
            <p className="text-xs md:text-sm text-gray-600 mt-0.5 md:mt-1">
              {activeSource === 'google' ? 'Google Ads' : 'Meta Ads'} - Campaign Analysis
            </p>
          </div>
          <button
            className="sm:hidden ml-2 p-1 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-200"
            onClick={() => setMenuOpen(!menuOpen)}
            style={{ marginLeft: '12px', zIndex: 5 }}
          >
            {menuOpen ? <X className="w-5 h-5 text-gray-700" /> : <Menu className="w-5 h-5 text-gray-700" />}
          </button>
        </div>
        <div className="flex space-x-1 sm:space-x-2 md:space-x-4">
  <div className="hidden sm:flex space-x-2 mr-2 md:mr-4">
    <button
      onClick={() => setActiveSource('google')}
      className={`px-2 py-1 md:px-4 md:py-2 text-xs md:text-sm rounded-xl transition-all duration-300 ${
        activeSource === 'google' 
          ? 'bg-[#2745F8] text-white' 
          : 'text-gray-600 hover:bg-gray-100'
      }`}
    >
      Google Ads
    </button>
    <button
      onClick={() => setActiveSource('meta')}
      className={`px-2 py-1 md:px-4 md:py-2 text-xs md:text-sm rounded-xl transition-all duration-300 ${
        activeSource === 'meta' 
          ? 'bg-[#2745F8] text-white' 
          : 'text-gray-600 hover:bg-gray-100'
      }`}
    >
      Meta Ads
    </button>
  </div>
  
  {/* Dashboard button only - Chat button removed */}
  {activeTab !== 'dashboard' && (
    <button 
      onClick={() => setActiveTab('dashboard')}
      className="px-2 py-1 sm:px-3 sm:py-1.5 md:px-4 md:py-2 rounded-xl bg-[#2745F8] text-white flex items-center gap-1 md:gap-2 shadow-md hover:bg-[#2745F8] transition-all duration-300 text-xs sm:text-sm"
    >
      <BarChartIcon className="w-3.5 h-3.5 sm:w-4 sm:h-4 md:w-5 md:h-5" />
      <span className="hidden sm:inline">Ver Dashboard</span>
    </button>
  )}
</div>

        </div>
    

      {/* Mobile source selector - improved for mobile */}
      {menuOpen && (
        <div className="sm:hidden mt-3 border-t border-gray-100 pt-3 animate-fade-in-up">
          <div className="flex flex-col space-y-2">
            <button
              onClick={() => {
                setActiveSource('google');
                setMenuOpen(false);
              }}
              className={`px-4 py-2 rounded-lg transition-all duration-300 text-left ${
                activeSource === 'google'
                  ? 'bg-[#2745F8] text-white'
                  : 'text-gray-600 hover:bg-gray-100'
              }`}
            >
              Google Ads
            </button>
            <button
              onClick={() => {
                setActiveSource('meta');
                setMenuOpen(false);
              }}
              className={`px-4 py-2 rounded-lg transition-all duration-300 text-left ${
                activeSource === 'meta'
                  ? 'bg-[#2745F8] text-white'
                  : 'text-gray-600 hover:bg-gray-100'
              }`}
            >
              Meta Ads
            </button>
          </div>
        </div>
      )}
    </header>
  );
};

// Mobile-optimized DateRangeSelector
const DateRangeSelector = ({ startDate, endDate, onStartDateChange, onEndDateChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const today = new Date();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const predefinedRanges = [
    {
      label: 'Últimos 7 días',
      range: [subDays(today, 6), today]
    },
    {
      label: 'Últimos 30 días',
      range: [subDays(today, 29), today]
    },
    {
      label: 'Este mes',
      range: [startOfMonth(today), today]
    },
    {
      label: 'Mes anterior',
      range: [startOfMonth(subMonths(today, 1)), endOfMonth(subMonths(today, 1))]
    },
    {
      label: 'Últimos 3 meses',
      range: [startOfMonth(subMonths(today, 3)), today]
    },
    {
      label: 'Último año',
      range: [subDays(today, 364), today]
    },
    {
      label: 'Este año',
      range: [startOfYear(today), today]
    }
  ];

  const handleSelectRange = (range) => {
    onStartDateChange(range[0]);
    onEndDateChange(range[1]);
    setIsOpen(false);
  };

  const formatShortDate = (date) => {
    return format(date, 'MM/dd/yy');
  };

  return (
    <div className="relative z-20 w-full sm:w-auto" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center gap-2 bg-white border border-gray-300 rounded-xl px-2 py-1.5 sm:px-3 sm:py-2 md:px-4 md:py-3 
                 text-gray-700 hover:border-[#2745F8] transition-all duration-300 w-full text-xs sm:text-sm"
      >
        <CalendarDays className="w-3.5 h-3.5 sm:w-4 sm:h-4 md:w-5 md:h-5 text-[#2745F8] flex-shrink-0" />
        <span className="font-medium">Periodos</span>
        <ChevronDown className={`w-3.5 h-3.5 sm:w-4 sm:h-4 ml-auto transition-transform duration-300 ${isOpen ? 'rotate-180' : ''}`} />
      </button>

      {isOpen && (
        <div className="absolute mt-1 sm:mt-2 right-0 bg-white rounded-xl shadow-lg 
                      border border-gray-200 py-1 sm:py-2 w-full sm:w-72 z-30">
          {predefinedRanges.map((range, index) => (
            <button
              key={index}
              onClick={() => handleSelectRange(range.range)}
              className="flex w-full items-center px-3 py-1.5 sm:px-4 sm:py-2 md:py-3 text-left text-gray-700 hover:bg-gray-50 
                       hover:text-[#2745F8] transition-colors duration-200 text-xs sm:text-sm"
            >
              <span>{range.label}</span>
              <span className="text-xs text-gray-500 ml-auto">
                {formatShortDate(range.range[0])} - {formatShortDate(range.range[1])}
              </span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

// Mobile-optimized MetricCard with original purple color
const MetricCard = ({ title, value = 0, icon: Icon, color = 'blue', prefix = '', suffix = '' }) => {
  const displayValue = value ?? 0;
  return (
    <div className="bg-[#2745F8] border border-[#2745F8] rounded-lg sm:rounded-xl md:rounded-2xl p-2.5 sm:p-4 md:p-6 flex flex-col items-center justify-center space-y-1 sm:space-y-2 md:space-y-4 shadow-sm hover:shadow-md transition-shadow">
      <Icon size={20} className="text-white sm:w-6 sm:h-6 md:w-10 md:h-10" />
      <div className="text-center">
        <p className="text-xs sm:text-sm md:text-lg font-bold text-white">{title}</p>
        <p className="text-sm sm:text-base md:text-2xl font-semibold text-white truncate max-w-full">
          {prefix}
          {safeToLocaleString(displayValue)}
          {suffix}
        </p>
      </div>
    </div>
  );
};

// Mobile-optimized DashboardFilter section
const DashboardFilter = ({ 
  startDate, 
  endDate, 
  setStartDate, 
  setEndDate, 
  loading, 
  fetchData, 
  handleExportToExcel, 
  handleExportToPDF 
}) => {
  return (
    <div className="bg-white rounded-lg md:rounded-xl shadow-sm border border-gray-200 p-2.5 sm:p-3 md:p-6">
      <div className="flex flex-col space-y-3 sm:flex-row sm:space-y-0 sm:justify-between sm:items-center mb-3 sm:mb-4 md:mb-6">
        <h2 className="text-base sm:text-lg md:text-xl font-semibold text-gray-800">Seleccionar periodo</h2>
        <DateRangeSelector
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={setStartDate}
          onEndDateChange={setEndDate}
        />
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4 md:gap-6">
        <CustomDatePicker
          selectedDate={startDate}
          onChange={setStartDate}
          label="Fecha de Inicio"
          maxDate={endDate}
        />

        <CustomDatePicker
          selectedDate={endDate}
          onChange={setEndDate}
          label="Fecha de Fin"
          maxDate={new Date()}
        />
      </div>

      <div className="mt-3 sm:mt-4 md:mt-6 flex flex-wrap justify-end gap-2 sm:gap-3">
        {/* PDF Export Button - Improved styling */}
        <button
          onClick={handleExportToPDF}
          className="px-3 py-2 sm:px-3 sm:py-2 md:px-6 md:py-3 bg-white border border-gray-300 rounded-xl shadow-sm
                   transition-all duration-300 flex items-center gap-1 md:gap-2 text-xs sm:text-sm md:text-base hover:border-[#2745F8] text-gray-700"
          disabled={loading}
        >
          <FileText className="w-3.5 h-3.5 sm:w-4 sm:h-4 md:w-5 md:h-5 text-[#2745F8]" />
          <span>PDF</span>
        </button>

        {/* Excel Export Button - Hidden on mobile to simplify UI */}
        <button
          onClick={handleExportToExcel}
          className="hidden sm:flex px-3 py-2 sm:px-3 sm:py-2 md:px-6 md:py-3 bg-white border border-gray-300 rounded-xl shadow-sm
                   transition-all duration-300 items-center gap-1 md:gap-2 text-xs sm:text-sm md:text-base hover:border-[#2745F8] text-gray-700"
          disabled={loading}
        >
          <FileText className="w-3.5 h-3.5 sm:w-4 sm:h-4 md:w-5 md:h-5 text-[#2745F8]" />
          <span>Excel</span>
        </button>

        {/* Update Data Button - Improved */}
        <button
          onClick={fetchData}
          className="px-3 py-2 sm:px-3 sm:py-2 md:px-6 md:py-3 bg-[#2745F8] text-white rounded-xl shadow-md hover:bg-[#9F55FF] 
                   transition-all duration-300 flex items-center gap-1 md:gap-2 text-xs sm:text-sm md:text-base"
          disabled={loading}
        >
          <RefreshCw className={`w-3.5 h-3.5 sm:w-4 sm:h-4 md:w-5 md:h-5 ${loading ? 'animate-spin' : ''}`} />
          <span>{loading ? 'Cargando...' : 'Actualizar'}</span>
        </button>
      </div>
    </div>
  );
};
// Mobile-optimized alternative that shows stats summary instead of charts
const MobileStatsContainer = ({ title, currentData, activeSource }) => {
  const getMetricSummary = () => {
    if (activeSource === 'google') {
      return [
        { name: 'Clics Diarios Prom.', value: Math.round(currentData.metrics.clicks / (currentData.daily_metrics?.length || 1)) },
        { name: 'Conversiones Diarias Prom.', value: Math.round(currentData.metrics.conversions / (currentData.daily_metrics?.length || 1)) },
        { name: 'Tasa de Conversión', value: `${(currentData.metrics.conversion_rate || 0).toFixed(2)}%` },
        { name: 'CPC Promedio', value: `$${(currentData.metrics.average_cpc || 0).toFixed(2)}` }
      ];
    } else {
      return [
        { name: 'Clics Diarios Prom.', value: Math.round(currentData.metrics.clicks / (currentData.daily_metrics?.length || 1)) },
        { name: 'Compras Diarias Prom.', value: Math.round(currentData.metrics.website_purchases / (currentData.daily_metrics?.length || 1)) },
      ];
    }
  };

  return (
    <div className="bg-white border border-gray-200 rounded-lg p-2.5 mt-3 shadow-sm sm:hidden">
      <h3 className="text-base font-bold text-gray-800 mb-2">{title}</h3>
      <div className="grid grid-cols-2 gap-2">
        {getMetricSummary().map((metric, index) => (
          <div key={index} className="bg-gray-50 p-2 rounded">
            <div className="text-xs text-gray-600">{metric.name}</div>
            <div className="text-base font-semibold text-[#2745F8]">{metric.value}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

const ChartContainer = ({ title, children, innerRef }) => (
  <div className="bg-white border border-gray-200 rounded-lg sm:rounded-xl md:rounded-2xl p-2.5 sm:p-4 md:p-6 mt-3 sm:mt-4 md:mt-6 shadow-sm hidden sm:block" ref={innerRef}>
    <h3 className="text-base sm:text-lg md:text-xl font-bold text-gray-800 mb-2 sm:mb-3 md:mb-4">{title}</h3>
    {children}
  </div>
);

// Mobile-optimized for Campaign Metrics Table
const CampaignMetricsTable = ({ currentData, activeSource }) => {
  return (
    <div className="overflow-x-auto -mx-2.5 sm:-mx-4 md:mx-0">
      <table className="w-full text-left text-xs md:text-sm">
        <thead>
          <tr className="bg-gray-50">
            <th className="text-xs md:text-sm font-bold text-gray-700 py-1.5 sm:py-2 px-2 sm:px-3 md:px-4">Campaign</th>
            <th className="text-xs md:text-sm font-bold text-gray-700 py-1.5 sm:py-2 px-1.5 sm:px-2 md:px-4">Impr.</th>
            <th className="text-xs md:text-sm font-bold text-gray-700 py-1.5 sm:py-2 px-1.5 sm:px-2 md:px-4">Clicks</th>
            <th className="text-xs md:text-sm font-bold text-gray-700 py-1.5 sm:py-2 px-1.5 sm:px-2 md:px-4">CTR</th>
            <th className="text-xs md:text-sm font-bold text-gray-700 py-1.5 sm:py-2 px-1.5 sm:px-2 md:px-4">Cost</th>
            <th className="text-xs md:text-sm font-bold text-gray-700 py-1.5 sm:py-2 px-1.5 sm:px-2 md:px-4">
              {activeSource === 'google' ? 'Conv.' : 'Purch.'}
            </th>
            <th className="text-xs md:text-sm font-bold text-gray-700 py-1.5 sm:py-2 px-1.5 sm:px-2 md:px-4">
              {activeSource === 'google' ? 'C/Conv.' : 'C/Pur.'}
            </th>
          </tr>
        </thead>
        <tbody>
          {currentData.campaign_metrics.map((campaign, index) => (
            <tr key={index} className="border-t border-gray-200 hover:bg-gray-50">
              <td className="py-1.5 sm:py-2 px-2 sm:px-3 md:px-4 text-gray-800 truncate max-w-[80px] sm:max-w-[100px] md:max-w-[200px]">
                {campaign.campaign_name || campaign['Campaign name']}
              </td>
              <td className="py-1.5 sm:py-2 px-1.5 sm:px-2 md:px-4 text-gray-800">{safeToLocaleString(campaign.impressions || campaign.Impressions)}</td>
              <td className="py-1.5 sm:py-2 px-1.5 sm:px-2 md:px-4 text-gray-800">{safeToLocaleString(campaign.clicks || campaign.Clicks)}</td>
              <td className="py-1.5 sm:py-2 px-1.5 sm:px-2 md:px-4 text-gray-800">{(campaign.ctr || campaign.CTR || 0).toFixed(2)}%</td>
              <td className="py-1.5 sm:py-2 px-1.5 sm:px-2 md:px-4 text-gray-800">${safeToLocaleString(campaign.cost || campaign['Total Cost'])}</td>
              <td className="py-1.5 sm:py-2 px-1.5 sm:px-2 md:px-4 text-gray-800">
                {safeToLocaleString(
                  campaign.conversions || campaign['Website purchases'] || 0
                )}
              </td>
              <td className="py-1.5 sm:py-2 px-1.5 sm:px-2 md:px-4 text-gray-800">
                ${safeToLocaleString(
                  campaign.cost_per_conversion ||
                  (campaign['Total Cost'] && campaign['Website purchases']
                    ? campaign['Total Cost'] / campaign['Website purchases']
                    : 0)
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

// Mobile-optimized CustomDatePicker Component
const CustomDatePicker = ({ selectedDate, onChange, label, maxDate }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(selectedDate || new Date());
  const calendarRef = useRef(null);

  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const dayNames = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const formatDate = (date) => {
    if (!date) return '';
    return format(date, 'MM/dd/yyyy');
  };

  const handlePrevMonth = () => {
    const prevMonth = new Date(currentMonth);
    prevMonth.setMonth(prevMonth.getMonth() - 1);
    setCurrentMonth(prevMonth);
  };

  const handleNextMonth = () => {
    const nextMonth = new Date(currentMonth);
    nextMonth.setMonth(nextMonth.getMonth() + 1);

    // Don't allow advancing beyond the current month if maxDate is the current date
    if (maxDate && nextMonth > maxDate) return;

    setCurrentMonth(nextMonth);
  };

  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (year, month) => {
    return new Date(year, month, 1).getDay();
  };

  const isToday = (date) => {
    const today = new Date();
    return date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();
  };

  const isSameDay = (date1, date2) => {
    if (!date1 || !date2) return false;
    return date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear();
  };

  const isDisabled = (date) => {
    if (maxDate && date > maxDate) return true;
    return false;
  };

  const renderCalendar = () => {
    const year = currentMonth.getFullYear();
    const month = currentMonth.getMonth();
    const daysInMonth = getDaysInMonth(year, month);
    const firstDayOfMonth = getFirstDayOfMonth(year, month);

    const days = [];

    // Days from previous month
    for (let i = firstDayOfMonth; i > 0; i--) {
      const prevMonthDays = month === 0
        ? getDaysInMonth(year - 1, 11)
        : getDaysInMonth(year, month - 1);

      const day = prevMonthDays - i + 1;
      const date = new Date(year, month - 1, day);

      days.push(
        <button
          key={`prev-${day}`}
          onClick={() => {
            if (!isDisabled(date)) onChange(date);
            setIsOpen(false);
          }}
          disabled={isDisabled(date)}
          className={`h-7 w-7 sm:h-8 sm:w-8 md:h-9 md:w-9 rounded-full text-gray-400 hover:bg-purple-50 disabled:hover:bg-transparent disabled:opacity-30 text-xs sm:text-sm`}
        >
          {day}
        </button>
      );
    }

    // Days of current month
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, month, day);
      const isCurrentDay = isToday(date);
      const isSelected = isSameDay(date, selectedDate);
      const disabled = isDisabled(date);

      days.push(
        <button
          key={`current-${day}`}
          onClick={() => {
            if (!disabled) {
              onChange(date);
              setIsOpen(false);
            }
          }}
          disabled={disabled}
          className={`h-7 w-7 sm:h-8 sm:w-8 md:h-9 md:w-9 rounded-full text-xs sm:text-sm
            ${isSelected ? 'bg-[#2745F8] text-white hover:bg-[#9F55FF]' :
              isCurrentDay ? 'bg-purple-100 text-[#2745F8] font-semibold' :
                'text-gray-800 hover:bg-purple-50'} 
            ${disabled ? 'opacity-30 cursor-not-allowed' : ''}
            transition-colors`}
        >
          {day}
        </button>
      );
    }

    // Days of next month
    const totalCells = 42; // 6 rows x 7 days
    const remainingCells = totalCells - days.length;

    for (let day = 1; day <= remainingCells; day++) {
      const date = new Date(year, month + 1, day);
      days.push(
        <button
          key={`next-${day}`}
          onClick={() => {
            if (!isDisabled(date)) onChange(date);
            setIsOpen(false);
          }}
          disabled={isDisabled(date)}
          className={`h-7 w-7 sm:h-8 sm:w-8 md:h-9 md:w-9 rounded-full text-gray-400 hover:bg-purple-50 disabled:hover:bg-transparent disabled:opacity-30 text-xs sm:text-sm`}
        >
          {day}
        </button>
      );
    }

    return days;
  };

  return (
    <div className="relative" ref={calendarRef}>
      <label className="block text-xs sm:text-sm font-medium text-gray-700 mb-1 sm:mb-2">{label}</label>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="w-full bg-white border border-gray-300 rounded-lg sm:rounded-xl px-2 py-1.5 sm:px-3 sm:py-2 md:px-4 md:py-3 text-gray-800 
                 focus-within:ring-2 focus-within:ring-[#2745F8] focus-within:border-[#2745F8] 
                 transition-all duration-300 flex items-center cursor-pointer"
      >
        <Calendar className="w-3.5 h-3.5 sm:w-4 sm:h-4 md:w-5 md:h-5 text-[#2745F8] mr-1.5 sm:mr-2 md:mr-3 flex-shrink-0" />
        <span className="text-xs sm:text-sm md:text-base truncate">{formatDate(selectedDate)}</span>
      </div>

      {isOpen && (
        <div className="absolute z-30 mt-1 sm:mt-2 bg-white rounded-lg sm:rounded-xl shadow-xl border border-gray-200 p-2 sm:p-3 md:p-4 w-[250px] sm:w-[280px] md:w-[320px] left-0 right-0 mx-auto sm:mx-0">
          <div className="flex justify-between items-center mb-2 sm:mb-3 md:mb-4">
            <button
              onClick={handlePrevMonth}
              className="p-1 rounded-full hover:bg-gray-100"
            >
              <ChevronLeft className="w-3.5 h-3.5 sm:w-4 sm:h-4 md:w-5 md:h-5 text-gray-600" />
            </button>

            <h3 className="font-semibold text-xs sm:text-sm md:text-base text-gray-800">
              {monthNames[currentMonth.getMonth()]} {currentMonth.getFullYear()}
            </h3>

            <button
              onClick={handleNextMonth}
              className="p-1 rounded-full hover:bg-gray-100"
              disabled={maxDate && currentMonth.getMonth() === maxDate.getMonth() && currentMonth.getFullYear() === maxDate.getFullYear()}
            >
              <ChevronRight className="w-3.5 h-3.5 sm:w-4 sm:h-4 md:w-5 md:h-5 text-gray-600" />
            </button>
          </div>

          <div className="grid grid-cols-7 gap-1 mb-1 md:mb-2">
            {dayNames.map(day => (
              <div key={day} className="h-5 sm:h-6 md:h-9 flex items-center justify-center text-xs font-medium text-gray-500">
                {day}
              </div>
            ))}
          </div>

          <div className="grid grid-cols-7 gap-1">
            {renderCalendar()}
          </div>

          <div className="mt-2 sm:mt-3 md:mt-4 flex justify-between">
            <button
              onClick={() => {
                onChange(new Date());
                setIsOpen(false);
              }}
              className="text-xs md:text-sm text-[#2745F8] hover:text-[#9F55FF] font-medium"
            >
              Today
            </button>

            <button
              onClick={() => setIsOpen(false)}
              className="text-xs md:text-sm text-gray-600 hover:text-gray-800 font-medium"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
// Selector de fuente mejorado con diseño responsive
const ChatSourceSelector = ({ activeSource, setActiveSource }) => {
  return (
    <div className="mb-4 w-full">
      <div className="bg-purple-50 p-1.5 sm:p-2 rounded-xl inline-flex w-full shadow-sm">
        <button
          onClick={() => setActiveSource('all')}
          className={`px-3 py-2 rounded-lg text-xs sm:text-sm font-medium transition-all duration-200 ${
            activeSource === 'all'
              ? 'bg-[#2745F8] text-white shadow-md'
              : 'bg-transparent text-gray-700 hover:bg-purple-100'
            } flex-1 mx-1`}
        >
          Todas
        </button>

        <button
          onClick={() => setActiveSource('google')}
          className={`px-3 py-2 rounded-lg text-xs sm:text-sm font-medium transition-all duration-200 ${
            activeSource === 'google'
              ? 'bg-[#2745F8] text-white shadow-md'
              : 'bg-transparent text-gray-700 hover:bg-purple-100'
          } flex-1 mx-1`}
        >
          Google Ads
        </button>

        <button
          onClick={() => setActiveSource('meta')}
          className={`px-3 py-2 rounded-lg text-xs sm:text-sm font-medium transition-all duration-200 ${
            activeSource === 'meta'
              ? 'bg-[#2745F8] text-white shadow-md'
              : 'bg-transparent text-gray-700 hover:bg-purple-100'
            } flex-1 mx-1`}
        >
          Meta Ads
        </button>
      </div>
    </div>
  );
};

// Componente de mensajes rápidos rediseñado para responsive mejorado
const EnhancedQuickMessages = ({ onSelectMessage }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  
  // Mensajes básicos (siempre visibles)
  const basicMessages = [
    {
      title: "Resumen",
      icon: "📊",
      message: "Dame un resumen completo de mis campañas, costos, tasa de conversion, roi, etc."
    },
    {
      title: "Tráfico",
      icon: "🔍",
      message: "Analiza mi tráfico web detalladamente"
    },
    {
      title: "Conversiones",
      icon: "🎯",
      message: "Necesito un análisis profundo de conversiones"
    },
    {
      title: "Campañas",
      icon: "💰",
      message: "Analiza el rendimiento de mis campañas de marketing:\n\n• Top 3 campañas por ROAS\n• Top 3 campañas por CTR\n• Top 3 campañas por tasa de conversión\n• Campañas con bajo rendimiento y cómo optimizarlas\n• ROAS general y por plataforma"
    }
  ];const advancedMessages = [
    
  ];

  // Versión móvil - mostrar solo 2 columnas y menos elementos
  const mobileBasicMessages = basicMessages.slice(0, 4);
  const mobileAdvancedMessages = advancedMessages.slice(0, 4);
  
  const allMessages = isExpanded ? 
    (window.innerWidth < 640 ? [...mobileBasicMessages, ...mobileAdvancedMessages] : [...basicMessages, ...advancedMessages]) : 
    (window.innerWidth < 640 ? mobileBasicMessages : basicMessages);

  return (
    <div className="w-full mb-3">
      <div className="flex justify-between items-center mb-2">
        <div className="flex items-center">
          <div className="bg-purple-100 text-purple-800 p-1 rounded-lg mr-2">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 sm:h-4 sm:w-4" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
            </svg>
          </div>
          <p className="text-xs sm:text-sm font-medium text-gray-700">Consultas rápidas</p>
        </div>
        <button 
          onClick={() => setIsExpanded(!isExpanded)}
          className="text-xs sm:text-sm font-medium text-purple-600 bg-purple-50 px-2 py-1 rounded-lg flex items-center hover:bg-purple-100 transition-colors shadow-sm"
        >
          {isExpanded ? (
            <>
              Menos opciones
              <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 ml-1" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clipRule="evenodd" />
              </svg>
            </>
          ) : (
            <>
              Más opciones
              <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 ml-1" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </>
          )}
        </button>
      </div>
      
      {/* Contenedor con scroll horizontal para dispositivos móviles pequeños */}
      <div className={`grid grid-cols-2 sm:grid-cols-4 gap-2 ${isExpanded ? 'h-auto max-h-64 sm:max-h-none overflow-y-auto pb-2' : 'h-auto'}`}>
        {allMessages.map((item, index) => (
          <button
            key={index}
            onClick={() => onSelectMessage(item.message)}
            className="p-2 sm:p-3 bg-white border border-purple-100 rounded-xl text-xs sm:text-sm text-gray-700 
                     hover:bg-purple-50 hover:border-purple-300 hover:text-purple-700 
                     transition-all duration-200 shadow-sm hover:shadow-md flex flex-col items-center
                     h-16 sm:h-24 quick-message-btn"
          >
            <span className="text-lg sm:text-xl mb-1 sm:mb-2">{item.icon}</span>
            <span className="font-medium text-center line-clamp-1">{item.title}</span>
          </button>
        ))}
      </div>
    </div>
  );
};
const ChatInput = ({ 
  currentMessage, 
  setCurrentMessage, 
  handleSendMessage, 
  loading, 
  onQuickMessageSelect,
  chatActiveSource,
  googleDataAvailable,
  metaDataAvailable
}) => {
  const textareaRef = useRef(null);

  // Check if data is available based on current source
  const isDataAvailable = () => {
    if (chatActiveSource === 'google') return googleDataAvailable;
    if (chatActiveSource === 'meta') return metaDataAvailable;
    if (chatActiveSource === 'all') return googleDataAvailable || metaDataAvailable;
    return false;
  };

  // Get message based on data availability
  const getPlaceholderMessage = () => {
    if (!googleDataAvailable && !metaDataAvailable) {
      return "No hay datos disponibles. Por favor, carga los datos de análisis primero.";
    }
    
    if (chatActiveSource === 'google' && !googleDataAvailable) {
      return "No hay datos de Google Ads disponibles. Cambia a Meta Ads o carga los datos primero.";
    }
    
    if (chatActiveSource === 'meta' && !metaDataAvailable) {
      return "No hay datos de Meta Ads disponibles. Cambia a Google Ads o carga los datos primero.";
    }
    
    if (chatActiveSource === 'all' && (!googleDataAvailable || !metaDataAvailable)) {
      const availableSource = googleDataAvailable ? "Google Ads" : "Meta Ads";
      const unavailableSource = googleDataAvailable ? "Meta Ads" : "Google Ads";
      return `Solo hay datos de ${availableSource} disponibles. Los datos de ${unavailableSource} no están cargados.`;
    }
    
    return "Escribe tu pregunta aquí...";
  };

  // Manejar tecla Enter
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey && isDataAvailable()) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  // Ajustar altura del textarea según contenido
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = `${Math.min(scrollHeight, 100)}px`; // Reducido a 100px máximo
    }
  }, [currentMessage]);

  return (
    <div className="w-full">
      {/* Componente de Mensajes Rápidos - solo visible si hay datos disponibles */}
      {isDataAvailable() && (
        <EnhancedQuickMessages onSelectMessage={onQuickMessageSelect} />
      )}
      
      <div className={`relative chat-input overflow-hidden border border-gray-200 rounded-xl shadow-md transition-shadow ${
        isDataAvailable() ? 'focus-within:border-purple-400 hover:shadow-lg' : 'bg-gray-50 border-gray-200'
      }`}>
        <textarea
          ref={textareaRef}
          value={currentMessage}
          onChange={(e) => isDataAvailable() && setCurrentMessage(e.target.value)}
          onKeyDown={handleKeyPress}
          placeholder={getPlaceholderMessage()}
          rows={1}
          className={`flex-1 px-3 py-2.5 text-xs sm:text-sm resize-none outline-none w-full ${
            isDataAvailable() ? 'text-gray-800 placeholder-gray-400' : 'text-gray-500 placeholder-gray-500 bg-gray-50'
          }`}
          style={{ maxHeight: '100px' }}
          disabled={!isDataAvailable()}
        />

        {isDataAvailable() && (
          <div className="absolute bottom-2 right-2.5 flex space-x-2">
            <button
              onClick={() => setCurrentMessage('')}
              className={`p-1.5 rounded-md text-gray-400 hover:text-gray-600 hover:bg-gray-100 transition-colors ${!currentMessage ? 'opacity-0' : 'opacity-100'}`}
              disabled={!currentMessage}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>

            <button
              onClick={handleSendMessage}
              disabled={loading || !currentMessage.trim()}
              className="send-button p-1.5 rounded-md text-white disabled:opacity-50 disabled:bg-purple-300 disabled:cursor-not-allowed bg-[#2745F8] hover:bg-[#2745F8] transition-colors shadow-sm"
            >
              {loading ? (
                <RefreshCw className="w-4 h-4 animate-spin" />
              ) : (
                <Send className="w-4 h-4" />
              )}
            </button>
          </div>
        )}
        
        {/* Data unavailable overlay message for small screens */}
        {!isDataAvailable() && (
          <div className="sm:hidden absolute inset-0 flex items-center justify-center bg-gray-50 bg-opacity-80 pointer-events-none">
            <div className="text-xs text-center text-gray-500 px-3">
              <RefreshCw className="w-4 h-4 mx-auto mb-1" />
              Primero carga los datos
            </div>
          </div>
        )}
      </div>

      <div className="mt-2 flex justify-between items-center text-xxs sm:text-xs text-gray-500">
        {isDataAvailable() ? (
          <span className="text-gray-500 italic">Shift+Enter para nueva línea</span>
        ) : (
          <span className="text-gray-500 italic">
            Debes cargar {
              chatActiveSource === 'google' ? 'los datos de Google Ads' : 
              chatActiveSource === 'meta' ? 'los datos de Meta Ads' : 
              'los datos de análisis'
            } primero
          </span>
        )}
        <span className="text-[#2745F8] font-medium">Powered by Espor.ai</span>
      </div>
    </div>
  );
};
const ChatInterface = ({
  chatActiveSource,
  setChatActiveSource,
  chatMessages,
  currentMessage,
  setCurrentMessage,
  handleSendMessage,
  loading,
  messagesRemaining,
  resetTimeMinutes,
  // Add new props for data availability
  googleDataAvailable,
  metaDataAvailable
}) => {
  const messagesEndRef = useRef(null);

  // Auto-scroll cuando hay nuevos mensajes
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatMessages]);

  // Manejar selección de mensaje rápido
  const handleQuickMessageSelect = (message) => {
    setCurrentMessage(message);
    // Opcional: dar foco al textarea
    setTimeout(() => {
      const textarea = document.querySelector('textarea');
      if (textarea) textarea.focus();
    }, 100);
  };

  // Check if any data is available for the current source
  const hasDataForSource = () => {
    if (chatActiveSource === 'google') return googleDataAvailable;
    if (chatActiveSource === 'meta') return metaDataAvailable;
    if (chatActiveSource === 'all') return googleDataAvailable || metaDataAvailable;
    return false;
  };

  return (
    <div className="flex flex-col h-[calc(100vh-130px)] sm:h-[calc(100vh-150px)] md:h-[calc(100vh-180px)] bg-white rounded-xl p-3 sm:p-4 md:p-5 shadow-lg border border-gray-200 relative">
      {/* Source selector for chat - disable options where data is not available */}
      <div className="mb-4 w-full">
        <div className="bg-purple-50 p-1.5 sm:p-2 rounded-xl inline-flex w-full shadow-sm">
          <button
            onClick={() => setChatActiveSource('all')}
            className={`px-3 py-2 rounded-lg text-xs sm:text-sm font-medium transition-all duration-200 ${
              chatActiveSource === 'all'
                ? 'bg-[#2745F8] text-white shadow-md'
                : 'bg-transparent text-gray-700 hover:bg-purple-100'
              } flex-1 mx-1 ${!googleDataAvailable && !metaDataAvailable ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={!googleDataAvailable && !metaDataAvailable}
          >
            Todas
          </button>

          <button
            onClick={() => setChatActiveSource('google')}
            className={`px-3 py-2 rounded-lg text-xs sm:text-sm font-medium transition-all duration-200 ${
              chatActiveSource === 'google'
                ? 'bg-[#2745F8] text-white shadow-md'
                : 'bg-transparent text-gray-700 hover:bg-purple-100'
            } flex-1 mx-1 ${!googleDataAvailable ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={!googleDataAvailable}
          >
            Google Ads
          </button>

          <button
            onClick={() => setChatActiveSource('meta')}
            className={`px-3 py-2 rounded-lg text-xs sm:text-sm font-medium transition-all duration-200 ${
              chatActiveSource === 'meta'
                ? 'bg-[#2745F8] text-white shadow-md'
                : 'bg-transparent text-gray-700 hover:bg-purple-100'
              } flex-1 mx-1 ${!metaDataAvailable ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={!metaDataAvailable}
          >
            Meta Ads
          </button>
        </div>
      </div>

      <div className="mb-3 sm:mb-4 text-xs sm:text-sm text-gray-600 flex justify-between items-center bg-gray-50 p-2.5 sm:p-3 rounded-lg shadow-sm">
        <span>
          Mensajes restantes: <span className="font-bold text-[#2745F8]">{messagesRemaining}</span>
        </span>
        {resetTimeMinutes > 0 && (
          <span className="bg-purple-50 text-purple-700 px-2 py-1 sm:px-3 sm:py-1.5 rounded-md shadow-sm text-xs">
            Reinicio en {Math.floor(resetTimeMinutes / 60)}h {resetTimeMinutes % 60}m
          </span>
        )}
      </div>

      {/* Mensaje de bienvenida */}
      {chatMessages.length === 0 && (
        <ChatWelcome 
          activeSource={chatActiveSource} 
          googleDataAvailable={googleDataAvailable}
          metaDataAvailable={metaDataAvailable}
        />
      )}

      {/* Mensajes del chat - Ajustado para dejar espacio para el input */}
      <div className="flex-grow overflow-y-auto mb-3 sm:mb-4 p-1 sm:p-2 space-y-0 custom-scrollbar flex flex-col">
        {chatMessages.map((msg, index) => {
          // Si es un mensaje en procesamiento, utilizar el componente especializado
          if (msg.isProcessing) {
            return <ProcessingMessage key={index} thinking={msg.thinking} />;
          }

          // Para mensajes normales
          return (
            <ChatMessage
              key={index}
              message={msg.text}
              isUser={msg.isUser}
              thinking={msg.thinking}
            />
          );
        })}

        <div ref={messagesEndRef} />
      </div>

      {/* Área de entrada con mensajes rápidos - Posicionado en la parte inferior */}
      <div className="mt-auto pb-2">
        <ChatInput
          currentMessage={currentMessage}
          setCurrentMessage={setCurrentMessage}
          handleSendMessage={handleSendMessage}
          loading={loading}
          onQuickMessageSelect={handleQuickMessageSelect}
          chatActiveSource={chatActiveSource}
          googleDataAvailable={googleDataAvailable}
          metaDataAvailable={metaDataAvailable}
        />
      </div>
    </div>
  );
};

// Componente ChatMessage mejorado con mejor formato para mensajes
const ChatMessage = ({ message, isUser, thinking }) => {
  // Función para procesar y formatear el contenido del mensaje
  const processContent = (content) => {
    if (typeof content !== 'string') return content;

    // Procesar contenido por líneas
    return content.split('\n').map((line, lineIndex) => {
      // Procesar encabezados (# Encabezado)
      if (line.match(/^#\s+(.+)$/)) {
        return (
          <h3 key={lineIndex} className="text-sm sm:text-base font-bold text-gray-800 my-1.5 sm:my-2">
            {line.replace(/^#\s+/, '')}
          </h3>
        );
      }
      
      // Procesar subencabezados (## Subencabezado)
      if (line.match(/^##\s+(.+)$/)) {
        return (
          <h4 key={lineIndex} className="text-xs sm:text-sm font-semibold text-gray-700 my-1.5 sm:my-2">
            {line.replace(/^##\s+/, '')}
          </h4>
        );
      }

      // Procesar viñetas (• Ítem o - Ítem)
      if (line.match(/^[•\-]\s+(.+)$/)) {
        return (
          <div key={lineIndex} className="flex items-start my-1 sm:my-1.5">
            <span className="text-[#2745F8] mr-2 mt-0.5">•</span>
            <span className="text-xs sm:text-sm">{line.replace(/^[•\-]\s+/, '')}</span>
          </div>
        );
      }

      // Procesar viñetas anidadas (  • Ítem)
      if (line.match(/^\s+[•\-]\s+(.+)$/)) {
        return (
          <div key={lineIndex} className="flex items-start my-1 sm:my-1.5 ml-4 sm:ml-5">
            <span className="text-purple-400 mr-2 mt-0.5">◦</span>
            <span className="text-xs sm:text-sm">{line.replace(/^\s+[•\-]\s+/, '')}</span>
          </div>
        );
      }

      // Procesar listas numeradas (1. Ítem)
      const numberedListMatch = line.match(/^(\d+)\.\s+(.+)$/);
      if (numberedListMatch) {
        return (
          <div key={lineIndex} className="flex items-start my-1 sm:my-1.5">
            <span className="text-[#2745F8] mr-2 min-w-[20px] text-right font-medium">{numberedListMatch[1]}.</span>
            <span className="text-xs sm:text-sm">{numberedListMatch[2]}</span>
          </div>
        );
      }

      // Línea en blanco
      if (line.trim() === '') {
        return <div key={lineIndex} className="py-1 sm:py-1.5"></div>;
      }

      // Procesar texto en negrita/cursiva
      const boldMatches = [...line.matchAll(/\*\*(.*?)\*\*/g)];
      if (boldMatches.length > 0) {
        let lastIndex = 0;
        let segments = [];

        boldMatches.forEach((match, i) => {
          if (match.index > lastIndex) {
            segments.push(<span key={`${lineIndex}-text-${i}`}>{line.substring(lastIndex, match.index)}</span>);
          }
          segments.push(<strong key={`${lineIndex}-bold-${i}`} className="text-gray-900">{match[1]}</strong>);
          lastIndex = match.index + match[0].length;
        });

        if (lastIndex < line.length) {
          segments.push(<span key={`${lineIndex}-text-last`}>{line.substring(lastIndex)}</span>);
        }

        return <p key={lineIndex} className="text-xs sm:text-sm my-1 sm:my-1.5">{segments}</p>;
      }

      // Procesar secciones especiales como "KPI: valor"
      if (line.includes(':') && !line.includes('|')) {
        const parts = line.split(':');
        if (parts.length === 2 && parts[0].length < 30) {
          return (
            <div key={lineIndex} className="my-1 sm:my-1.5 flex">
              <span className="font-semibold text-gray-800 text-xs sm:text-sm">{parts[0]}:</span>
              <span className="text-xs sm:text-sm ml-1.5">{parts[1]}</span>
            </div>
          );
        }
      }

      // Línea normal
      return <p key={lineIndex} className="text-xs sm:text-sm my-1 sm:my-1.5">{line}</p>;
    });
  };

  return (
    <div
      className={`p-3 sm:p-4 max-w-[90%] sm:max-w-[85%] my-2 sm:my-3 ${
        isUser
          ? 'user-bubble self-end ml-auto'
          : 'assistant-bubble self-start mr-auto'
        }`}
    >
      <div className="leading-relaxed">
        {isUser ? (
          <p className="text-xs sm:text-sm">{message}</p>
        ) : (
          <>
            {/* Si hay un proceso de pensamiento disponible y no es de un usuario, mostrarlo primero */}
            {thinking && !isUser && (
              <ThinkingProcess content={thinking} />
            )}

            {/* Luego mostrar el mensaje principal con formato mejorado */}
            <div className="message-content">
              {processContent(message)}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
// Mobile-optimized ThinkingProcess
const ThinkingProcess = ({ content }) => {
  const [expanded, setExpanded] = useState(false);
  const [animation, setAnimation] = useState(false);

  // Animate when content changes
  useEffect(() => {
    if (content) {
      setAnimation(true);
      const timer = setTimeout(() => setAnimation(false), 800);
      return () => clearTimeout(timer);
    }
  }, [content]);

  // If no content, don't show anything
  if (!content) return null;

  // Function to format the content
  const formatThinkingContent = (content) => {
    if (!content) return null;
    // Convert markdown table to HTML if it exists
    let formattedContent = content;

    if (content.includes('|---------|')) {
      // It's a markdown table
      const tableRows = content.split('\n').filter(line => line.trim().startsWith('|'));

      if (tableRows.length >= 3) { // Header, separator, and at least one data row
        const tableHTML = (
          <div className="overflow-x-auto mt-1 sm:mt-2 mb-1.5 sm:mb-3">
            <table className="min-w-full divide-y divide-gray-200 border border-gray-200 rounded text-xs">
              <thead className="bg-gray-50">
                <tr>
                  {tableRows[0].split('|').filter(cell => cell.trim()).map((header, idx) => (
                    <th key={idx} className="px-1.5 sm:px-2 py-1 text-xxs sm:text-xs font-medium text-gray-500 uppercase tracking-wider text-left">
                      {header.trim()}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {tableRows.slice(2).map((row, rowIdx) => (
                  <tr key={rowIdx} className={rowIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    {row.split('|').filter(cell => cell.trim()).map((cell, cellIdx) => (
                      <td key={cellIdx} className="px-1.5 sm:px-2 py-0.5 sm:py-1 text-xxs sm:text-xs text-gray-900">
                        {cell.trim()}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );

        // Remove the original table from the text
        const tableStart = content.indexOf('| Métrica |');
        const tableEnd = content.lastIndexOf('|') + 1;
        if (tableStart > -1 && tableEnd > tableStart) {
          const beforeTable = content.substring(0, tableStart);
          const afterTable = content.substring(tableEnd);

          return (
            <>
              {processContent(beforeTable)}
              {tableHTML}
              {processContent(afterTable)}
            </>
          );
        }
      }
    }

    // If there's no table or it couldn't be processed, format normally
    return processContent(content);
  };

  // Function to process Markdown format and line breaks
  const processContent = (content) => {
    if (typeof content !== 'string') return content;

    // Process content by lines
    return content.split('\n').map((line, lineIndex) => {
      // Process headers
      if (line.match(/^#\s+(.+)$/)) {
        return <h3 key={lineIndex} className="text-xs sm:text-sm font-semibold text-gray-800">{line.replace(/^#\s+/, '')}</h3>;
      }

      // Process lists - Bullet points
      if (line.match(/^[*-]\s+(.+)$/)) {
        const bulletText = line.replace(/^[*-]\s+/, '');

        // If text starts with an emoji or ✅/❌, highlight it
        if (bulletText.match(/^(✅|❌|🔍|📊|🧠|🔄)/)) {
          const [emoji, ...rest] = bulletText.split(' ');
          return (
            <div key={lineIndex} className="flex items-start space-x-1 sm:space-x-2 my-0.5 sm:my-1">
              <span className="text-sm sm:text-base md:text-lg">{emoji}</span>
              <span className="text-xxs sm:text-xs md:text-sm">{rest.join(' ')}</span>
            </div>
          );
        }

        return (
          <div key={lineIndex} className="flex items-start space-x-1 sm:space-x-2 my-0.5 sm:my-1">
            <span className="text-gray-400">•</span>
            <span className="text-xxs sm:text-xs md:text-sm">{bulletText}</span>
          </div>
        );
      }

      // Blank line
      if (line.trim() === '') {
        return <div key={lineIndex} className="py-0.5 sm:py-1"></div>;
      }

      // Highlight lines that look like section headers
      if (line.includes(':') && !line.includes('|')) {
        const parts = line.split(':');
        if (parts.length === 2 && parts[0].length < 30) {
          return (
            <div key={lineIndex} className="my-0.5 sm:my-1">
              <span className="font-medium text-gray-700 text-xxs sm:text-xs md:text-sm">{parts[0]}:</span>
              <span className="text-xxs sm:text-xs md:text-sm">{parts[1]}</span>
            </div>
          );
        }
      }

      // Process emojis at the beginning of the line
      if (line.match(/^(✅|❌|🔍|📊|🧠|🔄)/)) {
        const [emoji, ...rest] = line.split(' ');
        return (
          <div key={lineIndex} className="flex items-start space-x-1 sm:space-x-2 my-0.5 sm:my-1">
            <span className="text-sm sm:text-base md:text-lg">{emoji}</span>
            <span className="text-xxs sm:text-xs md:text-sm">{rest.join(' ')}</span>
          </div>
        );
      }

      // Normal line
      return <p key={lineIndex} className="my-0.5 sm:my-1 text-xxs sm:text-xs md:text-sm">{line}</p>;
    });
  };

  return (
    <div className="thinking-process-container mb-1 sm:mb-2 md:mb-3">
      <div className={`flex items-center mb-0.5 sm:mb-1 md:mb-2 ${animation ? 'animate-pulse' : ''}`}>
        <div className="h-4 w-4 sm:h-5 sm:w-5 md:h-6 md:w-6 rounded-full bg-purple-100 flex items-center justify-center mr-1 sm:mr-2 assistant-avatar">
          <span className="text-white text-xxs sm:text-xs">🧠</span>
        </div>
        <button
          onClick={() => setExpanded(!expanded)}
          className="text-xxs sm:text-xs md:text-sm font-medium text-gray-700 flex items-center hover:text-[#2745F8] transition-colors"
        >
          {expanded ? 'Hide analysis process' : 'View analysis process'}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`ml-0.5 sm:ml-1 h-2.5 w-2.5 sm:h-3 sm:w-3 md:h-4 md:w-4 transition-transform transform ${expanded ? 'rotate-180' : ''}`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
          </svg>
        </button>
      </div>

      {expanded && (
        <div
          className="bg-gray-50 rounded-lg p-1.5 sm:p-2 md:p-3 text-xxs sm:text-xs text-gray-700 my-0.5 sm:my-1 md:my-2 border border-gray-200 thinking-content shadow-sm"
          style={{
            animation: 'fadeInUp 0.3s ease-out forwards'
          }}
        >
          {formatThinkingContent(content)}
        </div>
      )}
    </div>
  );
};
const ChatWelcome = ({ activeSource, googleDataAvailable, metaDataAvailable }) => {
  // Determine the source name to display
  const getSourceName = () => {
    if (activeSource === 'all') return 'Google Ads y Meta Ads';
    return activeSource === 'google' ? 'Google Ads' : 'Meta Ads';
  };
  
  const sourceName = getSourceName();
  
  // Check if data is available for the selected source
  const isDataAvailable = () => {
    if (activeSource === 'google') return googleDataAvailable;
    if (activeSource === 'meta') return metaDataAvailable;
    if (activeSource === 'all') return googleDataAvailable || metaDataAvailable;
    return false;
  };
  
  // Get status message based on data availability
  const getStatusMessage = () => {
    if (!googleDataAvailable && !metaDataAvailable) {
      return "No hay datos disponibles. Por favor, ve a la pestaña de Dashboard y carga los datos de análisis primero.";
    }
    
    if (activeSource === 'google' && !googleDataAvailable) {
      return "No hay datos de Google Ads disponibles. Por favor, ve a la pestaña de Dashboard y carga los datos de Google Ads primero, o selecciona Meta Ads si ya tienes esos datos disponibles.";
    }
    
    if (activeSource === 'meta' && !metaDataAvailable) {
      return "No hay datos de Meta Ads disponibles. Por favor, ve a la pestaña de Dashboard y carga los datos de Meta Ads primero, o selecciona Google Ads si ya tienes esos datos disponibles.";
    }
    
    if (activeSource === 'all') {
      if (!googleDataAvailable && metaDataAvailable) {
        return "Solo hay datos de Meta Ads disponibles. Puedes hacer preguntas sobre Meta Ads o cargar los datos de Google Ads para obtener análisis completos.";
      }
      if (googleDataAvailable && !metaDataAvailable) {
        return "Solo hay datos de Google Ads disponibles. Puedes hacer preguntas sobre Google Ads o cargar los datos de Meta Ads para obtener análisis completos.";
      }
    }
    
    return "Puedo analizar tus campañas y ofrecerte recomendaciones personalizadas para mejorar tu rendimiento. ¿Qué te gustaría saber sobre tus datos de " + sourceName + "?";
  };

  return (
    <div className="welcome-screen p-3 sm:p-4 md:p-6 mb-2 sm:mb-3 md:mb-6">
      <div className="flex items-center mb-2 sm:mb-3 md:mb-4">
        <div className={`h-8 w-8 sm:h-10 sm:w-10 md:h-12 md:w-12 rounded-full assistant-avatar flex items-center justify-center mr-2 sm:mr-3 md:mr-4 ${
          isDataAvailable() ? 'bg-[#2745F8]' : 'bg-gray-400'
        }`}>
          <MessageCircle className="w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6 text-white" />
        </div>
        <div>
          <h3 className={`font-bold text-sm sm:text-base md:text-xl ${
            isDataAvailable() ? 'text-[#2745F8]' : 'text-gray-600'
          }`}>
            Asistente de {sourceName}
          </h3>
          <p className="text-xxs sm:text-xs text-gray-500">Powered by Espor.ai</p>
        </div>
      </div>

      <p className="text-xxs sm:text-xs md:text-sm mb-2 sm:mb-3 md:mb-4 text-gray-600 leading-relaxed">
        {getStatusMessage()}
      </p>
      
      {!isDataAvailable() && (
        <div className="bg-gray-50 border border-gray-200 rounded-lg p-3 sm:p-4 text-xxs sm:text-xs text-gray-600 flex items-center">
          <RefreshCw className="w-4 h-4 mr-2 text-gray-400" />
          <span>Ve al Dashboard y haz clic en "Actualizar" para cargar los datos de {
            activeSource === 'google' ? 'Google Ads' : 
            activeSource === 'meta' ? 'Meta Ads' : 
            'análisis'
          }</span>
        </div>
      )}
    </div>
  );
};

// Mobile-optimized ProcessingMessage
const ProcessingMessage = ({ thinking }) => {
  const [displayedText, setDisplayedText] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [fullText, setFullText] = useState(thinking || "Analizando tu consulta...");
  const typingSpeed = 20; // milliseconds per character (adjust to change speed)

  // Detect changes in thinking text
  useEffect(() => {
    if (thinking && thinking !== fullText) {
      // If the new text is different from the previous one, update
      setFullText(thinking);

      // If the new text completely contains the previous text as a prefix,
      // only add the new characters, otherwise restart the animation
      if (!thinking.startsWith(fullText)) {
        setDisplayedText("");
        setIsTyping(true);
      } else if (!isTyping) {
        // If it's not already typing, start writing from where it left off
        setIsTyping(true);
      }
    } else if (!thinking && !fullText) {
      setFullText("Analizando tu consulta...");
      setDisplayedText("");
      setIsTyping(true);
    }
  }, [thinking]);

  // Effect for typing animation
  useEffect(() => {
    if (!isTyping) return;

    // If we're already showing all the text, stop the animation
    if (displayedText === fullText) {
      setIsTyping(false);
      return;
    }

    // Calculate the next portion of text to display
    const nextCharIndex = displayedText.length;

    // Show the next character after a brief delay
    const timer = setTimeout(() => {
      // Put the next character 
      setDisplayedText(fullText.substring(0, nextCharIndex + 1));

      // If we reach a new line, add extra delay
      if (fullText[nextCharIndex] === '\n') {
        setTimeout(() => setIsTyping(true), typingSpeed * 10);
        setIsTyping(false);
      }
    }, typingSpeed);

    return () => clearTimeout(timer);
  }, [displayedText, fullText, isTyping]);

  // Format for emojis and different lines
  const formatContent = (text) => {
    if (!text) return null;

    return text.split('\n').map((line, lineIndex) => {
      // Handle lines with emojis
      if (line.match(/^[🧠📊🔍📝⏱️✅❌🔄⚖️📋📈🔎📑🗓️⚙️]/)) {
        const [emoji, ...rest] = line.split(' ');
        return (
          <div key={lineIndex} className="flex items-start my-0.5 sm:my-1">
            <span className="text-sm sm:text-base mr-1 sm:mr-2">{emoji}</span>
            <span className="text-xxs sm:text-xs">{rest.join(' ')}</span>
          </div>
        );
      }

      // Handle markdown table lines
      if (line.startsWith('|')) {
        return (
          <div key={lineIndex} className="my-0.5 sm:my-1 font-mono text-xxs sm:text-xs whitespace-nowrap overflow-x-auto">
            {line}
          </div>
        );
      }

      // Blank lines
      if (!line.trim()) {
        return <div key={lineIndex} className="h-1 sm:h-2"></div>;
      }

      // Handle list lines
      if (line.match(/^-\s/)) {
        return (
          <div key={lineIndex} className="flex items-start ml-2 sm:ml-4 my-0.5 sm:my-1">
            <span className="mr-1 sm:mr-2 text-xxs sm:text-xs">•</span>
            <span className="text-xxs sm:text-xs">{line.substring(2)}</span>
          </div>
        );
      }

      // Normal lines
      return <div key={lineIndex} className="my-0.5 sm:my-1 text-xxs sm:text-xs">{line}</div>;
    });
  };

  // Blinking cursor effect at the end of text
  const BlinkingCursor = () => {
    return (
      <span
        className="inline-block w-1.5 sm:w-2 h-3 sm:h-4 ml-0.5 sm:ml-1 bg-pink-500"
        style={{
          animation: 'blink 1s step-end infinite',
        }}
      />
    );
  };

  return (
    <div className="p-2 sm:p-3 md:p-5 max-w-[85%] mb-2 sm:mb-3 md:mb-4 chat-message assistant-bubble self-start mr-auto">
      <div className="leading-relaxed">
        <div className="mb-1 sm:mb-2 md:mb-3">
          <div className="flex items-center space-x-1">
            <div className="typing-dot bg-[#2745F8]"></div>
            <div className="typing-dot bg-[#2745F8]"></div>
            <div className="typing-dot bg-[#2745F8]"></div>
            <span className="text-gray-500 ml-1 sm:ml-2 text-xxs sm:text-xs md:text-sm">Procesando tu consulta...</span>
          </div>
        </div>

        <div className="mt-2 sm:mt-3 md:mt-4 pt-2 sm:pt-3 md:pt-4 border-t border-gray-100">
          <div className="flex items-center mb-0.5 sm:mb-1 md:mb-2">
            <div className="h-3 w-3 sm:h-4 sm:w-4 md:h-5 md:w-5 rounded-full bg-pink-100 flex items-center justify-center mr-1 sm:mr-2">
              <span className="text-pink-500 text-xxs sm:text-xs">🧠</span>
            </div>
            <span className="text-xxs sm:text-xs md:text-sm font-medium text-gray-700">Proceso de análisis en curso</span>
          </div>

          <div className="bg-gray-50 rounded-lg p-1.5 sm:p-2 md:p-3 text-xxs sm:text-xs text-gray-600 my-0.5 sm:my-1 md:my-2 border border-gray-200 max-h-40 sm:max-h-60 md:max-h-80 overflow-auto">
            <div className="typewriter-container whitespace-pre-wrap">
              {formatContent(displayedText)}
              {isTyping && <BlinkingCursor />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Toast component with mobile optimization
const Toast = ({ message }) => (
  <div className="fixed bottom-16 sm:bottom-4 right-4 z-50 animate-fade-in-up max-w-[90%] sm:max-w-xs">
    <div className="bg-red-50 border-l-4 border-red-400 p-2 sm:p-4 rounded shadow-lg">
      <div className="flex">
        <div className="ml-2 sm:ml-3">
          <p className="text-xs sm:text-sm text-red-700">
            {message}
          </p>
        </div>
      </div>
    </div>
  </div>
);

// Add CSS styles for the xxs text size that Tailwind doesn't have by default
const GlobalStyles = () => (
  <style jsx global>{`
    @tailwind base;
    @tailwind components;
    @tailwind utilities;
    
    /* Add xxs text size class */
    .text-xxs {
      font-size: 0.625rem;
      line-height: 0.75rem;
    }
    
    /* Animaciones y transiciones mejoradas */
    @keyframes fadeInUp {
      from {
        opacity: 0;
        transform: translateY(15px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
    
    @keyframes subtle-pulse {
      0% { box-shadow: 0 0 0 0 rgba(177, 104, 255, 0.3); }
      70% { box-shadow: 0 0 0 10px rgba(177, 104, 255, 0); }
      100% { box-shadow: 0 0 0 0 rgba(177, 104, 255, 0); }
    }
    
    /* Animación del cursor parpadeante para el efecto de escritura */
    @keyframes blink {
      0%, 100% { opacity: 1; }
      50% { opacity: 0; }
    }
    
    .animate-fade-in-up {
      animation: fadeInUp 0.3s ease-out forwards;
    }
    
    /* Chat bubble styles */
    .user-bubble {
      background: linear-gradient(135deg, #eff6ff 0%, #dbeafe 100%);
      border-right: 3px solid #3b82f6;
      border-radius: 16px 16px 0 16px;
      box-shadow: 0 4px 12px rgba(59, 130, 246, 0.1);
    }
    
    .assistant-bubble {
      background: white;
      border-left: 3px solid #2745F8;
      border-radius: 16px 16px 16px 0;
      box-shadow: 0 4px 12px rgba(177, 104, 255, 0.1);
    }
    
    /* Typing indicator animation */
    .typing-dot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #2745F8;
      margin-right: 4px;
      animation: typing-dot 1.4s infinite ease-in-out both;
    }
    
    .typing-dot:nth-child(1) {
      animation-delay: -0.32s;
    }
    
    .typing-dot:nth-child(2) {
      animation-delay: -0.16s;
    }
    
    @keyframes typing-dot {
      0%, 80%, 100% { 
        transform: scale(0.7);
        opacity: 0.5;
      }
      40% { 
        transform: scale(1);
        opacity: 1;
      }
    }
    
    /* Custom scrollbar */
    .custom-scrollbar::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
    
    .custom-scrollbar::-webkit-scrollbar-track {
      background: #f9fafb;
      border-radius: 10px; 
    }
    
    .custom-scrollbar::-webkit-scrollbar-thumb {
      background: #e0e0e0;
      border-radius: 10px;
      border: 2px solid #f9fafb;
    }
    
    .custom-scrollbar::-webkit-scrollbar-thumb:hover {
      background: #2745F8;
    }

    /* Fix for bottom navigation spacing */
    @media (max-width: 640px) {
      .sm\\:hidden main {
        padding-bottom: 60px;
      }
    }
  `}</style>
);const CombinedAdsDashboard = () => {
  const [activeSource, setActiveSource] = useState('google');
  const [chatActiveSource, setChatActiveSource] = useState('all');
  const [activeTab, setActiveTab] = useState('dashboard');
  const [menuOpen, setMenuOpen] = useState(false); 
  const [messagesRemaining, setMessagesRemaining] = useState(10);
  const [resetTimeMinutes, setResetTimeMinutes] = useState(0);
  const [googleData, setGoogleData] = useState({
    metrics: {
      impressions: 0,
      clicks: 0,
      conversions: 0,
      cost: 0,
      ctr: 0,
      average_cpc: 0,
      conversion_rate: 0,
      cost_per_conversion: 0,
    },
    daily_metrics: [],
    campaign_metrics: [],
  });
  const [metaData, setMetaData] = useState({
    metrics: {
      impressions: 0,
      clicks: 0,
      website_purchases: 0,
      cost: 0,
      ctr: 0,
      cpc: 0,
      cr: 0,
      cpm: 0,
    },
    daily_metrics: [],
    campaign_metrics: [],
  });
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(subYears(new Date(), 1)); // Set to 1 year ago by default
  const [endDate, setEndDate] = useState(new Date());
  const [customerId, setCustomerId] = useState('');
  const [chatMessages, setChatMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState('');
  const [error, setError] = useState(null);
  const { auth } = useContext(AuthContext);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  // Referencias para los gráficos (para exportación PDF)
  const performanceChartRef = useRef(null);
  const campaignChartRef = useRef(null);
  const chartRefs = {
    performanceRef: performanceChartRef,
    campaignRef: campaignChartRef
  };

  // Helper functions to check if data is available
  const isGoogleDataAvailable = () => {
    return googleData && 
           googleData.daily_metrics && 
           googleData.daily_metrics.length > 0 &&
           googleData.campaign_metrics &&
           googleData.campaign_metrics.length > 0;
  };

  const isMetaDataAvailable = () => {
    return metaData && 
           metaData.daily_metrics && 
           metaData.daily_metrics.length > 0 &&
           metaData.campaign_metrics &&
           metaData.campaign_metrics.length > 0;
  };

  // Función para exportar a Excel
  const handleExportToExcel = () => {
    // Create the date difference for projection calculations
    const daysDifference = Math.round((endDate - startDate) / (1000 * 60 * 60 * 24));

    // Prepare export data based on active source
    if (activeSource === 'google') {
      exportToExcel({
        source: 'Google Ads',
        startDate: startDate,
        endDate: endDate,
        daysDifference: daysDifference,
        metrics: googleData.metrics,
        dailyMetrics: googleData.daily_metrics,
        campaignMetrics: googleData.campaign_metrics
      }, 'Google_Ads_Analytics');
    } else {
      exportToExcel({
        source: 'Meta Ads',
        startDate: startDate,
        endDate: endDate,
        daysDifference: daysDifference,
        metrics: metaData.metrics,
        dailyMetrics: metaData.daily_metrics,
        campaignMetrics: metaData.campaign_metrics
      }, 'Meta_Ads_Analytics');
    }
  };

  const handleExportToPDF = async () => {
    try {
      console.log("Iniciando exportación a PDF");
      // Create the date difference for projection calculations
      const daysDifference = Math.round((endDate - startDate) / (1000 * 60 * 60 * 24));

      // Prepare export data based on active source
      const data = activeSource === 'google' ?
        {
          source: 'Google Ads',
          startDate: startDate,
          endDate: endDate,
          daysDifference: daysDifference,
          metrics: googleData.metrics,
          dailyMetrics: googleData.daily_metrics,
          campaignMetrics: googleData.campaign_metrics
        } :
        {
          source: 'Meta Ads',
          startDate: startDate,
          endDate: endDate,
          daysDifference: daysDifference,
          metrics: metaData.metrics,
          dailyMetrics: metaData.daily_metrics,
          campaignMetrics: metaData.campaign_metrics
        };

      await exportToPDF(data, chartRefs, `${data.source === 'Google Ads' ? 'Google_Ads_Analytics' : 'Meta_Ads_Analytics'}`);
      console.log("PDF generado correctamente");
    } catch (error) {
      console.error('Error generating PDF:', error);
      alert(`Error al generar PDF: ${error.message}`);
    }
  };

  useEffect(() => {
    // Only fetch data if auth token is available
    if (auth.token && auth.user?.email) {
      console.log("Auto-loading data for the past year");
      // Set dates to last year by default (already done in state initialization)
      // Call fetchData to load both Google and Meta data
      fetchData();
    }
  }, [auth.token, auth.user?.email]);

  useEffect(() => {
    // Use the google_customer_id from the user when available
    if (auth.user?.google_customer_id) {
      setCustomerId(auth.user.google_customer_id);
    }
  }, [auth.user?.google_customer_id]);
  const fetchGoogleData = async () => {
    if (!auth.token || !auth.user?.email) {
      console.error('No auth token or email found');
      setError('Authentication information missing');
      return;
    }
  
    // Use google_customer_id from user data if customerId isn't set yet
    const customerIdToUse = customerId || auth.user?.google_customer_id;
    
    if (!customerIdToUse) {
      console.warn('No customer ID found, skipping Google Ads data fetch');
      return; // Skip fetch but don't show error
    }
  
    try {
      const response = await fetch(`${API_URL}/api/google-ads`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        },
        body: JSON.stringify({
          customer_id: customerIdToUse,
          manager_id: auth.user?.google_manager_id,
          start_date: format(startDate, 'yyyy-MM-dd'),
          end_date: format(endDate, 'yyyy-MM-dd'),
        }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Error loading Google Ads data');
      }
  
      const result = await response.json();
      setGoogleData(result);
    } catch (error) {
      console.error('Error fetching Google data:', error);
      setToastMessage(error.message || 'Error loading Google Ads data');
      setShowToast(true);
    }
  }; 

  const fetchMetaData = async () => {
    try {
      if (!auth.token) return;

      const response = await fetch(`${API_URL}/api/meta-ads`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        },
        body: JSON.stringify({
          start_date: format(startDate, 'yyyy-MM-dd'),
          end_date: format(endDate, 'yyyy-MM-dd')
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Error al cargar datos de Meta Ads');
      }

      const result = await response.json();
      setMetaData(result);
    } catch (error) {
      console.error('Error al obtener datos de Meta:', error);
      setToastMessage(error.message || 'Error al cargar datos de Meta Ads');
      setShowToast(true);
    }
  };

  useEffect(() => {
    if (showToast) {
      const timer = setTimeout(() => {
        setShowToast(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showToast]);

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      // Use Promise.allSettled instead of Promise.all to ensure both requests run even if one fails
      const results = await Promise.allSettled([fetchGoogleData(), fetchMetaData()]);
      
      // Log any errors but don't block the UI
      results.forEach((result, index) => {
        if (result.status === 'rejected') {
          console.error(`Error fetching ${index === 0 ? 'Google' : 'Meta'} data:`, result.reason);
        }
      });
    } finally {
      setLoading(false);
    }
  }

  const handleSendMessage = async () => {
    if (!currentMessage.trim()) return;

    // Check authentication
    if (!auth.token || !auth.user?.email) {
      setToastMessage("Necesitas iniciar sesión para usar la función de chat");
      setShowToast(true);
      return;
    }

    // Check data availability based on selected source
    const isGoogleDataLoaded = isGoogleDataAvailable();
    const isMetaDataLoaded = isMetaDataAvailable();
    
    if (chatActiveSource === 'google' && !isGoogleDataLoaded) {
      setToastMessage("No hay datos de Google Ads disponibles. Carga los datos primero.");
      setShowToast(true);
      return;
    }
    
    if (chatActiveSource === 'meta' && !isMetaDataLoaded) {
      setToastMessage("No hay datos de Meta Ads disponibles. Carga los datos primero.");
      setShowToast(true);
      return;
    }
    
    if (chatActiveSource === 'all' && !isGoogleDataLoaded && !isMetaDataLoaded) {
      setToastMessage("No hay datos disponibles. Carga los datos de análisis primero.");
      setShowToast(true);
      return;
    }

    setLoading(true);
    const originalMessage = currentMessage;
    setCurrentMessage(''); // Limpiar el input inmediatamente

    // Añadir mensaje del usuario a la conversación
    setChatMessages((prev) => [...prev, { text: originalMessage, isUser: true }]);

    // Añadir mensaje temporal de procesamiento
    setChatMessages((prev) => [
      ...prev,
      {
        text: "Procesando tu consulta, esto puede tomar un momento...",
        isUser: false,
        isProcessing: true,
        thinking: "Analizando tu consulta..." // Iniciar con un mensaje básico de pensamiento
      }
    ]);

    try {
      // Preparar datos según la fuente seleccionada para el chat
      let requestData = {
        message: originalMessage,
        email: auth.user.email
      };

      // Si la fuente es 'all', enviar datos de ambas plataformas disponibles
      // Si es 'google' o 'meta', enviar solo los datos de esa plataforma
      if ((chatActiveSource === 'all' || chatActiveSource === 'google') && isGoogleDataLoaded) {
        requestData.google_data = {
          metrics: googleData.metrics,
          daily_metrics: googleData.daily_metrics,
          campaign_metrics: googleData.campaign_metrics,
        };
      }

      if ((chatActiveSource === 'all' || chatActiveSource === 'meta') && isMetaDataLoaded) {
        requestData.meta_data = {
          metrics: metaData.metrics,
          daily_metrics: metaData.daily_metrics,
          campaign_metrics: metaData.campaign_metrics,
        };
      }

      // Usar el endpoint unificado
      const response = await fetch(`${API_URL}/api/unified-chat-agent`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        },
        body: JSON.stringify(requestData),
      });

      // Manejar límite de mensajes (429 Too Many Requests)
      if (response.status === 429) {
        const errorData = await response.json();

        // Reemplazar mensaje de procesamiento con aviso de límite
        setChatMessages((prev) => {
          const newMessages = [...prev];
          // Reemplazar mensaje de procesamiento con aviso
          const processingIndex = newMessages.findIndex(msg => msg.isProcessing === true);
          if (processingIndex !== -1) {
            newMessages[processingIndex] = {
              text: `⚠️ Has alcanzado tu límite de mensajes. Podrás enviar más consultas en aproximadamente ${errorData.wait_time} minutos.`,
              isUser: false,
              isProcessing: false,
              isError: true
            };
          }
          return newMessages;
        });

        // Mostrar mensaje de error
        setToastMessage(`Límite de mensajes alcanzado. Reinicio en ${errorData.wait_time} minutos.`);
        setShowToast(true);

        // Actualizar contador de mensajes restantes
        setMessagesRemaining(0);
        setResetTimeMinutes(errorData.wait_time);

        setLoading(false);
        return;
      }

      if (!response.ok) {
        throw new Error('Error en la respuesta del chat');
      }

      const result = await response.json();

      // Actualizar contador de mensajes restantes si está disponible
      if (result.messages_remaining !== undefined) {
        setMessagesRemaining(result.messages_remaining);
      }

      if (result.reset_in_minutes !== undefined) {
        setResetTimeMinutes(result.reset_in_minutes);
      }

      if (result.task_id) {
        // Consultar periódicamente el estado de la tarea
        const finalResponse = await pollTaskStatus(result.task_id);

        // Extraer resultado y proceso de pensamiento
        let finalResult, thinking;

        if (typeof finalResponse === 'object') {
          finalResult = finalResponse.result;
          thinking = finalResponse.thinking || finalResponse.all_thinking;
        } else {
          finalResult = finalResponse;
          thinking = null;
        }

        // Reemplazar mensaje de procesamiento con el resultado final
        setChatMessages((prev) => {
          const newMessages = [...prev];
          const processingIndex = newMessages.findIndex(msg => msg.isProcessing === true);

          if (processingIndex !== -1) {
            newMessages[processingIndex] = {
              text: finalResult,
              isUser: false,
              isProcessing: false,
              thinking: thinking // Incluir el proceso de pensamiento
            };
          } else {
            // Si por alguna razón no encontramos el mensaje de procesamiento
            newMessages.push({
              text: finalResult,
              isUser: false,
              thinking: thinking
            });
          }
          return newMessages;
        });
      } else if (result.response) {
        // Compatibilidad con el modo de respuesta directa
        setChatMessages((prev) => {
          const newMessages = [...prev];
          const processingIndex = newMessages.findIndex(msg => msg.isProcessing === true);

          if (processingIndex !== -1) {
            newMessages[processingIndex] = {
              text: result.response,
              isUser: false,
              isProcessing: false,
              // No hay proceso de pensamiento en las respuestas directas
            };
          } else {
            newMessages.push({
              text: result.response,
              isUser: false
            });
          }
          return newMessages;
        });
      }
    } catch (error) {
      console.error('Error:', error);

      // Reemplazar mensaje de procesamiento con mensaje de error
      setChatMessages((prev) => {
        const newMessages = [...prev];
        const processingIndex = newMessages.findIndex(msg => msg.isProcessing === true);

        if (processingIndex !== -1) {
          newMessages[processingIndex] = {
            text: 'Lo siento, hubo un error al procesar tu mensaje. ¿Podrías intentarlo de nuevo?',
            isUser: false,
            isProcessing: false
          };
        } else {
          newMessages.push({
            text: 'Lo siento, hubo un error al procesar tu mensaje. ¿Podrías intentarlo de nuevo?',
            isUser: false
          });
        }
        return newMessages;
      });
    } finally {
      setLoading(false);
    }
  };

  const pollTaskStatus = async (taskId) => {
    return new Promise((resolve, reject) => {
      let attempts = 0;
      const maxAttempts = 60; // Aumentado a 60 para dar más tiempo a la animación
      let lastThinking = null;
      let thinkingUpdated = false;

      const checkStatus = async () => {
        try {
          attempts++;

          if (attempts > maxAttempts) {
            reject(new Error('La tarea está tomando demasiado tiempo. Por favor, inténtalo más tarde.'));
            return;
          }

          console.log(`Consultando tarea ${taskId}, intento #${attempts}`);
          const response = await fetch(`${API_URL}/api/tasks/${taskId}`, {
            headers: { 'Authorization': `Bearer ${auth.token}` }
          });

          if (!response.ok) {
            if (response.status === 404) {
              setTimeout(checkStatus, 2000);
              return;
            }
            throw new Error('Error al consultar estado de la tarea');
          }

          const statusData = await response.json();
          console.log('Status:', statusData.status);

          // Verificar si hay thinking
          if (statusData.thinking) {
            // Verificar si el thinking ha cambiado
            if (lastThinking !== statusData.thinking) {
              console.log('Nuevo thinking detectado, actualizando UI');
              thinkingUpdated = true;
              lastThinking = statusData.thinking;

              // Actualizar el mensaje de procesamiento con el nuevo thinking
              setChatMessages((prev) => {
                const newMessages = [...prev];
                const idx = newMessages.findIndex(msg => msg.isProcessing === true);
                if (idx !== -1) {
                  newMessages[idx] = {
                    ...newMessages[idx],
                    thinking: statusData.thinking
                  };
                }
                return newMessages;
              });

              // Esperar un poco más entre consultas cuando se actualiza el thinking
              // para dar tiempo a que se muestre la animación de escritura
              const waitTime = statusData.thinking.length > 1000 ? 2000 : 1500;
              setTimeout(() => checkStatus(), waitTime);
              return;
            }
          }

          // Procesar según el estado
          if (statusData.status === 'completed') {
            // Si es la primera vez que vemos que está completo y hubo actualizaciones de thinking,
            // esperamos un poco para que terminen las animaciones de escritura
            if (thinkingUpdated) {
              console.log('Tarea completada, esperando para mostrar resultado final');
              setTimeout(() => {
                resolve({
                  result: statusData.result,
                  thinking: lastThinking || statusData.all_thinking
                });
              }, 2000); // Dar tiempo a que termine la animación
            } else {
              resolve({
                result: statusData.result,
                thinking: lastThinking || statusData.all_thinking
              });
            }
          } else if (statusData.status === 'failed') {
            reject(new Error(statusData.error || 'Error al procesar la consulta'));
          } else {
            // Si el estado sigue siendo processing, pero no ha cambiado el thinking
            // esperamos menos tiempo antes de verificar nuevamente
            setTimeout(checkStatus, 1000);
          }
        } catch (error) {
          console.error('Error en polling:', error);
          setTimeout(checkStatus, 2000);
        }
      };

      // Iniciar el polling
      checkStatus();
    });
  };

  const formatDateStr = (dateStr) => {
    try {
      return format(new Date(dateStr), 'dd MMM', { locale: enUS });
    } catch (e) {
      console.error('Error formatting date:', dateStr);
      return dateStr;
    }
  };

  // Get current data based on active source
  const currentData = activeSource === 'google' ? googleData : metaData;

  if (loading && !currentData.metrics) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-50">
        <RefreshCw className="animate-spin text-[#2745F8] text-4xl" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <GlobalStyles />
      {/* Header */}
      <Header 
        activeSource={activeSource} 
        setActiveSource={setActiveSource} 
        activeTab={activeTab} 
        setActiveTab={setActiveTab} 
        menuOpen={menuOpen} 
        setMenuOpen={setMenuOpen} 
      />

      {/* Main Content */}
      <main className="flex-grow p-3 sm:p-4 md:p-6 overflow-y-auto pb-16 sm:pb-6">
        {showToast && <Toast message={toastMessage} />}

        {activeTab === 'dashboard' ? (
          // Dashboard content
          <>
            <DashboardFilter
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              loading={loading}
              fetchData={fetchData}
              handleExportToExcel={handleExportToExcel}
              handleExportToPDF={handleExportToPDF}
            />

            {/* Metrics Grid */}
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-2 sm:gap-3 md:gap-6 mt-3 sm:mt-4 md:mt-8">
              <MetricCard
                title="Impresiones"
                value={currentData.metrics.impressions}
                icon={Eye}
              />
              <MetricCard
                title="Clicks"
                value={currentData.metrics.clicks}
                icon={MousePointer}
              />
              <MetricCard
                title={activeSource === 'google' ? 'Conversiones' : 'Compras'}
                value={activeSource === 'google' ? currentData.metrics.conversions : currentData.metrics.website_purchases}
                icon={Target}
              />
              <MetricCard
                title="Gasto Total"
                value={currentData.metrics.cost}
                icon={DollarSign}
                prefix="$"
              />
            </div>

            {/* Charts - Hidden on mobile but still in DOM for PDF export */}
            {currentData.daily_metrics?.length > 0 && (
              <>
                {/* Mobile stats summary */}
                <div className="bg-white border border-gray-200 rounded-lg p-2.5 mt-3 shadow-sm sm:hidden">
                  <h3 className="text-base font-bold text-gray-800 mb-2">Daily Performance Summary</h3>
                  <div className="grid grid-cols-2 gap-2">
                    <div className="bg-gray-50 p-2 rounded">
                      <div className="text-xs text-gray-600">Avg. Daily Clicks</div>
                      <div className="text-base font-semibold text-[#2745F8]">
                        {Math.round(currentData.metrics.clicks / (currentData.daily_metrics?.length || 1))}
                      </div>
                    </div>
                    <div className="bg-gray-50 p-2 rounded">
                      <div className="text-xs text-gray-600">
                        {activeSource === 'google' ? 'Avg. Daily Conv.' : 'Avg. Daily Purch.'}
                      </div>
                      <div className="text-base font-semibold text-[#2745F8]">
                        {Math.round(
                          (activeSource === 'google' 
                            ? currentData.metrics.conversions 
                            : currentData.metrics.website_purchases) / 
                          (currentData.daily_metrics?.length || 1)
                        )}
                      </div>
                    </div>
                    <div className="bg-gray-50 p-2 rounded">
                      <div className="text-xs text-gray-600">
                        {activeSource === 'google' ? 'Conv. Rate' : 'Purchase Rate'}
                      </div>
                      <div className="text-base font-semibold text-[#2745F8]">
                        {`${(activeSource === 'google' 
                          ? currentData.metrics.conversion_rate 
                          : currentData.metrics.cr || 0).toFixed(2)}%`}
                      </div>
                    </div>
                    <div className="bg-gray-50 p-2 rounded">
                      <div className="text-xs text-gray-600">Avg. CPC</div>
                      <div className="text-base font-semibold text-[#2745F8]">
                        ${(activeSource === 'google' 
                          ? currentData.metrics.average_cpc 
                          : currentData.metrics.cpc || 0).toFixed(2)}
                      </div>
                    </div>
                  </div>
                </div>
                
                {/* Chart hidden on mobile but kept for PDF export */}
                <div className="bg-white border border-gray-200 rounded-lg sm:rounded-xl md:rounded-2xl p-2.5 sm:p-4 md:p-6 mt-3 sm:mt-4 md:mt-6 shadow-sm hidden sm:block" ref={campaignChartRef}>
                  <h3 className="text-base sm:text-lg md:text-xl font-bold text-gray-800 mb-2 sm:mb-3 md:mb-4">Campaign Performance</h3>
                  <div className="h-52 sm:h-64 md:h-96 lg:h-[400px]">
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart
                        data={currentData.campaign_metrics.slice(0, 6)}
                        margin={{ top: 5, right: 5, left: 0, bottom: 35 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey={activeSource === 'google' ? 'campaign_name' : 'Campaign name'}
                          tick={{ fontSize: 8 }}
                          interval={0}
                          angle={-45}
                          textAnchor="end"
                          height={50}
                        />
                        <YAxis yAxisId="left" orientation="left" stroke="#2563EB" tick={{ fontSize: 9 }} />
                        <YAxis yAxisId="right" orientation="right" stroke="#DB2777" tick={{ fontSize: 9 }} />
                        <Tooltip
                          wrapperStyle={{ fontSize: '9px' }}
                          contentStyle={{ fontSize: '9px' }}
                        />
                        <Legend iconSize={7} wrapperStyle={{ fontSize: '9px' }} />
                        <Bar
                          yAxisId="left"
                          dataKey={activeSource === 'google' ? 'clicks' : 'Clicks'}
                          name="Clicks"
                          fill="#2563EB"
                          radius={[2, 2, 0, 0]}
                          barSize={12}
                        />
                        <Bar
                          yAxisId="right"
                          dataKey={activeSource === 'google' ? 'conversions' : 'Website purchases'}
                          name={activeSource === 'google' ? 'Conversions' : 'Purchases'}
                          fill="#DB2777"
                          radius={[2, 2, 0, 0]}
                          barSize={12}
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
                <div className="bg-white border border-gray-200 rounded-lg sm:rounded-xl md:rounded-2xl p-2.5 sm:p-4 md:p-6 mt-3 sm:mt-4 md:mt-6 shadow-sm hidden sm:block" ref={performanceChartRef}>
                  <h3 className="text-base sm:text-lg md:text-xl font-bold text-gray-800 mb-2 sm:mb-3 md:mb-4">Daily Metrics</h3>
                  <div className="h-52 sm:h-64 md:h-80 lg:h-[300px]">
                    <ResponsiveContainer width="100%" height="100%">
                      <LineChart data={currentData.daily_metrics}>
                        <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
                        <XAxis
                          dataKey={activeSource === 'google' ? 'date' : 'Date'}
                          tickFormatter={formatDateStr}
                          stroke="#6B7280"
                          tick={{ fontSize: 9, dy: 5 }}
                          tickMargin={5}
                        />
                        <YAxis stroke="#6B7280" tick={{ fontSize: 9 }} />
                        <Tooltip
                          content={({ active, payload, label }) => {
                            if (active && payload && payload.length) {
                              return (
                                <div className="bg-white p-2 md:p-4 rounded-lg shadow-lg border border-gray-200">
                                  <p className="text-xxs sm:text-xs md:text-sm text-gray-600">
                                    {formatDateStr(label)}
                                  </p>
                                  {payload.map((entry, index) => (
                                    <p key={index} className="text-xs sm:text-sm md:text-lg text-gray-800">
                                      {entry.name}: {safeToLocaleString(entry.value)}
                                    </p>
                                  ))}
                                </div>
                              );
                            }
                            return null;
                          }}
                        />
                        <Legend iconSize={7} wrapperStyle={{ fontSize: '9px', paddingTop: '8px' }} />
                        <Line
                          type="monotone"
                          dataKey={activeSource === 'google' ? 'clicks' : 'Clicks'}
                          stroke="#2563EB"
                          strokeWidth={2}
                          name="Clicks"
                          dot={{ r: 1 }}
                          activeDot={{ r: 5 }}
                        />
                        <Line
                          type="monotone"
                          dataKey={activeSource === 'google' ? 'conversions' : 'Website purchases'}
                          stroke="#DB2777"
                          strokeWidth={2}
                          name={activeSource === 'google' ? 'Conversions' : 'Purchases'}
                          dot={{ r: 1 }}
                          activeDot={{ r: 5 }}
                        />
                        <Line
                          type="monotone"
                          dataKey={activeSource === 'google' ? 'cost' : 'Total Cost'}
                          stroke="#10B981"
                          strokeWidth={2}
                          name="Cost"
                          dot={{ r: 1 }}
                          activeDot={{ r: 5 }}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </>
            )}

            {/* Campaign Chart - Hidden on mobile but still in DOM for PDF export */}
            {currentData.campaign_metrics?.length > 0 && (
              <>
                {/* Mobile campaign summary */}
                <div className="bg-white border border-gray-200 rounded-lg p-2.5 mt-3 shadow-sm sm:hidden">
                  <h3 className="text-base font-bold text-gray-800 mb-2">Top Campaign</h3>
                  {currentData.campaign_metrics.length > 0 && (
                    <div className="bg-purple-50 p-3 rounded-lg">
                      <div className="text-xs font-bold text-gray-800 truncate">
                        {currentData.campaign_metrics[0].campaign_name || currentData.campaign_metrics[0]['Campaign name']}
                      </div>
                      <div className="grid grid-cols-2 gap-2 mt-2">
                        <div className="text-xs text-gray-600">Clicks: <span className="font-semibold text-[#2745F8]">
                          {safeToLocaleString(currentData.campaign_metrics[0].clicks || currentData.campaign_metrics[0].Clicks)}
                        </span></div>
                        <div className="text-xs text-gray-600">{activeSource === 'google' ? 'Conv.' : 'Purch.'}: <span className="font-semibold text-[#2745F8]">
                          {safeToLocaleString(currentData.campaign_metrics[0].conversions || currentData.campaign_metrics[0]['Website purchases'] || 0)}
                        </span></div>
                      </div>
                    </div>
                  )}
                </div>
                
                </>
              )}
  
              {/* Campaign Metrics Table - Visible on all devices */}
              {currentData.campaign_metrics?.length > 0 && (
                <div className="bg-white border border-gray-200 rounded-lg sm:rounded-xl md:rounded-2xl p-2.5 sm:p-4 md:p-6 mt-3 sm:mt-4 md:mt-6 shadow-sm">
                  <h3 className="text-base sm:text-lg md:text-xl font-bold text-gray-800 mb-2 sm:mb-3 md:mb-4">Campaign Metrics</h3>
                  <CampaignMetricsTable 
                    currentData={currentData}
                    activeSource={activeSource}
                  />
                </div>
              )}
            </>
          ) : (
            // Chat interface content - Passing data availability props
            <ChatInterface
              chatActiveSource={chatActiveSource}
              setChatActiveSource={setChatActiveSource}
              chatMessages={chatMessages}
              currentMessage={currentMessage}
              setCurrentMessage={setCurrentMessage}
              handleSendMessage={handleSendMessage}
              loading={loading}
              messagesRemaining={messagesRemaining}
              resetTimeMinutes={resetTimeMinutes}
              googleDataAvailable={isGoogleDataAvailable()}
              metaDataAvailable={isMetaDataAvailable()}
            />
          )}
        </main>
      </div>
    );
  };
  
  export default CombinedAdsDashboard;