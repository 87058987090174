import React from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { 
  LayoutGrid, 
  Users, 
  CreditCard, 
  Settings, 
  HelpCircle, 
  LogOut 
} from 'lucide-react';

const AdminLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const menuItems = [
    { 
      icon: LayoutGrid, 
      label: 'Dashboard', 
      path: '/admin-espor.ai', 
      active: location.pathname === '/admin-espor.ai' 
    },
    { 
      icon: Users, 
      label: 'Usuarios', 
      path: '/admin-espor.ai/users', 
      active: location.pathname === '/admin-espor.ai/users' 
    },
    { 
      icon: CreditCard, 
      label: 'Suscripciones', 
      path: '/admin-espor.ai/subscriptions', 
      active: location.pathname === '/admin-espor.ai/subscriptions' 
    },
    { 
      icon: Settings, 
      label: 'Configuración', 
      path: '/admin-espor.ai/settings', 
      active: location.pathname === '/admin-espor.ai/settings' 
    },
    { 
      icon: HelpCircle, 
      label: 'Soporte', 
      path: '/admin-espor.ai/support', 
      active: location.pathname === '/admin-espor.ai/support' 
    }
  ];

  const handleLogout = () => {
    // Implement logout logic
    localStorage.removeItem('adminToken');
    // Redirect to admin login page
    navigate('/admin-espor.ai/login');
  };

  return (
    <div className="flex h-screen">
      {/* Sidebar */}
      <div className="w-64 bg-white border-r shadow-sm flex flex-col">
        {/* Logo and Title */}
        <div className="p-6 border-b">
          <h2 className="text-2xl font-bold text-gray-800">Admin Panel</h2>
          <p className="text-sm text-gray-500">Espor.ai</p>
        </div>

        {/* Navigation Menu */}
        <nav className="flex-1 p-4">
          {menuItems.map((item) => (
            <Link 
              key={item.path} 
              to={item.path} 
              className={`
                flex items-center p-3 rounded-lg mb-2 transition-colors 
                ${location.pathname === item.path 
                  ? 'bg-purple-100 text-purple-600' 
                  : 'text-gray-600 hover:bg-gray-100 hover:text-gray-900'
                }
              `}
            >
              <item.icon className="w-5 h-5 mr-3" />
              <span className="text-sm font-medium">{item.label}</span>
            </Link>
          ))}
        </nav>

        {/* Logout Button */}
        <div className="p-4 border-t">
          <button 
            onClick={handleLogout}
            className="w-full flex items-center justify-center p-3 text-red-600 hover:bg-red-50 rounded-lg transition-colors"
          >
            <LogOut className="w-5 h-5 mr-3" />
            <span className="text-sm font-medium">Cerrar Sesión</span>
          </button>
        </div>
      </div>

      {/* Main Content Area */}
      <div className="flex-1 bg-gray-50 overflow-y-auto">
        <Outlet />
      </div>
    </div>
  );
};

export default AdminLayout;