import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { 
  Search, 
  Calendar, 
  DollarSign,
  Users,
  LayoutGrid, 
  CreditCard, 
  Settings, 
  HelpCircle, 
  LogOut
} from 'lucide-react';

const SubscriptionManagement = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [subscriptions, setSubscriptions] = useState([]);  // Inicializado como array vacío
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [showConfirmCancel, setShowConfirmCancel] = useState(false);
    const [selectedSubscription, setSelectedSubscription] = useState(null);
    const [stats, setStats] = useState({
      total_revenue: 0,
      active_subscriptions: 0,
      mrr: 0
    });
  const menuItems = [
    { 
      icon: LayoutGrid, 
      label: 'Dashboard', 
      path: '/admin-espor.ai', 
    },
    { 
      icon: Users, 
      label: 'Usuarios', 
      path: '/admin-espor.ai/users', 
    },
    { 
      icon: CreditCard, 
      label: 'Suscripciones', 
      path: '/admin-espor.ai/subscriptions', 
    },
    
  ];

  const handleLogout = () => {
    localStorage.removeItem('adminToken');
    navigate('/admin-espor.ai/login');
  };

  useEffect(() => {
    fetchSubscriptions();
    fetchStats();
  }, []);

  const fetchSubscriptions = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/subscriptions`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
        }
      });
      const data = await response.json();
      
      // Asegurarnos de que data sea un array
      const subscriptionsArray = Array.isArray(data) ? data : 
                                Array.isArray(data.subscriptions) ? data.subscriptions :
                                Array.isArray(data.data) ? data.data : [];
      
      console.log('Subscriptions data:', subscriptionsArray); // Debug log
      setSubscriptions(subscriptionsArray);
    } catch (error) {
      console.error('Error fetching subscriptions:', error);
      setError(error.message);
      setSubscriptions([]); // En caso de error, establecer array vacío
    } finally {
      setLoading(false);
    }
  };
  const fetchStats = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/subscription-stats`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
        }
      });
      const data = await response.json();
      setStats(data);
    } catch (error) {
      console.error('Error fetching subscription stats:', error);
    }
  };

  const handleCancelSubscription = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/admin/subscriptions/${selectedSubscription.id}/cancel`,
        {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
          }
        }
      );

      if (response.ok) {
        setSubscriptions(subscriptions.map(sub => 
          sub.id === selectedSubscription.id 
            ? { ...sub, status: 'cancelled' }
            : sub
        ));
        setShowConfirmCancel(false);
      }
    } catch (error) {
      console.error('Error cancelling subscription:', error);
    }
  };

  const StatCard = ({ title, value, icon: Icon, prefix = '' }) => (
    <div className="bg-white p-6 rounded-lg shadow-sm">
      <div className="flex justify-between items-start mb-4">
        <h3 className="text-gray-500 text-sm">{title}</h3>
        <div className="p-2 bg-purple-100 rounded-lg">
          <Icon className="w-5 h-5 text-purple-600" />
        </div>
      </div>
      <p className="text-2xl font-bold">{prefix}{value.toLocaleString()}</p>
    </div>
  );

  const filteredSubscriptions = subscriptions.filter(sub => 
    sub.user_email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    sub.user_name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return (
      <div className="flex h-screen">
        {/* Sidebar */}
        <div className="w-64 bg-white border-r shadow-sm flex flex-col">
          <div className="p-6 border-b">
            <h2 className="text-2xl font-bold text-gray-800">Admin Panel</h2>
            <p className="text-sm text-gray-500">Espor.ai</p>
          </div>

          <nav className="flex-1 p-4">
            {menuItems.map((item) => (
              <Link 
                key={item.path} 
                to={item.path} 
                className={`
                  flex items-center p-3 rounded-lg mb-2 transition-colors 
                  ${location.pathname === item.path 
                    ? 'bg-purple-100 text-purple-600' 
                    : 'text-gray-600 hover:bg-gray-100 hover:text-gray-900'
                  }
                `}
              >
                <item.icon className="w-5 h-5 mr-3" />
                <span className="text-sm font-medium">{item.label}</span>
              </Link>
            ))}
          </nav>

          <div className="p-4 border-t">
            <button 
              onClick={handleLogout}
              className="w-full flex items-center justify-center p-3 text-red-600 hover:bg-red-50 rounded-lg transition-colors"
            >
              <LogOut className="w-5 h-5 mr-3" />
              <span className="text-sm font-medium">Cerrar Sesión</span>
            </button>
          </div>
        </div>

        <div className="flex-1 flex justify-center items-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-purple-600"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-screen">
      {/* Sidebar */}
      <div className="w-64 bg-white border-r shadow-sm flex flex-col">
        <div className="p-6 border-b">
          <h2 className="text-2xl font-bold text-gray-800">Admin Panel</h2>
          <p className="text-sm text-gray-500">Espor.ai</p>
        </div>

        <nav className="flex-1 p-4">
          {menuItems.map((item) => (
            <Link 
              key={item.path} 
              to={item.path} 
              className={`
                flex items-center p-3 rounded-lg mb-2 transition-colors 
                ${location.pathname === item.path 
                  ? 'bg-purple-100 text-purple-600' 
                  : 'text-gray-600 hover:bg-gray-100 hover:text-gray-900'
                }
              `}
            >
              <item.icon className="w-5 h-5 mr-3" />
              <span className="text-sm font-medium">{item.label}</span>
            </Link>
          ))}
        </nav>

        <div className="p-4 border-t">
          <button 
            onClick={handleLogout}
            className="w-full flex items-center justify-center p-3 text-red-600 hover:bg-red-50 rounded-lg transition-colors"
          >
            <LogOut className="w-5 h-5 mr-3" />
            <span className="text-sm font-medium">Cerrar Sesión</span>
          </button>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 bg-gray-50 overflow-y-auto">
        <div className="p-6 space-y-6">
          <h2 className="text-2xl font-bold text-gray-900">Gestión de Suscripciones</h2>

          {/* Stats Cards */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
            <StatCard 
              title="Ingresos Totales"
              value={stats.total_revenue}
              icon={DollarSign}
              prefix="$"
            />
            <StatCard 
              title="Suscripciones Activas"
              value={stats.active_subscriptions}
              icon={Calendar}
            />
            <StatCard 
              title="MRR"
              value={stats.mrr}
              icon={DollarSign}
              prefix="$"
            />
          </div>

          {/* Search and Filters */}
          <div className="flex justify-between items-center mb-6">
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
              <input
                type="text"
                placeholder="Buscar suscripciones..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
              />
            </div>
          </div>

          {/* Subscriptions Table */}
          <div className="bg-white shadow-sm rounded-lg overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Usuario</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Plan</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Estado</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Próximo Pago</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Acciones</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredSubscriptions.map((subscription) => (
                  <tr key={subscription.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{subscription.user_name}</div>
                      <div className="text-sm text-gray-500">{subscription.user_email}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                        {subscription.plan_name}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        subscription.status === 'active' ? 'bg-green-100 text-green-800' :
                        subscription.status === 'cancelled' ? 'bg-red-100 text-red-800' :
                        'bg-yellow-100 text-yellow-800'
                      }`}>
                        {subscription.status}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {subscription.next_payment_date ? new Date(subscription.next_payment_date).toLocaleDateString() : 'N/A'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                      <button
                        onClick={() => {
                          setSelectedSubscription(subscription);
                          setShowConfirmCancel(true);
                        }}
                        className="text-red-600 hover:text-red-900"
                        disabled={subscription.status === 'cancelled'}
                      >
                        Cancelar
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Confirmation Modal */}
      {showConfirmCancel && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-md">
            <h3 className="text-lg font-bold mb-4">Confirmar Cancelación</h3>
            <p>¿Estás seguro de que quieres cancelar la suscripción de {selectedSubscription?.user_email}?</p>
            <div className="mt-6 flex justify-end space-x-4">
              <button
                onClick={() => setShowConfirmCancel(false)}
                className="px-4 py-2 text-gray-600 hover:text-gray-800"
              >
                Cancelar
              </button>
              <button
                onClick={handleCancelSubscription}
                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
              >
                Confirmar Cancelación
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SubscriptionManagement;