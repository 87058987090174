import React, { useState, useEffect } from 'react';
import { MessageSquare, User, Clock, Check, X } from 'lucide-react';

const Support = () => {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [reply, setReply] = useState('');
  const [filter, setFilter] = useState('all'); // all, open, closed

  useEffect(() => {
    fetchTickets();
  }, []);

  const fetchTickets = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/support-tickets`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
        }
      });
      const data = await response.json();
      setTickets(data);
    } catch (error) {
      console.error('Error fetching tickets:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleReply = async (e) => {
    e.preventDefault();
    if (!reply.trim()) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/admin/support-tickets/${selectedTicket.id}/reply`,
        {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('adminToken')}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ message: reply })
        }
      );

      if (response.ok) {
        const updatedTicket = await response.json();
        setTickets(tickets.map(ticket => 
          ticket.id === selectedTicket.id ? updatedTicket : ticket
        ));
        setReply('');
      }
    } catch (error) {
      console.error('Error sending reply:', error);
    }
  };

  const handleCloseTicket = async (ticketId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/admin/support-tickets/${ticketId}/close`,
        {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
          }
        }
      );

      if (response.ok) {
        setTickets(tickets.map(ticket => 
          ticket.id === ticketId 
            ? { ...ticket, status: 'closed' }
            : ticket
        ));
      }
    } catch (error) {
      console.error('Error closing ticket:', error);
    }
  };

  const filteredTickets = tickets.filter(ticket => {
    if (filter === 'all') return true;
    return ticket.status === filter;
  });

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-500"></div>
      </div>
    );
  }

  return (
    <div className="flex h-[calc(100vh-150px)]">
      {/* Tickets List */}
      <div className="w-1/3 bg-white rounded-lg shadow-sm mr-6 overflow-hidden">
        <div className="p-4 border-b">
          <h3 className="text-lg font-semibold mb-4">Tickets de Soporte</h3>
          <div className="flex space-x-2">
            <button
              onClick={() => setFilter('all')}
              className={`px-3 py-1 rounded-full text-sm ${
                filter === 'all' 
                  ? 'bg-purple-100 text-purple-800' 
                  : 'bg-gray-100 text-gray-800'
              }`}
            >
              Todos
            </button>
            <button
              onClick={() => setFilter('open')}
              className={`px-3 py-1 rounded-full text-sm ${
                filter === 'open' 
                  ? 'bg-green-100 text-green-800' 
                  : 'bg-gray-100 text-gray-800'
              }`}
            >
              Abiertos
            </button>
            <button
              onClick={() => setFilter('closed')}
              className={`px-3 py-1 rounded-full text-sm ${
                filter === 'closed' 
                  ? 'bg-red-100 text-red-800' 
                  : 'bg-gray-100 text-gray-800'
              }`}
            >
              Cerrados
            </button>
          </div>
        </div>
        <div className="overflow-y-auto h-[calc(100%-80px)]">
          {filteredTickets.map(ticket => (
            <div
              key={ticket.id}
              onClick={() => setSelectedTicket(ticket)}
              className={`p-4 border-b cursor-pointer hover:bg-gray-50 ${
                selectedTicket?.id === ticket.id ? 'bg-purple-50' : ''
              }`}
            >
              <div className="flex justify-between items-start">
                <div>
                  <h4 className="font-medium text-gray-900">{ticket.subject}</h4>
                  <p className="text-sm text-gray-500">{ticket.user_email}</p>
                </div>
                <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                  ticket.status === 'open' 
                    ? 'bg-green-100 text-green-800' 
                    : 'bg-red-100 text-red-800'
                }`}>
                  {ticket.status}
                </span>
              </div>
              <p className="text-sm text-gray-600 mt-2 truncate">
                {ticket.last_message}
              </p>
              <div className="flex items-center mt-2 text-xs text-gray-500">
                <Clock size={12} className="mr-1" />
                {new Date(ticket.updated_at).toLocaleDateString()}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Ticket Detail */}
      {selectedTicket ? (
        <div className="flex-1 bg-white rounded-lg shadow-sm overflow-hidden flex flex-col">
          <div className="p-4 border-b flex justify-between items-center">
            <div>
              <h3 className="text-lg font-semibold">{selectedTicket.subject}</h3>
              <div className="flex items-center text-sm text-gray-500">
                <User size={16} className="mr-1" />
                {selectedTicket.user_email}
              </div>
            </div>
            {selectedTicket.status === 'open' && (
              <button
                onClick={() => handleCloseTicket(selectedTicket.id)}
                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
              >
                Cerrar Ticket
              </button>
            )}
          </div>

          {/* Messages */}
          <div className="flex-1 overflow-y-auto p-4 space-y-4">
            {selectedTicket.messages?.map((message, index) => (
              <div
                key={index}
                className={`flex ${message.is_admin ? 'justify-end' : 'justify-start'}`}
              >
                <div className={`max-w-[70%] rounded-lg p-4 ${
                  message.is_admin 
                    ? 'bg-purple-100 text-purple-900' 
                    : 'bg-gray-100 text-gray-900'
                }`}>
                  <p className="text-sm">{message.content}</p>
                  <div className="mt-2 text-xs text-gray-500 flex items-center">
                    {new Date(message.created_at).toLocaleString()}
                    {message.is_admin && (
                      <>
                        <Check size={12} className="ml-1 text-green-500" />
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Reply Form */}
          {selectedTicket.status === 'open' && (
            <form onSubmit={handleReply} className="p-4 border-t">
              <div className="flex items-start space-x-2">
                <textarea
                  value={reply}
                  onChange={(e) => setReply(e.target.value)}
                  placeholder="Escribe tu respuesta..."
                  className="flex-1 p-2 border rounded-lg resize-none"
                  rows="3"
                ></textarea>
                <button
                  type="submit"
                  className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 flex items-center"
                >
                  <MessageSquare size={16} className="mr-2" />
                  Enviar
                </button>
              </div>
            </form>
          )}
        </div>
      ) : (
        <div className="flex-1 flex justify-center items-center bg-gray-50 rounded-lg">
          <div className="text-center text-gray-500">
            <MessageSquare size={48} className="mx-auto mb-4" />
            <p>Selecciona un ticket para ver los detalles</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Support;